/*

	アコーディオンパーツ

*/
import { useContext } from "react"
import IconMinus from "@review/components/IconMinus"
import IconPlus from "@review/components/IconPlus"
import PanelExtraction from "@review/features/PanelExtraction"
import { ReviewContext } from "@review/pages/Review/hooks"
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion"
import MuiAccordionDetails from "@mui/material/AccordionDetails"
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"

// アコーディオンのスタイル
const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}))

// サマリ部分のスタイル
const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary {...props} />
))(({ theme }) => ({
  backgroundColor: "#383A46",
  color: "#FFF",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
    color: "#FFF",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
  "& .MuiIconButton-root": {
    color: "#FFF",
  },
}))

// 詳細部分のスタイル
const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}))

export default function PageAccordion() {
  const handle = useContext(ReviewContext)
  return (
    <Accordion
      expanded={handle.panelExtractionExpanded}
      onChange={handle.selectPanelExtraction}
    >
      <AccordionSummary
        expandIcon={
          handle.panelExtractionExpanded ? <IconMinus /> : <IconPlus />
        }
        sx={{
          ":hover": {
            backgroundColor: "rgb(80, 176, 145)",
          },
        }}
      >
        <Typography sx={{ fontSize: "17px" }}>データ集計の条件を指定する</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 5 }}>
        <PanelExtraction
          defaultValue={handle.defaultValue}
          onClickAnalysisAll={handle.onClickAnalysisAll}
          onClickAnalysis={handle.onClickAnalysis}
          fetchingReviews={handle.fetchingReviews}
        />
      </AccordionDetails>
    </Accordion>
  )
}
