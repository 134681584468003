import { RouteObject, Outlet } from "react-router-dom"
import List from "@contact/pages/List"
import Detail from "@contact/pages/Detail"
import { Protected } from "utils/auth/protect"

const ContactRoutes : RouteObject[] = [
  {
    path: "contact",
    element: (
      <Protected>
        <Outlet />
      </Protected>
    ),
    children: [
      { index: true, element: <List /> },
      { path: ":id", element: <Detail /> },
    ],
  },
]

export default ContactRoutes
