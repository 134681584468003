/*

	口コミ・評価分析ページのベース

*/
import ApplicationTemplate from "common/layouts/application"
import Provider from "./hooks"
import "./style.css"

export default function Review() {
  return (
    <ApplicationTemplate>
      <Provider />
    </ApplicationTemplate>
  )
}
