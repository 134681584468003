import { UseFormReturn } from "react-hook-form"
import LockIcon from "@mui/icons-material/Lock"
import { FormControl, InputAdornment, TextField, Typography } from "@mui/material"
import { passwordChangeFormInputs, activateSendButton } from "../hooks"
import HtmlTooltip from "../../components/HtmlTooltip"
import "../../auth.css"

export default function NewPasswordForm({
  formMethods,
  setDisabledButtonFlag,
}: {
  formMethods: UseFormReturn<passwordChangeFormInputs>
  setDisabledButtonFlag: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const {
    register,
    formState: { errors },
  } = formMethods

  return (
    <>
      <FormControl
        margin="none"
        variant="standard"
        required
        error={!!errors.new_password}
        fullWidth
      >
        <HtmlTooltip title={passwordNoticeText}>
          <TextField
            required
            autoFocus
            autoComplete="new_password"
            margin="normal"
            label="新しいパスワード"
            {...register("new_password")}
            type="password"
            helperText={
              errors.new_password?.message || "新しいパスワードを8文字以上で入力してください。"
            }
            onKeyDown={() => {
              setDisabledButtonFlag(activateSendButton({formMethods}))
            }}
            error={!!errors.new_password}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon />
                </InputAdornment>
              ),
            }}
          />
        </HtmlTooltip>
      </FormControl>
    </>
  )
}

export const passwordNoticeText = (
  <>
    <p>
      パスワードは英数大文字小文字及び記号を含む8文字以上の文字列で入力してください。
      <br />
      利用可能な記号: <code>{"^ $ * . [ ] { } ( ) ? \" ! @ # % & / \ , > < ' : ; | _ ~ ` = + - "}</code>
      <br />
      例: <code>Abc123!@#</code>
    </p>
  </>
)
