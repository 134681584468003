/*

	条件抽出フォームの共有コンテキスト

*/
import React, { ReactNode } from "react"
import dayjs from "dayjs"
import { type UseFormRegister, useForm } from "react-hook-form"
import { Data, ExtractionForm } from "@review/pages/Review/type"
import { ItemFilterInput } from "gql/graphql"
import { SelectChangeEvent } from "@mui/material/Select"

export type ExtractionProp = {
  filter: ItemFilterInput
  categoryDefinition: string
  setCategoryDefinition: (value: string) => void
  changeCategoryDefinition: (value: string) => void
  formContextValue: any
  setFilter: (filter: ItemFilterInput) => void
  reset: (defaultValue: ExtractionForm) => void
  selectedItems: Data[]
  targetItems: Data[]
  onChangeSelectedItem: (selectedRows: Data[]) => void
  onChangeTargetItem: (targetRows: Data[]) => void
  onClickAddItemTargetList: () => void
  alert: string
  setAlert: (message: string) => void
  fetchingItems: boolean
  setFetchingItems: (value: boolean) => void
}

export function useExtraction(defaultValue: ExtractionForm): ExtractionProp {
  // カテゴリ分類
  const [categoryDefinition, setCategoryDefinition] = React.useState("INTAGE")

  // フォームの入力値
  const formContextValue = useForm<ExtractionForm>({
    mode: "onChange",
    defaultValues: {
      term_from: dayjs().format("YYYY/MM/DD"),
      term_to: dayjs().format("YYYY/MM/DD"),
      category: [],
      subCategory: [],
      maker: [],
      brand: [],
      barcode: [],
      selectedItems: [],
    },
  })

  // フォームの内容リセット
  const reset = (defaultValue: ExtractionForm) => {
    if (defaultValue) {
      const term_from = formContextValue.getValues("term_from")
      const term_to = formContextValue.getValues("term_to")
      formContextValue.reset({
        term_from: term_from ? term_from : defaultValue.term_from,
        term_to: term_to ? term_to : defaultValue.term_to,
        category: defaultValue.category,
        subCategory: defaultValue.subCategory,
        maker: defaultValue.maker,
        brand: defaultValue.brand,
        barcode: defaultValue.barcode,
        selectedItems: defaultValue.selectedItems,
      })
    }
  }

  // フィルタ
  const defaultFilter = {
    intage_generic_category_lv3name: [],
    jicfs_lv4name: [],
    subcategory_name: [],
    maker_name_orig: [],
    brand_name: [],
    identifier: [],
  }
  const [filter, setFilter] = React.useState<ItemFilterInput>(defaultFilter)

  // カテゴリ定義が変更された時
  const changeCategoryDefinition = (value: string) => {
    if (value === categoryDefinition) return
    reset(defaultValue)
    setFilter(defaultFilter)
    setCategoryDefinition(value)
  }

  // チェックされている商品、ターゲット商品の一覧
  const [selectedItems, setSelectedItems] = React.useState<Data[]>([])
  const [targetItems, setTargetItems] = React.useState<Data[]>([])

  // 商品一覧の行が選択された時
  const onChangeSelectedItem = (selectedRows: Data[]) => {
    setSelectedItems(selectedRows)
  }
  const onChangeTargetItem = (targetRows: Data[]) => {
    setTargetItems(targetRows)
  }

  // チェックしたバーコードを商品指定に追加
  const onClickAddItemTargetList = () => {
    const newTargetItems = [...targetItems]
    selectedItems.forEach((item) => {
      const index = newTargetItems.findIndex(
        (targetItem) => targetItem.barcode === item.barcode,
      )
      if (index === -1) {
        newTargetItems.push(item)
      }
    })
    setTargetItems(newTargetItems)
  }

  // バリデーションエラーのアラート
  const [alert, setAlert] = React.useState("");

  // GraphQLの実行中
  const [fetchingItems, setFetchingItems] = React.useState(false)

  return {
    categoryDefinition: categoryDefinition,
    setCategoryDefinition: setCategoryDefinition,
    changeCategoryDefinition: changeCategoryDefinition,
    formContextValue: formContextValue,
    filter: filter,
    setFilter: setFilter,
    reset: reset,
    selectedItems: selectedItems,
    targetItems: targetItems,
    onChangeSelectedItem: onChangeSelectedItem,
    onChangeTargetItem: onChangeTargetItem,
    onClickAddItemTargetList: onClickAddItemTargetList,
    alert: alert,
    setAlert: setAlert,
    fetchingItems: fetchingItems,
    setFetchingItems: setFetchingItems,
  }
}
