/*

	お問い合わせスレッド

*/
import { Link } from "react-router-dom"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import { columns } from "./columns"
import { Contact, ContactStatus } from "gql/graphql"

const statusSymbol: Record<string, string> = {
  [ContactStatus.New]: "新規",
  [ContactStatus.Received]: "受付済み",
  [ContactStatus.Done]: "対応済み",
}

export default function ItemRow(
  props: {
    row: Contact
  }
) {
  const row = props.row
  const status = ContactStatus

  return (
    <TableRow
      hover={true}
      sx={{
        cursor: "pointer",
        textDecoration: "none",
        backgroundColor: row.status == status.Done ? "#f0f0f0" : "inherit"
      }}
      component={Link}
      to={`/contact/${row.contact_id}`}
    >
      {columns.map((column) => {
        const value = () => {
          if (column.id == "status") {
            return statusSymbol[row[column.id] ?? ""]
          }
          if (column.id == "unread_cnt") {
            return (row[column.id] ?? 0) + "件"
          }
          return row[column.id]
        }

        return (
          <TableCell
            key={column.id}
            align={column.align}
          >
            {value()}
          </TableCell>
        )
      })}
    </TableRow>
  )
}
