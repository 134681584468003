/*

	メッセージ

*/
import React from "react"
import Stack from "@mui/material/Stack"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"
import { ContactComment } from "gql/graphql"
import dayjs from "dayjs"

const AdminMessageBallon = styled(Box)({
  position: "relative",
  marginLeft: "20px",
  marginBottom: "2px",
  padding: "10px",
  backgroundColor: "#A8DDFD",
  textAlign: "left",
  font: "400 .9em 'Open Sans', sans-serif",
  border: "1px solid #97C6E3",
  borderRadius: "10px",
  "&:after": {
    content: "''",
    position: "absolute",
    width: "0",
    height: "0",
    borderTop: "15px solid #A8DDFD",
    borderLeft: "15px solid transparent",
    borderRight: "15px solid transparent",
    top: "0",
    left: "-15px"
  },
  "&:before": {
    content: "''",
    position: "absolute",
    width: "0",
    height: "0",
    borderTop: "17px solid #97C6E3",
    borderLeft: "16px solid transparent",
    borderRight: "16px solid transparent",
    top: "-1px",
    left: "-17px"
  }
})

const UserMessageBallon = styled(Box)({
  position: "relative",
  marginRight: "20px",
  marginBottom: "2px",
  padding: "10px",
  backgroundColor: "#f8e896",
  textAlign: "left",
  font: "400 .9em 'Open Sans', sans-serif",
  border: "1px solid #dfd087",
  borderRadius: "10px",
  "&:after": {
    content: "''",
    position: "absolute",
    width: "0",
    height: "0",
    borderTop: "15px solid #f8e896",
    borderLeft: "15px solid transparent",
    borderRight: "15px solid transparent",
    top: "0",
    right: "-15px"
  },
  "&:before": {
    content: "''",
    position: "absolute",
    width: "0",
    height: "0",
    borderTop: "17px solid #dfd087",
    borderLeft: "16px solid transparent",
    borderRight: "16px solid transparent",
    top: "-1px",
    right: "-17px"
  }
})

export default function Message(props: {
  message: ContactComment
}) {
  const messages = props.message.comment.split("\n").map((message, index) => {
    return (
      <React.Fragment key={index}>
        { message }
        <br />
      </React.Fragment>
    )
  })
  const created_at = dayjs(props.message.created_at).format("YYYY/MM/DD HH:mm")
  return props.message.admin_id ? (
    <Stack direction="row" justifyContent="flex-start">
      <Stack direction="column" alignItems="flex-start" sx={{ width: "60%" }}>
        <AdminMessageBallon>
          { messages }
        </AdminMessageBallon>
        <Typography sx={{ color: "grey", fontSize: "12px" }}>{ created_at }</Typography>
      </Stack>
    </Stack>
  ) : (
    <Stack direction="row" justifyContent="flex-end">
      <Stack direction="column" alignItems="flex-end" sx={{ width: "60%" }}>
        <UserMessageBallon>
          { messages }
        </UserMessageBallon>
        <Typography sx={{ color: "grey", fontSize: "12px" }}>{ created_at }</Typography>
      </Stack>
    </Stack>
  )
}
