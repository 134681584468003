import { useEffect } from "react"
import { UseFormReturn } from "react-hook-form"
import { Button, Typography } from "@mui/material"
import { useAuth } from "hooks/useAuth"
import { ErrorWithFormProps, LoginFormInputs } from "../hooks"
import { SERVICE_EMAIL } from "constants/base"

const ButtonsAndLinks = ({
  formMethods,
  execute,
  error,
}: {
  formMethods: UseFormReturn<LoginFormInputs>
  execute: (data: LoginFormInputs) => Promise<void>
  error: ErrorWithFormProps
}) => {
  const auth = useAuth() // Cognito
  const username = auth.username
  useEffect(() => {
    console.debug("username is:", username)
  }, [auth, username])

  const activateSendButton = () => {
    const onetimePassword = formMethods.getValues("onetime_password") || ""
    return onetimePassword.length !== 6 ? true : false
  }

  if (auth.isLoading) return <></>

  return (
    <>
      <Button
        type="submit"
        onClick={formMethods.handleSubmit(execute, error)}
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        disabled={activateSendButton()}
      >
        {auth.isSignUpUserSession
          ? "認証してサインアップを完了する"
          : "認証してサインインする"}
      </Button>

      <Button
        href="/auth/signIn"
        fullWidth
        variant="outlined"
        sx={{ mt: 6, mb: 4 }}
      >
        サインインページに戻る（ワンタイムパスワードの再発行もこちらから）
      </Button>

      <Typography variant="body2" color="text.secondary">
        メールが届かない場合は、迷惑メールフォルダをご確認ください。また、
        {SERVICE_EMAIL} からのメールを受信できるように設定してください。
      </Typography>
    </>
  )
}
export default ButtonsAndLinks
