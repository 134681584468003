/*

	データ集計期間の入力

*/
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import InputDate from "../InputDate"

export default function InputDateBetween(props: {
  title: string
  from_label: string
  from_name: string
  to_label: string
  to_name: string
}) {
  return (
    <>
      <Typography sx={{ marginBottom: "10px" }}>{props.title}</Typography>
      <Stack direction="row" alignItems="center" spacing={2}>
        <InputDate label={props.from_label} name={props.from_name} />
        <Typography>〜</Typography>
        <InputDate label={props.to_label} name={props.to_name} />
      </Stack>
    </>
  )
}
