export const barColors = [
  "#FF5B5B",
  "#F8CBAD",
  "#EF8943",
  "#FFE699",
  "#FFCB25",
  "#A9D18E",
  "#81BB59",
  "#9DC3E6",
  "#5B9BD5",
  "#2F5597",
]
