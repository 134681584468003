import SvgIcon from "@mui/material/SvgIcon"

const SVGComponent = () => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10zM5.5 12a1 1 0 0 0 1 1h11a1 1 0 1 0 0-2h-11a1 1 0 0 0-1 1z"
        fill="#fff"
      />
    </SvgIcon>
  )
}
export default SVGComponent
