import PermissionErrorBase from 'common/components/PermissionErrorBase';
import { Env, EnvTypes } from 'constants/base';
import { useAuth } from 'hooks/useAuth';
import { To } from 'react-router';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

class ProtectedErrorObjectError extends Error {
  constructor(message: string | undefined) {
    super(message)
    this.name = "ProtectedErrorObjectError"
  }
}

type ChildrenProps = {
  permissionErrorCustom?: React.FC
  children?: React.ReactElement
  env?: EnvTypes
  redirect?: To
}

const adminRole = ["Developer", "Admin"] as const
type AdminRole = (typeof adminRole)[number]

const companyRole = ["CompanyUser", "CompanyAdmin"] as const
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type CompanyRole = (typeof companyRole)[number] // NOTE: default role is CompanyUser

const companyAdminRole = ["CompanyAdmin"] as const
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type CompanyAdminRole = (typeof companyAdminRole)[number] // NOTE: CompanyAdmin can edit company's info, user, etc.

function hasAdminRole(roles: string[]): boolean {
  return roles.some((role) => adminRole.includes(role as AdminRole))
}

/**
 * Protected component.
 * @param param0 children component or components
 * @returns redirect to login page if not authenticated or render children
 *          if authenticated or show 404 page if user is not has permission to access to the page.
 */
export const Protected: React.FC<ChildrenProps> = ({
  permissionErrorCustom,
  children,
  env,
  redirect,
}: ChildrenProps) => {
  // redirect to login page if not authenticated
  const { isAuthenticated, isLoading, hasAccountData } = useAuth()
  const location = useLocation()
  if (isLoading) return

  // if env is not match to running env, redirect to login page.
  if (!!env && env !== Env) {
    if(!!redirect)
      return <Navigate replace to={redirect} />
  }

  if (permissionErrorCustom && typeof permissionErrorCustom !== "function")
    throw new ProtectedErrorObjectError(
      "Error: permissionErrorCustom is not function.",
    )

  // get login state from useAuth

  // check current location (if auth page, do not redirect to login page)
  const authPageRegex = /^\/auth\/.*/
  const isAuthPage = authPageRegex.test(location.pathname)

  // render children if authenticated
  // NOTE: ここではあくまでも認証状態のみをチェックしている。各サブ機能のアクセス権限チェックは各サブ機能側で行う。
  //       CXMPとしては認証状態であれば、どのサブ機能にアクセスしても良いが、各サブ機能で別途アクセス権限を持つため。
  if (isAuthenticated) {
    if (!hasAccountData) {
      return <Navigate replace to="/auth/signUpAccountData" />
    }

    return <>{children}</>
  }

  // or redirect to login page if not authenticated
  if (!isAuthPage) {
    return <Navigate replace to="/auth/signIn"/>
  }

  // or show permission error page if user is not has permission to access to the page.
  if (!permissionErrorCustom) return <PermissionErrorBase />
  return <>{permissionErrorCustom}</>
}

/**
 * Admin protected component.
 * @param param0 children component or components
 * @returns redirect to login page if not authenticated or render children
 *          if authenticated or show 404 page if user is not admin.
 */
export const AdminProtected: React.FC<ChildrenProps> = ({
  permissionErrorCustom,
  children,
  env,
  redirect
}: ChildrenProps) => {
  // redirect to login page if not authenticated
  const navigate = useNavigate()
  const { isAuthenticated, roles, isLoading } = useAuth()
  const location = useLocation()
  if (isLoading) return

  // if env is not match to running env, redirect to login page.
  if (!!env && env !== Env) {
    if(!!redirect)
      return <Navigate replace to={redirect} />

    return <Navigate replace to="/auth/signIn" />
  }

  // NOTICE: this component must be use only show/hide component.
  //         do not use for access control.

  // check current location (if auth page, do not redirect to login page)
  const authPageRegex = /^\/auth\/.*/
  const isAuthPage = authPageRegex.test(location.pathname)

  // render children if authenticated and user has admin role.
  if (isAuthenticated && hasAdminRole(roles)) return <>{children}</>

  // or redirect to login page if not authenticated
  if (!isAuthenticated && !isAuthPage) {
    return <Navigate replace to="/auth/signIn"/>
  }

  // or show permission error page if user is not has permission to access to the page.
  if (!isAuthenticated && !permissionErrorCustom) return <PermissionErrorBase />
  return <>{permissionErrorCustom}</>
}
