import { Typography, Link, CssBaseline } from "@mui/material"
import HeaderNav from "../components/HeaderNav"
import HeroArea from "../components/HeroArea"
import AreaBox from "../components/AreaBox"
import MainBox from "../components/MainBox"
import AuthCopyright from "../components/AuthCopyright"
import "../auth.css"

export default function Presenter() {
  return (
    <>
      <CssBaseline />
      <HeaderNav />
      <AreaBox>
        <HeroArea />

        <MainBox>
          <Typography component="h1" variant="h5" sx={{ mb: 2 }}>
            サインアップ完了
          </Typography>
          <Typography component="p" variant="body1" sx={{ fontWeight: "500" }}>
            登録ありがとうございます。
            <br />
            引き続き
            <Link href="/auth/signin">サインイン</Link>
            を行い、サービスをご利用ください。
          </Typography>
        </MainBox>

        {/* footer */}
        <AuthCopyright />
      </AreaBox>
    </>
  )
}
