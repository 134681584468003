import { Tooltip } from "@mui/material"
import { styled } from "@mui/material/styles"
import { TooltipProps, tooltipClasses } from "@mui/material/Tooltip"

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} placement="right-start" />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 340,
    // fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))

export default HtmlTooltip
