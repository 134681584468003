import { Box } from "@mui/material"
import { styled } from "@mui/material/styles"

const AreaBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(6),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}))

export default AreaBox
