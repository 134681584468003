/*

	商品情報行

*/
import { Data } from "@review/pages/Review/type"
import { Column, columns } from "@review/pages/Review/column"
import TableItemBar from "@review/components/TableItemBar"
import CloseIcon from "@mui/icons-material/Close"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"

export default function ItemRow(props: {
  row: Data
  onClickRemove: (row: Data) => void
}) {
  const row = props.row

  return (
    <TableRow
      tabIndex={-1}
      key={row.barcode}
    >
      <TableCell
        sx={{ pt: 1, pb: 1 }}
        style={{ padding: "4px" }}
        width="30px"
        align="center"
      >
        <CloseIcon
          onClick={() => props.onClickRemove(row)}
          sx={{ cursor: "pointer" }}
        />
      </TableCell>
      {columns.map((column: Column, index: number) => {
        const value = row[column.id]
        if (column.isBar) {
          return (
            <TableCell
              sx={{ pt: 1, pb: 1 }}
              key={column.id}
              align={column.align}
              style={{ padding: "4px" }}
            >
              <TableItemBar
                num={Number(value)}
                scale={column.barScale}
                height={column.barHeight}
                color={column.barColor}
                fixed={column.fixed}
                localeString={column.localeString}
              />
            </TableCell>
          )
        } else {
          return (
            <TableCell
              sx={{ pt: 1, pb: 1 }}
              key={column.id}
              align={column.align}
              style={{ padding: "4px" }}
            >
              {value}
            </TableCell>
          )
        }
      })}
    </TableRow>
  )
}
