/*

	お問い合わせスレッド一覧

*/
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TablePagination, { LabelDisplayedRowsArgs } from "@mui/material/TablePagination"
import TableRow from "@mui/material/TableRow"
import { Contact } from "gql/graphql"
import ItemRow from "./ItemRow"
import { columns } from "./columns"

export default function TableThread(props: {
  contactThreads: Contact[]
  page: number
  setPage: (page: number) => void
  rowsPerPage: number
  setRowsPerPage: (rowsPerPage: number) => void
  totalCount: number
}) {
  const rows: Contact[] = props.contactThreads

  return (
    <TableContainer>
      <Table>
        <TableBody>
          <TableRow>
            <TablePagination
              labelRowsPerPage="表示件数"
              labelDisplayedRows={(paginationInfo: LabelDisplayedRowsArgs) => {
                if (paginationInfo.from === paginationInfo.to) {
                  return `${paginationInfo.count}件中 ${paginationInfo.from}件目を表示`
                }
                return `${paginationInfo.count}件中 ${paginationInfo.from}-${paginationInfo.to}件目を表示`
              }}
              count={props.totalCount}
              rowsPerPage={props.rowsPerPage}
              page={props.page}
              onPageChange={(event, newPage) => {
                props.setPage(newPage)
              }}
              onRowsPerPageChange={(event) => {
                props.setRowsPerPage(parseInt(event.target.value, 10))
                props.setPage(0)
              }}
            />
          </TableRow>
        </TableBody>
      </Table>
      <Table stickyHeader area-label="sticky table">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.length > 0 && rows.map((row) => {
            return (
              <ItemRow
                row={row}
              />
            )
          })}
          {rows.length == 0 && (
            <TableRow>
              <TableCell colSpan={columns.length} align="center">
                お問い合わせはありません
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Table>
        <TableBody>
          <TableRow>
            <TablePagination
              labelRowsPerPage="表示件数"
              labelDisplayedRows={(paginationInfo: LabelDisplayedRowsArgs) => {
                if (paginationInfo.from === paginationInfo.to) {
                  return `${paginationInfo.from}件中 ${paginationInfo.from}件目を表示`
                }
                return `${paginationInfo.from}件中 ${paginationInfo.from}-${paginationInfo.to}件目を表示`
              }}
              count={props.totalCount}
              rowsPerPage={props.rowsPerPage}
              page={props.page}
              onPageChange={(event, newPage) => {
                props.setPage(newPage)
              }}
              onRowsPerPageChange={(event) => {
                props.setRowsPerPage(parseInt(event.target.value, 10))
                props.setPage(0)
              }}
            />
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}
