/*

	ページタイトル用パーツ

*/
import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import ReviewIcon from "common/icons/ReviewIcon"

export default function PageTitle(props: {
  title: string
  description: string
}) {
  return (
    <Stack direction="column" spacing={6} alignItems="left">
      <Stack direction="row" spacing={1} alignItems="center">
        <ReviewIcon width="180px" height="128px" />
        <Typography
          variant="h2"
          component="h2"
          sx={{
            color: "rgb(56,58,70)",
            fontSize: "28px",
            display: "inline",
            fontWeight: "bold",
          }}
        >
          {props.title}
        </Typography>
      </Stack>
      <Box>{props.description}</Box>
    </Stack>
  )
}
