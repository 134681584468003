/*

	リストボックスによる複数選択

*/
import { useController, useFormContext } from "react-hook-form"
import { ListBox } from "@review/components/ListBox"
import SelectListBoxInput from "@review/components/SelectListBoxInput"
import CheckBoxIcon from "@mui/icons-material/CheckBox"
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank"
import Autocomplete from "@mui/material/Autocomplete"
import Checkbox from "@mui/material/Checkbox"
import CircularProgress from "@mui/material/CircularProgress"
import ListItem from "@mui/material/ListItem"

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

interface AutoCompleteItem {
  label: string
  id: string
}

export default function SelectListBox(props: {
  name: string
  label: string
  items: string[]
}) {
  const formName = props.name
  const formLabel = props.label

  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext()

  const { field: fieldItem } = useController({
    name: formName,
    control: control,
    defaultValue: [],
  })

  return (
    <Autocomplete
      loading={false}
      size="small"
      options={props.items}
      sx={{
        width: "200px",
        maxHeight: "100px",
        overflow: "hidden auto",
        paddingRight: 0,
        '.MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
          minWidth: "0px",
        }
      }}
      multiple
      freeSolo
      disableCloseOnSelect
      value={fieldItem.value}
      ListboxComponent={ListBox}
      onChange={(e, v) => {
        setValue(formName, v)
      }}
      renderOption={(props, option, { selected }) => (
        <ListItem {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option}
        </ListItem>
      )}
      renderInput={(params) => (
        <SelectListBoxInput
          params={params}
          name={formName}
          label={formLabel}
          fieldItem={fieldItem}
         />
      )}
    />
  )
}
