import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import { barColors } from "@review/constants/barColor"

export default function TableItemBar(props: {
  num: number
  color?: string
  scale?: number
  height?: string
  fixed?: number
  localeString?: boolean
}) {
  const barsize = (props.scale ? props.scale : 1) * props.num
  const barColor = props.color ? props.color : barColors[Math.round(props.num*2) - 1]

  const fixed_num = props.num.toFixed(props.fixed)
  const value = props.localeString
    ? Number(fixed_num).toLocaleString()
    : fixed_num

  return (
    <Stack direction="row" alignItems="center">
     <Box
       sx={{
         width: barsize + "px",
         height: props.height ? props.height : "30px",
         backgroundColor: barColor,
         marginRight: "4px",
       }}
     />
      <Typography>{value}</Typography>
    </Stack>
  )
}
