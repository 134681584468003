/*

	確認ダイアログ

*/

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'

export default function ConfirmDialog(props: {
  open: boolean
  title: string
  message: string
  onClose: () => void
  onDone: () => void
}) {
  return (
    <Dialog open={ props.open }>
      <DialogTitle>{ props.title }</DialogTitle>
      <DialogContent>
        <DialogContentText>{ props.message }</DialogContentText>
      </DialogContent>
      <DialogActions>
       <Button onClick={() => props.onClose()}>
         キャンセル
       </Button>
       <Button onClick={() => props.onDone()}>
         確定
       </Button>
      </DialogActions>
    </Dialog>
  )
}
