/*

	分析パネルのビュー

*/
import React from "react"
import { Data } from "@review/pages/Review/type"
import { Dist } from "gql/graphql"
import { ReviewContext } from "@review/pages/Review/hooks"
import CircularProgress from "@mui/material/CircularProgress"
import Stack from "@mui/material/Stack"
import PanelChart from "./components/PanelChart"
import PanelReview from "./components/PanelReview"
import PanelStatistics from "./components/PanelStatistics"
import { gql, useQuery } from "urql"
import dayjs from "dayjs"

export default function PanelAnalysis() {
  const ReviewContextValue = React.useContext(ReviewContext)

  const analysis = ReviewContextValue.analysis
  const analysisWithFilter = ReviewContextValue.analysisWithFilter

  const filter = analysisWithFilter ? ReviewContextValue.filter : {}
  const targetItems = analysis ? ReviewContextValue.targetItems : []
  const filterTermFrom = ReviewContextValue.filterTermFrom
  const filterTermTo = ReviewContextValue.filterTermTo
  const categoryDefinition = ReviewContextValue.categoryDefinition
  const setFetchingReviews = ReviewContextValue.setFetchingReviews

  if (analysis === false && analysisWithFilter === false) return <></>

  const [cnt, setCnt] = React.useState(0)
  const [cntReview, setCntReview] = React.useState(0)
  const [scoreAvg, setScoreAvg] = React.useState(0)
  const [dist, setDist] = React.useState<Dist[]>([])

  const reviewFilter = {
    brand_name: analysis ? [] : filter.brand_name,
    intage_generic_category_lv3name: analysis ? [] : filter.intage_generic_category_lv3name,
    jicfs_lv4name: analysis ? [] : filter.jicfs_lv4name,
    maker_name_orig: analysis ? [] : filter.maker_name_orig,
    subcategory_name: analysis ? [] : filter.subcategory_name,
    identifier: analysis ? targetItems.map((item) => item.barcode) : filter.identifier,
    term_from: analysis ? filterTermFrom : filter.term_from,
    term_to: analysis ? filterTermTo : filter.term_to,
    categoryDefinition,
    pager: {
      item_offset: 0,
      per_page: 0,
    }
  }

  // レビューの取得
  const [result] = useQuery({
    query: /* GraphQL */ `
      query getReviews($ReviewFilterInput: ReviewFilterInput!) {
        getReviews(filter: $ReviewFilterInput) {
            reviews {
            score
            comment
            comment_length
            review_updated_at
            identifier
            name
            intage_generic_category_lv3name
            jicfs_lv4name
            maker_name_orig
            sex
            age_group
            pref
          }
          cnt
          cnt_review
          score_avg
          dist {
            score
            cnt
          }
        }
      }
    `,
    variables: {
      ReviewFilterInput: reviewFilter,
    },
  })

  const getItems = () => {
    if (result.fetching || result.error) return []
    return result.data.getReviews.reviews.map((item: any) => {
      const category = categoryDefinition == "INTAGE"
        ? item.intage_generic_category_lv3name
        : item.jicfs_lv4name
      return {
        point: item.score,
        review: item.comment ? item.comment : "",
        strlen: item.comment_length,
        updateDate: dayjs(item.review_updated_at).format("YYYY/MM/DD"),
        barcode: item.identifier,
        title: item.name,
        category: category,
        maker: item.maker_name_orig,
        gender: item.sex,
        generation: item.age_group,
        address: item.pref,
      }
    })
  }

  const getCnt = () => {
    if (result.fetching || result.error) return cnt
    return result.data.getReviews.cnt
  }

  const getCntReview = () => {
    if (result.fetching || result.error) return cntReview
    return result.data.getReviews.cnt_review
  }

  const getScoreAvg = () => {
    if (result.fetching || result.error) return scoreAvg
    return result.data.getReviews.score_avg
  }

  const getDist = () => {
    if (result.fetching || result.error) return dist
    return result.data.getReviews.dist
  }

  React.useEffect(() => {
    setCnt(getCnt())
    setCntReview(getCntReview())
    setScoreAvg(getScoreAvg())
    setDist(getDist())
  }, [result])

  return (
    <Stack
      direction="column"
      alignItems="center"
      sx={{ backgroundColor: "#fff", p: 5 }}
      gap={2}
    >
      { result.fetching ? (
          <CircularProgress />
        ) : (
          <Stack gap={2} sx={{ width: "100%" }}>
            <Stack direction="row" gap={2}>
              <PanelStatistics
                cnt={cnt}
                cntReview={cntReview}
                scoreAvg={scoreAvg}
              />
              <PanelChart dist={dist} />
            </Stack>

            <PanelReview
              categoryDefinition={categoryDefinition}
              filter={reviewFilter}
            />
          </Stack>
        )
      }
    </Stack>
  )
}
