/*

	サブカテゴリ選択

*/
import { useForm } from "react-hook-form"
import { ExtractionForm } from "@review/pages/Review/type"
import SelectListBox from "@review/components/SelectListBox"
import { gql, useQuery } from "urql"

export default function SelectSubCategory(props: {
  name: string
  label: string
  intage_generic_category_lv3name: string[]
  jicfs_lv4name: string[]
}) {
  const filter = {
    intage_generic_category_lv3name: props.intage_generic_category_lv3name,
    jicfs_lv4name: props.jicfs_lv4name,
  }

  const [result] = useQuery({
    query: /* GraphQL */ `
      query getSubCategoryOptions(
        $SubcategoryFilterInput: SubcategoryFilterInput
      ) {
        getSubcategoryOptions (filter: $SubcategoryFilterInput)
      }
    `,
    variables: {
      SubcategoryFilterInput: filter
    },
  })

  const getOptionsFromResult = () => {
    if (result.fetching || result.error) return []
    if (result.data.getSubcategoryOptions == null) return []
    return result.data.getSubcategoryOptions
  }

  const getOptions = () => {
    return result.fetching
      ? []
      : getOptionsFromResult()
  }

  return(
    <SelectListBox
      name={props.name}
      label={props.label}
      items={getOptions()}
    />
  )
}
