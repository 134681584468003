import React from "react"
import { ReviewContext } from "@review/pages/Review/hooks"
import { Data, Review } from "@review/pages/Review/type"

export type TableReviewProp = {
  rows: Review[]
  page: number
  setPage: (page: number) => void
  rowsPerPage: number
  setRowsPerPage: (rowsPerPage: number) => void
}

export function useTableReview(reviewRows: Review[]): TableReviewProp {
  const [rows, setRows] = React.useState<Review[]>(reviewRows)
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(25)

  return {
    rows: rows,
    page: page,
    setPage: setPage,
    rowsPerPage: rowsPerPage,
    setRowsPerPage: setRowsPerPage,
  }
}
