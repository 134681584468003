/*

	評価点分析チャート

*/
import {
  Cell,
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"
import { Dist } from "gql/graphql"
import { barColors } from "@review/constants/barColor"

export default function ChartReview(props: {
  dist: Dist[]
}) {
  const dist = props.dist
  return (
    <ResponsiveContainer>
      <BarChart
        data={dist}
        margin={{ top: 20, right: 20 }}
      >
        <CartesianGrid strokeDasharray="0" vertical={false} />
        <XAxis
          dataKey="score"
          type="number"
          ticks={[0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5]}
          tick={{ fontSize: 14 }}
          tickLine={false}
          domain={[0, 5.5]}
        />
        <YAxis
          axisLine={false}
          type="number"
          tickCount={4}
          tick={{ fontSize: 14 }}
          tickLine={false}
          tickFormatter={(tick) => tick.toLocaleString()}
          unit="件"
          width={100}
        />
        <Tooltip
          label={false}
          formatter={(tick) => tick.toLocaleString()}
        />
        <Bar dataKey="cnt" name="件数">
          {
            dist.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={barColors[index]} />
            ))
          }
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  )
}
