import { Data } from "@review/pages/Review/type"

export interface Column {
  id: keyof Data
  label: string
  minWidth?: number
  align?: "right"
  format?: (value: number) => string
  isBar?: boolean
  barScale?: number
  barHeight?: string
  barColor?: string
  fixed?: number
  localeString?: boolean
}

export const columns: Column[] = [
  {
    id: "barcode",
    label: "バーコード",
    minWidth: 80,
  },
  {
    id: "title",
    label: "商品名",
    minWidth: 240,
  },
  {
    id: "maker",
    label: "メーカー名",
    minWidth: 120,
  },
  {
    id: "category",
    label: "商品カテゴリ",
    minWidth: 140,
  },
  {
    id: "subCategory",
    label: "商品サブカテゴリ",
    minWidth: 140,
  },
  {
    id: "brand",
    label: "ブランド名",
    minWidth: 200,
  },
  {
    id: "subBrand",
    label: "サブブランド名",
    minWidth: 200,
  },
  {
    id: "avaragePoint",
    label: "平均評価点",
    minWidth: 140,
    isBar: true,
    barScale: 10,
    fixed: 2,
  },
  {
    id: "count",
    label: "評価件数",
    minWidth: 140,
    isBar: true,
    barScale: 0.1,
    barHeight: "10px",
    barColor: "#363948",
    localeString: true,
  },
  {
    id: "reviewCount",
    label: "口コミ件数(2文字以上)",
    minWidth: 140,
    isBar: true,
    barScale: 0.1,
    barHeight: "10px",
    barColor: "#363948",
    localeString: true,
  },
]
