/*

	ページタイトル

*/
import Typography from "@mui/material/Typography"

export default function PageTitle( props: { title: string } ) {
  return (
    <Typography
      variant="h2"
      component="h2"
      sx={{
        color: "rgb(56,58,70)",
        fontSize: "28px",
        display: "inline",
        fontWeight: "bold",
      }}
    >
    {props.title}
   </Typography>
  )
}
