/*

	お問い合わせスレッド一覧パネル

*/
import React from "react"
import Alert from "@mui/material/Alert"
import Button from "@mui/material/Button"
import { styled } from "@mui/material/styles"
import TableThread from "@contact/pages/List/components/TableThread"
import ContactDialog from "@contact/components/ContactDialog"
import PanelWrapper from "@contact/components/PanelWrapper"
import { usePanelThread } from "./hooks"
import { useQuery, useMutation, gql } from "urql"
import { SendContactMutation } from "gql/graphql"
import { MutationSendContactArgs } from "gql/graphql"

const ButtonContact = styled(Button)({
  width: 160,
  fontWeight: "bold",
})

export default function PanelThread() {
  const {
    openContactDialog,
    setOpenContactDialog,
  } = usePanelThread()
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [totalCount, setTotalCount] = React.useState(0)
  const [contactThreads, setContactThreads] = React.useState([])
  const [alert, setAlert] = React.useState(false)

  const [result, executeQuery] = useQuery({
    query: /* Qraphql */ `
      query getContacts($filter: GetContactsInput) {
        getContacts(filter: $filter) {
          contacts {
            contact_id
            contact_type
            target_service
            contact_detail
            status
            created_at
            last_comment_date
            unread_cnt
          }
          totalCount
        }
      }
    `,
    variables: {
      filter: {
        pager: {
          item_offset: page * 10,
          per_page: rowsPerPage,
        }
      }
    },
    requestPolicy: 'network-only',
  })
  const SendContactQuery = gql /* Graphql */ `
    mutation sendContact($data: SendContactInput!) {
      sendContact(data: $data) {
        contact_id
        contact_type
        target_service
        contact_detail
        status
        created_at
      }
    }
  `
  const [_, sendContact] = useMutation<
    SendContactMutation,
    MutationSendContactArgs
  >(SendContactQuery)

  const onDone = async (
    typeValue: number,
    serviceValue: number,
    detailValue: string,
  ) => {
    await sendContact({
      data: {
        contact_type_id: typeValue,
        target_service_id: serviceValue,
        contact_detail: detailValue,
      }
    })
    .then((res) => {
      if (res.error) {
        setAlert(true)
      }
      if (!res.data?.sendContact) {
        setAlert(true)
      }
    })

    setOpenContactDialog(false)
    executeQuery()
  }

  React.useEffect(() => {
    if (result.data) {
      setContactThreads(result.data.getContacts.contacts)
      setTotalCount(result.data.getContacts.totalCount)
    }
  }, [result.data])

  React.useEffect(() => {
    if (result.error) {
      setAlert(true)
    }
  }, [result.error])

  return (
    <PanelWrapper>
      {openContactDialog && (
        <ContactDialog
          open={openContactDialog}
          onClose={() => setOpenContactDialog(false)}
          onDone={onDone}
        />
      )}
      <ButtonContact
        variant="contained"
        color="info"
        onClick={() => setOpenContactDialog(true)}
      >
        新規問い合わせ
      </ButtonContact>
      {alert && (
        <Alert severity="error" onClose={() => setAlert(false)}>
          お問合せの作成に失敗しました。
        </Alert>
      )}
      <TableThread
        contactThreads={contactThreads}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        totalCount={totalCount}
      />
    </PanelWrapper>
  )
}
