import { createContext, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import i18n from "i18n"
import { z } from "zod"
import { makeZodI18nMap } from "zod-i18n-map"
import { useReCaptcha, checkReCaptcha } from "utils/reCaptcha"
import { zodResolver } from "@hookform/resolvers/zod"
import { useAuth, CognitoResult } from "hooks/useAuth"
import { SubmitErrorHandler, useForm, UseFormReturn } from "react-hook-form"
import { setGlobalMessage } from "hooks/useGlobalMessage"
import Presenter from "./Presenter"
import ResultText from "../../../../applications/brandquickview/features/brandQuickView/components/detailTab/components/resultText/index"

export const FormContext = createContext<
  UseFormReturn<LoginFormInputs> | undefined
>(undefined)

// execute Zod
z.setErrorMap(makeZodI18nMap({ ns: ["baseCustomedForm", "authForm", "zod"] }))
const formNS = "baseCustomedForm"
const schema = z.object({
  username: z.string().email(),
  password: z.string().min(1, i18n.t("text.nonempty", { ns: formNS })),
})
export type LoginFormInputs = z.infer<typeof schema>
export type ErrorWithFormProps = SubmitErrorHandler<LoginFormInputs>

/**
 * Provider component for LoginForm.
 * @returns React component
 */
export default function Provider() {
  const formMethods = useForm<LoginFormInputs>({
    resolver: zodResolver(schema),
  })
  if (formMethods === undefined) throw new Error("formMethods is undefined.")

  const reCaptchaValue = useReCaptcha() // reCAPTCHA
  const auth = useAuth() // Cognito
  const navigate = useNavigate()

  if( auth.isAuthenticated){
    navigate("/", { replace: true })
  }
  // Cognito sign in.
  const execute = async (data: LoginFormInputs) => {
    const isHumanAccess = await checkReCaptcha(reCaptchaValue)
    if (!isHumanAccess) {
      setGlobalMessage({
        message:
          "セキュリティ上の理由によりサインインを完了できませんでした。\nリロードして再度お試しください。",
        severity: "error",
      })
      throw new Error("Error: isHumanAccess is false.")
    }

    const result = await auth.signIn(data.username, data.password)

    if (result.success) {
      return result.skipOTP
        ? navigate("/", { replace: true })
        : navigate("/auth/checkOTP", {
            state: { from: location.pathname, username: data.username },
          })
    }

    // unknown error
    setGlobalMessage({
      message:
        "不明なエラーが発生しました。\nページを再読み込みしてもう一度試してください。",
      severity: "error",
    })
    throw new Error(`signIn Error: unknown error. ${result.message}`)
  }

  const error: ErrorWithFormProps = (errors, event) => {
    console.debug("errorWithSignIn")
    return
  }

  return (
    <FormContext.Provider value={formMethods}>
      <Presenter execute={execute} error={error} />
    </FormContext.Provider>
  )
}
