/*

	ページ全体のビュー

*/
import React from "react"
import PanelAnalysis from "@review/features/PanelAnalysis"
import Box from "@mui/material/Box"
import Link from "@mui/material/Link"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import Stack from "@mui/material/Stack"
import PageAccordion from "./components/PageAccordion"
import PageTitle from "./components/PageTitle"
import { ReviewContext } from "./hooks"

export default function Presenter() {
  const ReviewContextValue = React.useContext(ReviewContext)
  const accountCategoryCnt = ReviewContextValue.accountCategoryCnt

  return (
    <Stack direction="column" sx={{ width: "100%" }} spacing={5}>
      <PageTitle
        title="口コミ・評価分析 LITE"
        description='「買いログ」に日々集まってくる"実購買者"の口コミ・評価データを集計できます。'
      />
      <PageAccordion />
      {accountCategoryCnt > 0 ?
        <PanelAnalysis />
        : (
          <Dialog
            open={true}
            sx={{
              ".MuiPaper-root": {
                p: 4,
              },
            }}
          >
            <DialogTitle
              sx={{
                fontWeight: "bold",
                textAlign: "center",
              }}
              id="alert-dialog-title"
            >
              このコンテンツはご利用できません
            </DialogTitle>
            <DialogContent>
              <DialogContentText sx={{ color: "text.primary" }} mt={2}>
                口コミ・評価分析LITEのご利用には別途お申込みが必要です。
              </DialogContentText>
              <DialogContentText sx={{ color: "text.primary" }} mt={1}>
                サービス内容や費用などの詳細につきましては、お手数ですが、営業担当までお問合せください。
              </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ textAlign: "center" }}>
              <Box textAlign="center" sx={{ width: "100%" }}>
                <Link href="/">TOP画面へ戻る</Link>
              </Box>
            </DialogActions>
          </Dialog>
        )
      }
    </Stack>
  )
}
