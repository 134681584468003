import { useContext, useState } from "react"
import { UseFormReturn } from "react-hook-form"
import { Box, CssBaseline } from "@mui/material"
import HeaderNav from "../components/HeaderNav"
import HeroArea from "../components/HeroArea"
import AreaBox from "../components/AreaBox"
import MainBox from "../components/MainBox"
import MainBoxTitleText from "../components/MainBoxTitleText"
import AuthCopyright from "../components/AuthCopyright"
import EmailForm from "./components/EmailForm"
import ButtonAndLink from "./components/ButtonAndLink"
import { ErrorWithFormProps, PasswordForgotFormInputs, FormContext } from "./hooks"
import "../auth.css"
import { useAuth } from "hooks/useAuth"

export default function Presenter({
  execute,
  error,
}: {
  execute: (data: PasswordForgotFormInputs) => Promise<void>
  error: ErrorWithFormProps
}) {
  const formMethods = useContext(FormContext) as UseFormReturn<PasswordForgotFormInputs>
  // パスワード変更ボタンの有効/無効
  const [disabledButtonFlag, setDisabledButtonFlag] = useState<boolean>(true)
  const auth = useAuth()

  return (
    <>
      <CssBaseline />
      <HeaderNav />
      <AreaBox>
        <HeroArea />

        <MainBox>
          <MainBoxTitleText text="パスワードを変更したいメールアドレスを入力してください"/>
          <Box component="form" noValidate sx={{ m: 1, mt: 2 }}>
            <EmailForm formMethods={formMethods} setDisabledButtonFlag={setDisabledButtonFlag} />
            <ButtonAndLink
              formMethods={formMethods}
              execute={execute}
              error={error}
              disabledButtonFlag={disabledButtonFlag}
            />
          </Box>
        </MainBox>

        {/* footer */}
        <AuthCopyright />
      </AreaBox>
    </>
  )
}
