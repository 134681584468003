import * as React from "react"
import ApplicationTemplate from "common/layouts/application"

import PermissionErrorBase from "common/components/PermissionErrorBase"

export default function PermissionErrorPage() {
  return (
    <ApplicationTemplate>
      <PermissionErrorBase />
    </ApplicationTemplate>
  )
}
