/*

	お知らせ画面下部バナー 買いログView

*/
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from "@mui/material/Link"
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export default function KailogView() {
  return (
    <Box>
      <Stack
        sx={{
          m: 2,
          p: 1,
          borderBottom: '1px solid lightgray',
        }}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
          買いログView
        </Typography>
        <Button
          variant="contained"
          color="info"
          href="https://researchandinnovation.jp.looker.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          決定
        </Button>
      </Stack>

      <Box p={2}>
        <Stack
          sx={{
            backgroundColor: "rgb(245, 245, 245)",
            height: '460px',
            overflowY: 'auto',
          }}
          alignItems="center"
          gap={4}
        >
          <Stack direction="column" alignItems="center" p={2}>
            <Box sx={{ maxWidth: '1250px', textAlign: 'center' }}>
              <Typography
                variant="h6"
                sx={{
                  fontSize: '32px',
                  fontWeight: 'bold',
                  color: 'rgb(49, 106, 165)',
                  textAlign: 'center',
                  marginBottom: '16px',
                }}
              >
                買いログViewとは
              </Typography>
              <Typography>
                口コミ・評価などの定性データ、MAU30万人を誇る購買の定量データをご提供。
              </Typography>
              <Typography>
                定性・定量の両面から、自社/競合商品や購買者のレビューに活用できるBIツールです。
              </Typography>
              <Typography>
                加工食品・飲料、日用雑貨等はもちろん、生鮮・惣菜データの提供も開始しました。
              </Typography>
            </Box>
          </Stack>

          <Box
            sx={{
              backgroundColor: "rgba(63, 114, 166, 0.1)",
              borderRadius: '8px',
              padding: '16px',
              width: '100%',
              maxWidth: '1250px',
            }}
          >
            <Stack
              direction="column"
              alignItems="center"
              px={2}
              gap={2}
              sx={{ width: '100%', maxWidth: '1250px' }}
            >
              <Stack direction="row" gap={2} sx={{ width: '100%' }}>
                <Stack
                  direction="row"
                  sx={{
                    backgroundColor: "rgba(255, 255, 255)",
                    borderRadius: '8px',
                    width: '50%',
                    p: 2,
                  }}>
                  <Box
                    component="img"
                    sx={{
                      width: '48px',
                      height: '48px',
                      mr: '8px',
                    }}
                    src="/information/kailog_view/about01.svg"
                  >
                  </Box>
                  <Box>
                    <Typography sx={{
                      fontSize: '24px',
                      fontWeight: 'bold',
                    }}>
                      圧倒的なサンプルサイズ「MAU約30万人」※
                    </Typography>
                    <List
                      sx={{
                        listStyleType: 'disc',
                        listStylePosition: 'inside',
                        padding: 0,
                      }}
                    >
                      <ListItem sx={{ display: 'list-item', padding: 0 }}>スーパー、CVS等のチェーン別分析が可能</ListItem>
                      <ListItem sx={{ display: 'list-item', padding: 0 }}>出現率が低い商品の分析にも有用</ListItem>
                    </List>
                  </Box>
                </Stack>
                <Stack
                  direction="row"
                  sx={{
                    backgroundColor: "rgba(255, 255, 255)",
                    borderRadius: '8px',
                    width: '50%',
                    p: 2,
                  }}>
                  <Box
                    component="img"
                    sx={{
                      width: '48px',
                      height: '48px',
                      mr: '8px',
                    }}
                    src="/information/kailog_view/about02.svg"
                  >
                  </Box>
                  <Box>
                    <Typography sx={{
                      fontSize: '24px',
                      fontWeight: 'bold',
                    }}>
                      信頼度が高く豊富な口コミ・評価レビュー
                    </Typography>
                    <List
                      sx={{
                        listStyleType: 'disc',
                        listStylePosition: 'inside',
                        padding: 0,
                      }}
                    >
                      <ListItem sx={{ display: 'list-item', padding: 0 }}>実購買データに紐づくリアルな口コミ</ListItem>
                      <ListItem sx={{ display: 'list-item', padding: 0 }}>口コミ7,000万件、評価点1.2億件の豊富な情報量（2024年1月時点）</ListItem>
                    </List>
                  </Box>
                </Stack>
              </Stack>
              <Stack direction="row" gap={2} sx={{ width: '100%' }}>
                <Stack
                  direction="row"
                  sx={{
                    backgroundColor: "rgba(255, 255, 255)",
                    borderRadius: '8px',
                    width: '50%',
                    p: 2,
                  }}>
                  <Box
                    component="img"
                    sx={{
                      width: '48px',
                      height: '48px',
                      mr: '8px',
                    }}
                    src="/information/kailog_view/about03.svg"
                  >
                  </Box>
                  <Box>
                    <Typography sx={{
                      fontSize: '24px',
                      fontWeight: 'bold',
                    }}>
                      日次更新による速報性
                    </Typography>
                    <List
                      sx={{
                        listStyleType: 'disc',
                        listStylePosition: 'inside',
                        padding: 0,
                      }}
                    >
                      <ListItem sx={{ display: 'list-item', padding: 0 }}>新商品の立上がりもスピーディにチェック</ListItem>
                      <ListItem sx={{ display: 'list-item', padding: 0 }}>プロモーション施策の検証も日別で</ListItem>
                    </List>
                  </Box>
                </Stack>
                <Stack
                  direction="row"
                  sx={{
                    backgroundColor: "rgba(255, 255, 255)",
                    borderRadius: '8px',
                    width: '50%',
                    p: 2,
                  }}>
                  <Box
                    component="img"
                    sx={{
                      width: '48px',
                      height: '48px',
                      mr: '8px',
                    }}
                    src="/information/kailog_view/about04.svg"
                  >
                  </Box>
                  <Box>
                    <Typography sx={{
                      fontSize: '24px',
                      fontWeight: 'bold',
                    }}>
                      生鮮・惣菜データも提供
                    </Typography>
                    <List
                      sx={{
                        listStyleType: 'disc',
                        listStylePosition: 'inside',
                        padding: 0,
                      }}
                    >
                      <ListItem sx={{ display: 'list-item', padding: 0 }}>約800分類の生鮮・惣菜分類データを整備</ListItem>
                      <ListItem sx={{ display: 'list-item', padding: 0 }}>チェーン横断でバーコード単位の購買分析が可能</ListItem>
                    </List>
                  </Box>
                </Stack>
              </Stack>
              <Box sx={{ alignSelf: 'flex-end' }}>※月間の買物登録者数</Box>
            </Stack>
          </Box>

          <Stack
            direction="column"
            pb={6}
            gap={3}
            sx={{ maxWidth: '1250px', width: '100%', textAlign: 'center' }}
          >
            <Typography
              variant="h6"
              sx={{
                fontSize: '32px',
                fontWeight: 'bold',
                color: 'rgb(49, 106, 165)',
                textAlign: 'center',
              }}
            >
              買いログとは？
            </Typography>
            <Typography>
              いつ、どこで、だれが、何を、何と一緒に購入しているのか、日々の買い物データを収集。
            </Typography>
            <Box
              sx={{
                width: '100%',
                padding: '48px',
                borderRadius: '8px',
                backgroundColor: 'white',
              }}
            >
              <Box
                component="img"
                src="/information/kailog_view/kailog.svg"
              >
              </Box>
            </Box>
          </Stack>

          <Stack
            direction="column"
            pb={6}
            gap={3}
            sx={{ maxWidth: '1250px', width: '100%', textAlign: 'center' }}
          >
            <Typography
              variant="h6"
              sx={{
                fontSize: '32px',
                fontWeight: 'bold',
                color: 'rgb(49, 106, 165)',
                textAlign: 'center',
              }}
            >
              買いログ 生鮮・惣菜データとは
            </Typography>
            <Stack direction="row" alignItems="center" gap={2}>
              <Box
                component="img"
                sx={{
                  width: '20%',
                  borderRadius: '8px',
                }}
                src="/information/kailog_view/kailog-mart.jpg"
              >
              </Box>
              <Stack alignItems="flex-start" sx={{ textAlign: "left" }} gap={2}>
                <Typography>
                  従来、生鮮・惣菜の商品情報を整備することは難題でしたが、弊社とインテージの協働で編み出した独自手法により、バーコード単位の高精度な生鮮・惣菜データを提供することが可能になりました。
                </Typography>
                <Typography>
                  生鮮・惣菜の購入個数・金額や単価の推移はもちろん、小売チェーン横断で生鮮・惣菜を含むバーコード単位の買いまわり等も分析できます。
                </Typography>
                <Typography sx={{ alignSelf: "flex-end" }}>
                  ※ご提供ツール：買いログView
                </Typography>
              </Stack>
            </Stack>
            <Typography
              variant="h6"
              sx={{
                fontSize: '24px',
                fontWeight: 'bold',
                mt: '16px',
              }}
            >
              生鮮・惣菜の商品情報を判定する、画期的な独自手法を開発
            </Typography>
            <Box
              sx={{
                width: '100%',
                padding: '48px',
                borderRadius: '8px',
                backgroundColor: 'white',
              }}
            >
              <Box
                component="img"
                sx={{
                  width: '100%',
                }}
                src="/information/kailog_view/kailog-first.svg"
              >
              </Box>
            </Box>
            <Typography sx={{ alignSelf: "flex-end" }}>
              ※80%以上の判定制度を担保
            </Typography>
            <Typography
              variant="h6"
              sx={{
                fontSize: '24px',
                fontWeight: 'bold',
                mt: '16px',
              }}
            >
              チェーン横断で生鮮・惣菜を含むバーコード単位の買いまわりが分かる
            </Typography>
            <Box
              sx={{
                width: '100%',
                padding: '48px',
                borderRadius: '8px',
                backgroundColor: 'white',
              }}
            >
              <Box
                component="img"
                sx={{
                  width: '100%',
                }}
                src="/information/kailog_view/kailog-second.svg"
              >
              </Box>
            </Box>
          </Stack>
        </Stack>
      </Box>
    </Box>
  )
}
