import { createContext } from "react"
import { useNavigate, useLocation } from 'react-router-dom';
import { SubmitErrorHandler, useForm, UseFormReturn } from "react-hook-form"
import { z } from "zod"
import { makeZodI18nMap } from "zod-i18n-map"
import { zodResolver } from "@hookform/resolvers/zod"
import { useAuth } from "hooks/useAuth"
import Presenter from "./Presenter"
import { Auth } from "aws-amplify"
import { useMutation, gql } from "urql"

const genRandomId = (length: number = 36) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charactersLength);
      result += characters.charAt(randomIndex);
  }
  return result;
}


export type AccountDataResult = {
    success: boolean
    type: string
}

export type saveAccountDataResult = {
    success: boolean
}



export const FormContext = createContext<
  UseFormReturn<LoginFormInputs> | undefined
>(undefined)

// execute Zod
z.setErrorMap(makeZodI18nMap({ ns: ["authForm", "baseCustomedForm", "zod"] }))
const schema = z.object({
  username: z.string().min(1,{message: '必須項目です'}),
  companyname: z.string().min(1,{message: '必須項目です'}),
  departmentname: z.string().min(1,{message: '必須項目です'}),
  divisionname: z.string(),
})
export type LoginFormInputs = z.infer<typeof schema>
export type ErrorWithFormProps = SubmitErrorHandler<LoginFormInputs>


/**
 * Provider component for LoginForm.
 * @returns React component
 */
export default function Provider() {
  const [result, executeMutation] = useMutation(
    gql/* GraphQL */ `
      mutation saveAccountData($Data: SaveAccountDataInput!) {
        saveAccountData(accountData: $Data) {
          result
        }
      }
    `
  )

  const formMethods = useForm<LoginFormInputs>({
    resolver: zodResolver(schema),
  })
  if (formMethods === undefined) throw new Error("formMethods is undefined.")

  // singUp
  const execute = async (data: LoginFormInputs) => {
    const accountDataSession = genRandomId()
    try {
      const user = await Auth.currentAuthenticatedUser()
      await Auth.updateUserAttributes(user, {
        'custom:accountDataSession': accountDataSession
      })
      executeMutation({
        Data: {
          userName: data.username,
          companyName: data.companyname,
          departmentName: data.departmentname,
          divisionName: data.divisionname,
          accountDataSession: accountDataSession
        }
      }).then((res) => {
        if (res.error || !res.data || res.data.result === false)
          throw Error("saveAccountData error")

        window.location.href = '/'
      }).catch((e) => {
        throw Error("saveAccountData error")
      })
    } catch (error) {
      console.error(error)
      return
    }
  }

  // signUp with error (form input error)
  const error: ErrorWithFormProps = (errors, event) => {
    console.debug("errorWithSignIn", "errors", errors, "event", event)
    return
  }

  return (
    <FormContext.Provider value={formMethods}>
      <Presenter execute={execute} error={error} />
    </FormContext.Provider>
  )
}
