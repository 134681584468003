/**
 * Environment variables
 */
import ssm from "../env/ssm.json"
import sls from "../env/sls.json"

export type EnvTypes = "development" | "test" | "staging" | "production"
export const Env = (process.env.REACT_APP_ENV as EnvTypes) || "development"
export const SKIP_RECAPTCHA =
  "SKIP_RECAPTCHA" in ssm ? ssm.SKIP_RECAPTCHA === "true" : true
export const SKIP_OTP = "SKIP_OTP" in ssm && ssm.SKIP_OTP === "true"
export const ICANVAS_REQUEST_ERROR = "ICanvasRequestError"
export const ICANVAS_DATA_IN_UPDATE_ERROR = "ICanvasDataInUpdateError"

// デバッグモード
export const DebugMode = process.env.REACT_APP_DEBUG_MODE === "true"

export const SERVICE_DOMAIN =
  process.env.REACT_APP_SERVICE_DOMAIN || "cxmp-dev.kailog.jp" // TODO: SERVICE_DOMAIN をAmplifyで自動設定する
export const SERVICE_EMAIL =
  process.env.REACT_APP_SERVICE_EMAIL || "no-reply@kailog.jp"

/**
 * MSW
 */
// Base URL
export const baseUrl =
  process.env.REACT_APP_BASE_URL || "https://localhost:3001"

/**
 * GraphQL
 */
export const graphqlEndpoint =
  process.env.REACT_APP_GRAPHQL_ENDPOINT || "http://localhost:4566/"

/**
 * Lambda iCanvas
 */
// Lambda iCanvas 接続タイムアウト
export const LambdaIcanvasConnTimeout = 30 * 1000 // 30sec

/**
 * Dummy Login
 */
export const DummyLoginEmails = ["intage.com", "r-n-i.jp"]

export const EXIST_ACCOUNT_DATA_ENDPOINT = sls.ExistAccountDataEndpoint
export const AFTER_SIGNUP_ENDPOINT = sls.AfterSignupEndpoint

export const API_GATEWAY_API_KEY = sls.ApiKey
