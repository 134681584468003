/*

	お知らせ画面下部バナー 口コミ・評価分析LITE

*/
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from "@mui/material/Link"
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export default function Review() {
  return (
    <Box>
      <Stack
        sx={{
          m: 2,
          p: 1,
          borderBottom: '1px solid lightgray',
        }}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
          口コミ・評価分析LITE
        </Typography>
        <Button
          variant="contained"
          color="info"
          href="/review"
        >
          決定
        </Button>
      </Stack>
      <Box p={2}>
        <Stack
          sx={{
            backgroundColor: "rgb(245, 245, 245)",
            height: '460px',
            overflowY: 'auto',
          }}
          alignItems="center"
          gap={4}
        >
          <Stack direction="column" alignItems="center" p={2}>
            <Box sx={{ maxWidth: '1250px', textAlign: 'center' }}>
              <Typography
                variant="h6"
                sx={{
                  fontSize: '32px',
                  fontWeight: 'bold',
                  color: 'rgb(49, 106, 165)',
                  textAlign: 'center',
                  marginBottom: '16px',
                }}
              >
                実購買者が記入するリアルな口コミ・評価の抽出ツール
              </Typography>
              <Typography>
                お買い物アプリ「CODE」で買物登録した実購買者だけが記入できる商品の口コミと評価点。バーコード（単品）に紐づくリアルな生活者の声を抽出できます。
              </Typography>
              <Typography>
                2024年1月時点で口コミ: 7,000万件、評価点: 1.2億件の膨大なデータが蓄積され、日々増え続けています。
              </Typography>
            </Box>
          </Stack>

          <Stack
            direction="column"
            px={2}
            pb={6}
            gap={3}
            sx={{ maxWidth: '1250px' }}
          >
            <Typography
              variant="h6"
              sx={{
                fontSize: '32px',
                fontWeight: 'bold',
                color: 'rgb(49, 106, 165)',
                textAlign: 'center',
              }}
            >
              買いログ　口コミ・評価の収集方法
            </Typography>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Box
                component="img"
                sx={{
                  width: '48%',
                  borderRadius: '8px',
                  backgroundColor: 'white',
                }}
                src="/information/review/kailog_portal_con01.png"
              >
              </Box>
              <Box sx={{ width: '48%' }}>
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: '24px',
                    fontWeight: 'bold',
                    mb: '16px',
                  }}
                >
                  レシートとバーコードにより購買証明された商品に関する口コミと評価点
                </Typography>
                <Typography>
                  口コミ・評価は、実際に買物登録したユーザーだけが記入できる仕組みです。買物登録する際にはレシートとバーコードが必要なため、購買が証明された商品についての口コミ、評価点が蓄積されています。
                </Typography>
                <Typography>
                  またバーコード（単品）ごとに記入する仕組みなので、同じブランドの中でも容量やフレーバーの違いなど、具体的にどの商品の評価かを把握できます。
                </Typography>
              </Box>
            </Stack>
          </Stack>

          <Stack
            direction="column"
            px={2}
            pb={6}
            gap={3}
            sx={{ maxWidth: '1250px' }}
          >
            <Typography
              variant="h6"
              sx={{
                fontSize: '32px',
                fontWeight: 'bold',
                color: 'rgb(49, 106, 165)',
                textAlign: 'center',
              }}
            >
              評価点と口コミがセットだからできること
            </Typography>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Box sx={{ width: '48%' }} pr={2}>
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: '24px',
                    fontWeight: 'bold',
                    mb: '16px',
                  }}
                >
                  評価点により、商品評価のポジ・ネガを容易に判別できます
                </Typography>
                <Typography>
                  買いログの口コミは基本的に評価点とセットで記入されます※。そのため実ユーザーの商品評価の総合的なポジ・ネガが、5点満点（0.5点刻み）の点数から容易に判別できます。
                </Typography>
                <Typography mt={2}>
                  ※口コミの記入は任意のため、評価点のみのレコードもあります
                </Typography>
              </Box>
              <Box
                component="img"
                sx={{
                  width: '48%',
                  borderRadius: '8px',
                  backgroundColor: 'white',
                }}
                src="/information/review/kailog_portal_con02.png"
              >
              </Box>
            </Stack>
          </Stack>

          <Stack
            direction="column"
            px={2}
            pb={6}
            gap={3}
            sx={{ maxWidth: '1250px' }}
          >
            <Typography
              variant="h6"
              sx={{
                fontSize: '32px',
                fontWeight: 'bold',
                color: 'rgb(49, 106, 165)',
                textAlign: 'center',
              }}
            >
              買いログ　口コミ・評価の特長
            </Typography>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Box
                component="img"
                sx={{
                  width: '48%',
                  borderRadius: '8px',
                  backgroundColor: 'white',
                }}
                src="/information/review/kailog_portal_con03.png"
              >
              </Box>
              <Box sx={{ width: '48%' }}>
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: '24px',
                    fontWeight: 'bold',
                    mb: '16px',
                  }}
                >
                  記入者の属性情報を踏まえてブランドや商品ごとの評価を把握できます
                </Typography>
                <Typography>
                  買いログの口コミ・評価はバーコード単位で取得している上に、カテゴリ・ブランド・商品名等のマスタ情報と紐づいています。また、記入したCODEアプリユーザーの属性情報（性別、年代、都道府県）もわかります。
                </Typography>
                <Typography mt={2}>
                  そのため、匿名性が高く、何に言及しているかが曖昧になりがちなSNSの口コミ情報等と比べて、特定のブランドや商品に関する評価を、記入者の属性を踏まえて把握するのに有用です。
                </Typography>
              </Box>
            </Stack>
          </Stack>

          <Stack
            direction="column"
            px={2}
            pb={6}
            gap={3}
            sx={{ maxWidth: '1250px' }}
          >
            <Typography
              variant="h6"
              sx={{
                fontSize: '32px',
                fontWeight: 'bold',
                color: 'rgb(49, 106, 165)',
                textAlign: 'center',
              }}
            >
              誰もが直感的な操作で使えます
            </Typography>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Box sx={{ width: '48%' }} pr={2}>
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: '24px',
                    fontWeight: 'bold',
                    mb: '16px',
                  }}
                >
                  クリックのみでデータ抽出～ダウンロードが可能
                </Typography>
                <Typography>
                  口コミ・評価分析LITEでは、データ期間を選び、メーカー/ブランド/バーコードなどのフィルタで対象を指定してボタンを押すだけの簡単な操作で、買いログの口コミ・評価の抽出とダウンロードができます。
                </Typography>
                <Typography mt={2}>
                  自社および競合商品のレビューや、取引先に対する自社商品の評価ポイントのアピールなど、様々なシーンで実ユーザーの生の声をご活用ください！
                </Typography>
              </Box>
              <Box
                component="img"
                sx={{
                  width: '48%',
                  borderRadius: '8px',
                  backgroundColor: 'white',
                }}
                src="/information/review/kailog_portal_con04.png"
              >
              </Box>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </Box>
  )
}
