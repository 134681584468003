import "./style.css"

function TermPage() {

  return (
    <div id="container">
    <h1>買いログ（ポータル）利用規約</h1>
    <br />
    <p>
      本利用規約（以下「本規約」といいます。）には、株式会社リサーチ・アンド・イノベーション（以下「当社」といいます。）の提供する買いログ（ポータル）のご利用にあたり、クライアントの皆様に遵守していただかなければならない事項及び当社とクライアントの皆様との間の権利義務関係が定められております。当該サービスをクライアントとしてご利用になる方は、本規約に同意する前に、必ず全文お読み下さいますようお願い致します。
    </p>
    <br />
    <h2>第 1 条 （適用）</h2>
    <ol>
      <li>本規約は、本サービス（第2条に定義）の利用に関する当社とクライアント（第2条に定義）との間の権利義務関係を定めることを目的とし、クライアントと当社の間の本サービスの利用に関わる一切の関係に適用されます。</li>
      <li>当社が本ウェブサイト（第2条に定義）上で随時掲載する本サービスに関するルール、諸規定等は本規約の一部を構成するものとします。</li>
    </ol>
    <h2>第 2 条 （定義）</h2>
    <p>本規約において使用する以下の用語は各々以下に定める意味を有するものとします。</p>
    <ol>
      <li>
        「本サービス」とは、当社が提供する「買いログ（ポータル）」という名称のクライアントのコンテンツ（第3号に定義）へのリンク又はシングルサインオンを可能にするサービス（理由の如何を問わずサービスの名称又は内容が変更された場合は、当該変更後のサービスを含みます。）を意味します。
      </li>
      <li>「クライアント」とは、当社または株式会社インテージ（以下「インテージ」といいます。）との間で「コンテンツ利用契約」（第４号に定義）を締結している者であって、第3条に基づき本利用契約を締結する個人又は法人を意味します。
      </li>
      <li>
        「コンテンツ」とは、当社が運営し、収集するCODEという買い物アプリから収集した購買データベース「買いログ」を利用し行う、アンケート、インタビュー、データ分析等の個々のマーケティングサービス又はインテージが提供するパネル調査データベースに関するサービスを意味します。
      </li>
      <li>「コンテンツ利用契約」とは、各コンテンツ（前号に定義）の利用契約を意味します。</li>
      <li>
        「本ウェブサイト」とは、そのドメインが「https://kailog.jp/」である当社が運営し本サービスを利用できるウェブサイト（理由の如何を問わず本サービスのウェブサイトのドメイン又は内容が変更された場合は、当該変更後のウェブサイトを含みます。）を意味します。
      </li>
      <li>「知的財産権」とは、著作権、特許権、実用新案権、商標権、意匠権その他の知的財産権（それらの権利を取得し、又はそれらの権利につき登録等を出願する権利を含みます。）を意味します。</li>
      </ol>

    <h2>第 3 条 （登録）</h2>
    <ol>
      <li>
        本サービスの利用を希望する者（以下「登録希望者」といいます。）は、本規約を遵守することに同意し、かつ当社の定める一定の情報（以下「登録情報」といいます。）を当社所定の利用申込書に記載のうえ当該利用申込書を当社に提供することにより、当社に対し、本サービスの利用の登録を申請することができます。
      </li>
      <li> 当社は、前項に基づき登録を申請した者が、以下の各号のいずれかの事由に該当する場合は、登録を拒否することがあります。</li>
      <ol>
        <li>当社に提供された登録情報の全部又は一部につき虚偽、誤記又は記載漏れがあった場合</li>
        <li>未成年者、成年被後見人、被保佐人又は被補助人のいずれかであり、法定代理人、後見人､保佐人又は補助人の同意等を得ていなかった場合</li>
        <li>
          反社会的勢力（暴力団、暴力団員、暴力団準構成員、暴力団関係企業、総会屋、その他暴力、威力又は詐欺的手法を使用して経済的利益を追求する集団又は個人を意味します。以下同じ。）である、又は資金提供その他を通じて反社会的勢力の維持、運営若しくは経営に協力若しくは関与する等反社会的勢力との何らかの交流若しくは関与を行っていると当社が判断した場合
        </li>
        <li>その他、当社が登録を適当でないと判断した場合</li>
        </ol>
        <li>当社は、前項その他当社の基準に従って、登録希望者の登録の可否を判断し、当社が登録を認める場合にはその旨を登録希望者（以下「クライアント」といいます。）に通知します。</li>
      <li>
        当社が前項に基づき登録を認める旨の通知をした場合、当該通知日または別途当社が指定する日（以下「契約日」といいます。）に本規約の諸規定に従った本サービスの利用にかかる契約（以下「本利用契約」といいます。）がクライアントと当社の間に成立します。
      </li>
      <li>
        コンテンツの利用にあたっては、別途コンテンツ毎に、各コンテンツの利用規約に同意いただき、コンテンツ利用契約を締結する必要があります。コンテンツ利用契約が、本利用契約の一部の適用を排除しまたは本利用契約と異なる事項を定めたときは、当該コンテンツ利用契約が優先して適用されます。なお、本サービスに基づき発行されるユーザーID及びパスワードの取扱いについては、本利用契約が適用され、各コンテンツ毎に発行されたユーザーID及びパスワードの取扱いについては、当該コンテンツ利用契約が適用されます。
      </li>
      <li>クライアントは、登録情報に変更があった場合は、遅滞なく、当社の定める方法により、当該変更事項を当社に通知し、当社から要求された資料を提出するものとします。</li>
      </ol>
      <h2>第 4 条 （ユーザーの利用登録）</h2>
      <ol>
      <li>
        クライアントは、自己の役員・従業員であって、本サービスを利用する者（以下「ユーザー」といいます。）毎に、本規約の内容に同意させたうえ、当社が定める手続による利用登録を行わせるものとし、当該利用登録時に各ユーザーに関する情報（以下「ユーザー情報」といいます。）を正確に当社に対して提供させるものとします。
      </li>
      <li>前条第２項は、ユーザーの利用登録に準用するものとします。</li>
      <li>クライアントは、ユーザー情報に変更があった場合は、当社指定の方法に従い、各ユーザーをして速やかに変更・削除を行わせるものとします。</li>
      </ol>
      <h2>第 5 条 （本サービスの利用条件）</h2>
      <ol>
        <li>クライアントは、利用契約の有効期間中、本規約に従って、当社の定める方法に従い、本サービスを利用することができます。</li>
        <li>クライアントは、本サービスを、自己の役員又は従業員に対してのみ使用させることができるものとし、その他の第三者に対して使用させることはできません。</li>
      <li>
        クライアントは、ユーザーに対し、本規約を遵守させるものとします。ユーザーが本サービス上で行う一切の行為は、当該ユーザーが属するクライアントの行為とみなされ、クライアントに属するユーザーによる本サービスの利用及び本規約の遵守につき、クライアントは当社に対して一切の責任を負うものとします。
      </li>
      </ol>
      <h2>第 6 条 （料金及び支払方法）</h2>
      <ol>
        <li>クライアントは、本サービスを無償で利用することができます。</li>
        <li>本サービスを介しコンテンツを利用する場合は、各コンテンツ利用契約に基づき利用料金が発生する場合があります。</li>
      </ol>
      <h2>第 7 条 （パスワード及びユーザーIDの管理）</h2>
      <ol>
        <li>クライアントは、自己の責任において、パスワード及びユーザーIDを管理及び保管するものとします。</li>
        <li>クライアントは、パスワード及びユーザーIDの共有、貸与、譲渡、名義変更、売買等をしてはならないものとします。</li>
        <li>パスワード又はユーザーIDの管理不十分、使用上の過誤、第三者の使用等による損害の責任はクライアントが負うものとし、当社は一切の責任を負いません。</li>
        <li>当社は、本サービスを介したコンテンツの利用について、当該利用がクライアント以外の第三者であっても、クライアントによる利用とみなします。</li>
        <li>クライアントは、パスワード又はユーザーIDが盗まれたり、第三者に使用されていることが判明した場合には、直ちにその旨を当社に通知するとともに、当社からの指示に従うものとします。</li>
      </ol>
      <h2>第 8 条 （利用者情報の取扱い）</h2>
    <p>
      当社は、本サービス及びコンテンツの利用にあたりクライアントに登録いただくクライアント及びユーザーの個人情報、クライアント及びユーザーの本サービス及びコンテンツの利用履歴（以下「利用者情報」といいます。）について、以下のとおり取り扱うものとします。
    </p>
    <ol>
      <li>取得する情報<br />
        * 氏名<br />
        * メールアドレス<br />
        * 企業名<br />
        * 部署名<br />
        * 本サービス及びコンテンツの利用履歴<br />
        * 本サービス及びコンテンツの利用内容<br />
      </li>
      <li>利用者情報の利用目的<br />
        * 本サービス及びコンテンツの運用管理<br />
        * 本サービス及びコンテンツを利用するためのパスワードの通知<br />
        * 本サービス及びコンテンツに関するシステムトラブル発生時のトレーサビリティ及び当該トラブルの内容確認等の連絡<br />
        * 本サービス及びコンテンツの改善<br />
        * 本サービス及びコンテンツの利用促進のための諸施策<br />
      </li>
      <li>利用者情報の取扱いの委託<br />
        当社は、利用者情報の取扱いの全部又は一部を委託します。この場合、利用者情報の安全管理が図られるよう、当該委託先に対して必要、且つ、十分な監督を行うものとします。
      </li>
      <li>その他<br />
        前各号のほかは、当社の個人情報保護方針（https://r-n-i.jp/privacy/）によるものとします。
      </li>
      </ol>
      <h2>第 9 条 （禁止行為）</h2>
      <ol>
        1. クライアントは、本サービスの利用にあたり、以下の各号のいずれかに該当する行為をしてはなりません。
        <ol>
          <li>当社又は第三者の知的財産権、肖像権、プライバシーの権利、名誉、その他の権利又は利益を侵害する行為（かかる侵害を直接又は間接に惹起する行為を含みます。）</li>
          <li>犯罪行為に関連する行為又は公序良俗に反する行為</li>
          <li>コンピューター・ウィルスその他の有害なコンピューター・プログラムを含む情報を送信する行為</li>
          <li>当社による本サービスの運営を妨害するおそれのある行為</li>
          <li>その他、当社が不適切と判断する行為</li>
        </ol>
      </ol>
      <h2>第 10 条 （本サービスの停止等）</h2>
      <ol>
        <li>当社は、以下のいずれかに該当する場合には、クライアントに事前に通知することなく、本サービスの利用の全部又は一部を停止又は中断することができるものとします。</li>
        <ol>
          <li>本サービスに係るコンピューター・システム及び設備の点検又は保守作業を定期的又は緊急に行う場合</li>
          <li>コンピューター、通信回線等が事故により停止した場合</li>
          <li>天災地変、戦争、テロ活動等の破壊活動その他の非常理由により本サービスの運営ができなくなった場合</li>
          <li>災害、停電その他当社がコントロールできない事由、その他当社の事情によらない事由により本サービスの運営ができなくなった場合</li>
          <li>当社が使用するデータ分析プラットフォームの使用ができなくなった場合</li>
          <li>その他、当社が停止又は中断を必要と判断した場合</li>
        </ol>
        <li>本サービスにおいては、緊急メンテナンスが突発的に発生する場合があります。それに伴い、クライアントに事前に通知することなく、クライアントがデータを見られない時間帯が発生します。</li>
        <li>当社は、当社の都合により本サービスに新しいサービスを追加、変更し、又は本サービスの全部又は一部の提供を終了することができます。</li>
        <li>当社は、本条に基づき当社が行った措置に基づきクライアントに生じた損害について一切の責任を負いません。</li>
      </ol>
      <h2>第 11 条 （設備の負担等）</h2>
      <ol>
      <li>
        本サービスの提供を受けるために必要な、コンピューター、ソフトウェアその他の機器、通信回線その他の通信環境等の準備及び維持は、クライアントの費用と責任において行うものとし、当社は、本サービスにおける動作保証及び使用機器への適合性について一切保証しません。
      </li>
      <li>クライアントは自己の本サービスの利用環境に応じて、コンピューター・ウィルスの感染の防止、不正アクセス及び情報漏洩の防止等のセキュリティ対策を自らの費用と責任において講じるものとします。</li>
      <li>
        クライアントは、本サービスの利用開始に際し、又は本サービスの利用中に、本ウェブサイトからのダウンロードその他の方法によりソフトウェア等をクライアントのコンピューター等にインストールする場合には、クライアントが保有する情報の消滅若しくは改変又は機器の故障、損傷等が生じないよう十分な注意を払うものとし、当社はクライアントに発生したかかる損害について一切責任を負わないものとします。
      </li>
      </ol>
      <h2>第 12 条 （権利帰属）</h2>
      <ol>
      <li>
        本ウェブサイト、本サービスに関する所有権及び知的財産権は全て当社に帰属しており、本規約に定める登録に基づく本サービスの利用許諾は、本規約において明示されているものを除き、本ウェブサイト、本サービスに関する当社又は当社の知的財産権の使用許諾を意味するものではありません。
      </li>
      <li>クライアントは、本サービス提供のためのソフトウェア等の複製、改変、リバースエンジニアリング、逆コンパイル、逆アセンブルその他本サービスにかかる当社の知的財産権を侵害する行為を一切行ってはならないものとします。</li>
      </ol>
      <h2>第 13 条 （本サービスの利用停止、契約解除）</h2>
      <ol>
        <li>当社は、クライアント（クライアントに属するユーザーを含みます。）が、以下の各号のいずれかの事由に該当する場合は、事前に通知又は催告することなく、本サービスの利用を一時的に停止することができます。</li>
        <ol>
          <li>本規約のいずれかの条項に違反した場合</li>
          <li>登録情報又はユーザー情報に虚偽の事実があることが判明した場合</li>
          <li>支払停止若しくは支払不能となり、又は破産手続開始、民事再生手続開始、会社更生手続開始、特別清算開始若しくはこれらに類する手続の開始の申立てがあった場合</li>
          <li>30日以上当社からの連絡に対して応答がない場合</li>
          <li>第3条第2項各号及び第９条1項各号に該当する場合</li>
          <li>その他、当社がクライアントとしての登録の継続を適当でないと判断した場合</li>
        </ol>
        <li>前項各号のいずれかの事由に該当した場合、当社は、7日前までに当社所定の方法でクライアントに通知することにより、ユーザーの利用登録を取り消し、又は本利用契約を解除することができます。</li>
        <li>当社は、本条に基づき当社が行った行為によりクライアントに生じた損害について一切の責任を負いません。</li>
      </ol>
      <h2>第 14 条 （保証の否認及び免責）</h2>
      <ol>
      <li>
        当社は、本サービスにつき如何なる保証も行うものではありません。本サービスは現状有姿で提供されるものであり、当社は本サービスについて、クライアントの特定の目的への適合性、期待する機能・商業的有用性・正確性・完全性を有すること、継続的に利用できること、及び不具合が生じないこと等を含め、一切保証を致しません。
      </li>
      <li>
        クライアントが当社から直接又は間接に、本サービス及び本ウェブサイト、その他の事項に関する何らかの情報を得た場合であっても、当社はクライアントに対し本規約において規定されている内容を超えて如何なる保証も行うものではありません。
      </li>
      <li>
        クライアントは、本サービスを利用することが、クライアントに適用のある法令、業界団体の内部規則等に違反するか否かを自己の責任と費用に基づいて調査するものとし、当社は、クライアントによる本サービスの利用が、クライアントに適用のある法令、業界団体の内部規則等に適合することを何ら保証するものではありません。
      </li>
      <li>本サービスに関連してクライアントと第三者との間において生じた取引、連絡、紛争等については、クライアントの責任において処理及び解決するものとし、当社はかかる事項について一切責任を負いません。</li>
      <li>
        当社は、当社による本サービスの提供の中断、停止、終了、利用不能又は変更、クライアントの登録の取消、本サービスの利用によるデータの消失又は機器の故障若しくは損傷、その他本サービスに関連してクライアントが被った損害につき、賠償する責任を一切負わないものとします。
      </li>
      <li>
        本ウェブサイトから他のウェブサイトへのリンク又は他のウェブサイトから本ウェブサイトへのリンクが提供されている場合でも、当社は、本ウェブサイト以外のウェブサイト及びそこから得られる情報に関して如何なる理由に基づいても一切の責任を負わないものとします。
      </li>
      <li>
        当社は、当社に故意、重過失がある場合を除き、本サービスに関連してクライアントが被った損害について、一切賠償の責任を負いません。法令の適用その他の理由により当社がクライアントに対して損害賠償責任を負う場合においても、賠償すべき損害の範囲は、ユーザーに現実に発生した直接かつ通常の損害に限るものとし、付随的損害、間接損害、逸失利益を含む特別損害は含まれないものとします。
      </li>
      </ol>
      <h2>第 15 条 （賠償等の責任）</h2>
      <p>クライアントは、本規約に違反することにより、又は本サービスの利用に関連して当社に損害を与えた場合、当社に対しその損害を賠償しなければなりません。</p>

    <h2>第 16 条 （秘密保持）</h2>
    <ol>
      <li>
        クライアント及び当社は、本サービスの提供に関して知り得た相手方の秘密情報（本サービスに関するノウハウ、当社のシステムに関する情報、技術上又は営業上の一切の秘密情報を含みます。）を、厳重かつ適正に管理するものとし、相手方の事前の書面による同意なく第三者（当社の関連会社及び委託先を含みます。）に開示、提供及び漏洩し、また、秘密情報を本規約及び利用契約に基づく権利の行使又は義務の履行以外の目的で使用してはならないものとします。
      </li>
      <li>次の各号の情報は、秘密情報に該当しないものとします。</li>
      <ol>
        <li>開示を受けた時、既に所有していた情報</li>
        <li>開示を受けた時、既に公知であった情報又はその後自己の責に帰さない事由により公知となった情報</li>
        <li>開示を受けた後に、第三者から合法的に取得した情報</li>
        <li>開示された秘密情報によらず独自に開発し又は創作した情報</li>
      </ol>
      <li>
        前項にかかわらず、クライアント及び当社は、裁判所又は行政機関から、秘密情報の開示を命令され又は法令等に基づき秘密情報の開示を要求された場合、当該命令・要求の範囲において秘密情報を開示することができるものとします。但し、当該命令、要求又は要請があった場合、速やかにその旨を相手方に通知しなければなりません。
      </li>
      <li>
        第１項にかかわらず、当社は、クライアントの秘密情報を、契約書及び取引先管理、会計又は税務上の処理若しくは確認並びに挨拶状送付等の業務を実施する目的に必要な範囲に限り、株式会社インテージホールディングス、株式会社インテージ及び株式会社インテージ・アソシエイツ（以下総称して「開示先」といいます。）に対して開示することができるものとします。但し、当社は、開示先に利用契約により自己が負う義務と同等の義務を課すものとし、かかる義務違反についてクライアントに対し責任を負います。
      </li>
      <li>クライアント及び当社は、相手方の指示があった場合又は利用契約が終了した場合は、相手方の指示に従い速やかに秘密情報を、返却又は廃棄し、以後使用しないものとします。</li>
      </ol>
      <h2>第 17 条 （有効期間）</h2>
      <ol>
        <li>本利用契約の有効期間は、第３条第４項に定める契約日に効力を生じ、本利用契約が解除された日又は本サービスの提供が終了した日のいずれか早い日まで、当社とクライアントとの間で有効に存続するものとします。</li>
        <li>本利用契約が終了した場合であっても、第16条の規定は、本利用契約の終了後も3年間は有効に存続するものとします。</li>
      </ol>
      <h2>第 18 条 （本規約等の変更）</h2>
      <ol>
        <li>当社は、本サービスの内容を自由に変更できるものとします。</li>
      <li>
        当社は、本規約（本ウェブサイトに掲載する本サービスに関するルール、諸規定等を含みます。以下本項において同じ。）を変更できるものとします。当社は、本規約を変更する場合には、クライアントに当該変更内容及び効力発生日を定め、第19条に規定する方法で事前に通知するものとします。
      </li>
      </ol>
      <h2>第 19 条 （連絡/通知）</h2>
      <p>本サービスに関する問い合わせその他クライアントから当社に対する連絡又は通知、及び本規約の変更に関する通知その他当社からクライアントに対する連絡又は通知は、当社の定める方法で行うものとします。</p>

    <h2>第 20 条 （本規約の譲渡等）</h2>
    <ol>
      <li>クライアントは、当社の書面による事前の承諾なく、本利用契約上の地位又は本規約に基づく権利若しくは義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分をすることはできません。</li>
      <li>
        当社は本サービスにかかる事業を他社に譲渡した場合には、当該事業譲渡に伴い本利用契約上の地位、本規約に基づく権利及び義務並びにクライアントの登録情報その他の顧客情報を当該事業譲渡の譲受人に譲渡することができるものとし、クライアントは、かかる譲渡につき本項において予め同意したものとします。なお、本項に定める事業譲渡には、通常の事業譲渡のみならず、会社分割その他事業が移転するあらゆる場合を含むものとします。
      </li>
      </ol>
      <h2>第 21 条 （分離可能性）</h2>
    <p>
      本規約のいずれかの条項又はその一部が、消費者契約法その他の法令等により無効又は執行不能と判断された場合であっても、本規約の残りの規定及び一部が無効又は執行不能と判断された規定の残りの部分は、継続して完全に効力を有し、当社及びクライアントは、当該無効若しくは執行不能の条項又は部分を適法とし、執行力を持たせるために必要な範囲で修正し、当該無効若しくは執行不能な条項又は部分の趣旨並びに法律的及び経済的に同等の効果を確保できるように努めるものとします。
    </p>

    <h2>第 22 条 （準拠法及び管轄裁判所）</h2>
    <p>本規約の準拠法は日本法とし、本規約に起因し又は関連する一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。</p>

    <h2>第 23 条 （協議解決）</h2>
    <p>当社及びクライアントは、本規約に定めのない事項又は本規約の解釈に疑義が生じた場合には、互いに信義誠実の原則に従って協議の上速やかに解決を図るものとします。</p>


    【2024年5月10日制定】
    </div>
  )
}

export default TermPage
