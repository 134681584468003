/*

	インナーパネルのベース

*/
import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"
import { styled } from "@mui/material/styles"
import { SxProps, Theme } from "@mui/system"

const InnerPanelBox = styled(Box)(() => ({
  width: "100%",
  backgroundColor: "#fff",
  border: "1px solid #ccc",
  borderRadius: "10px",
  padding: "24px",
}))

const InnerPanelStack = styled(Stack)(() => ({
  justifyContent: "center",
  height: "100%",
  width: "100%",
}))

export default function InnerPanel(props: {
  children: React.ReactNode,
  sx?: SxProps<Theme>,
}) {
  return (
    <InnerPanelBox sx={props.sx}>
      <InnerPanelStack>{props.children}</InnerPanelStack>
    </InnerPanelBox>
  )
}
