import AccountMenu from "features/components/accountMenu"
import MenuIcon from "@mui/icons-material/Menu"
import { IconButton, Toolbar, Typography } from "@mui/material"
import CustomedAppBar from "./CustomedAppBar"
import Box from '@mui/material/Box';

export default function Header({
  toggleDrawer,
  open,
  pageName,
  drawer,
}: {
  toggleDrawer: () => void
  open: boolean
  pageName?: string
  drawer?: number
}) {
  // set default params.
  pageName = pageName 
  drawer = drawer || 260

  return (
    <CustomedAppBar position="absolute" open={open} drawer={drawer}>
      <Toolbar
        sx={{
          pr: "24px", // Keep right padding when drawer closed
        }}
      >
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer}
          sx={{
            marginRight: "36px",
            ...(open && { display: "none" }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          sx={{ flexGrow: 1 }}
        >
          <Box
            component="img"
            sx={{
              height: 24,
              verticalAlign: "top",
            }}
            src="/kailog_white.png"
          />
          {pageName}
        </Typography>
        <AccountMenu />
      </Toolbar>
    </CustomedAppBar>
  )
}
