import { CognitoUser } from "amazon-cognito-identity-js"
import { Amplify, Auth } from "aws-amplify"
import { AmplifyConfig } from "constants/amplify"
import {
  isICanvasError,
  setGlobalMessage,
  showICanvasError,
} from "hooks/useGlobalMessage"
import { existAccountData } from "utils/auth/existAccountData"

/**
 * @fileoverview useAuth custom hook.
 * @see https://qiita.com/kde15/items/851aea71504cfa6ea680
 *
 * usage:
 *   import { useAuth } from "hooks/useAuth"
 *   useAuth()
 */
import React, { createContext, useContext, useEffect, useState } from "react"

import { DummyLoginEmails, ICANVAS_REQUEST_ERROR } from "../constants/base"

Amplify.configure(AmplifyConfig)

export interface UseAuth {
  isLoading: boolean
  setIsLoading: (isLoading: boolean) => void
  isAuthenticated: boolean
  isDummyUser: boolean
  isSignUpUserSession: boolean
  hasAccountData: boolean
  username: string
  roles: string[]
  isRoleLoaded: boolean
  subSystems: string[]
  signUp: (username: string, password: string) => Promise<CognitoResult>
  signIn: (username: string, password: string) => Promise<CognitoResult>
  sendCustomChallengeAnswer: (onetimePassword: string) => Promise<CognitoResult>
  signOut: () => Promise<CognitoResult>
  getRoles: () => Promise<CognitoResult>
  confirmSignUp: (onetimePassword: string) => Promise<CognitoResult>
  cognitoUser: CognitoUser | null
}

type CognitoError = {
  code: string
  name: string
  message: string
}

export interface CognitoResult {
  success: boolean
  message: string
  skipOTP?: boolean
}

interface ProvideAuthProps {
  children: React.ReactNode
}

const authContext = createContext({} as UseAuth)

export const ProvideAuth: React.FC<ProvideAuthProps> = ({ children }) => {
  const auth = useProvideAuth()
  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

/**
 * init global state.
 * @returns UseAuth
 */
export const useAuth = () => {
  return useContext(authContext)
}

/**
 * setup global Auth state and functions.
 * @returns UseAuth
 */
const useProvideAuth = (): UseAuth => {
  // setup global state
  const [reload, setReload] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [hasAccountData, setHasAccountData] = useState(true)
  const [username, setUsername] = useState("")
  const [isRoleLoaded, setIsRoleLoaded] = useState(false)
  const [roles, setRoles] = useState<string[]>([])
  const [subSystems, setSubSystems] = useState<string[]>([])
  const [isDummyUser, setDummyUser] = useState(false)
  const [cognitoUser, setCognitoUser] = useState<CognitoUser | null>(null)
  const [isSignUpUserSession, setSignUpUserSession] = useState(false)

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        existAccountData(user.username).then((res) => {
          setUsername(user.username)
          setHasAccountData(res as boolean)
          setIsAuthenticated(true)
          setIsLoading(false)
        }).catch(() => {
          setIsAuthenticated(false)
          setHasAccountData(true)
          setIsLoading(false)
        })
      })
      .catch(() => {
        setUsername("")
        setIsAuthenticated(false)
        setHasAccountData(true)
        setIsLoading(false)
      })
  }, [])

  /**
   * Sign up with username and password.
   *   (OTP will be sent to email and check signIn function.)
   * @param username login email address.
   * @param password login password.
   * @returns CognitoResult sign up result.
   */
  const signUp = async (username: string, password: string) => {
    setSignUpUserSession(true)
    const email = username // signUp username is email. (customed by Cognito User Pool)

    // sign out before sign up. (clear cognito token)
    try {
      await Auth.signOut()
    } catch (error) {
      console.log("error:", error)
    }

    try {
      const { user } = await Auth.signUp({
        username: email,
        password: password,
        attributes: {
          email: email,
        },
      })
      setCognitoUser(user)
      setUsername(email)
      return { success: true, message: "" }
    } catch (error) {
      const cognitoError = error as unknown as CognitoError

      if (cognitoError.code === "UsernameExistsException") {
        // TODO: このメッセージは表示しない方が良いかもしれない。
        setGlobalMessage({
          message:
            "このメールアドレスは既に登録されています。サインイン画面からサインインしてください。",
          severity: "error",
        })

        return {
          success: false,
          message: "アカウントの作成に失敗しました。(UsernameExistsException)",
        }
      }

      // default error.
      setGlobalMessage({
        message:
          "アカウントの作成に失敗しました。メールアドレス、パスワードが正しく入力されているか確認してください。",
        severity: "error",
      })

      return {
        success: false,
        message: "アカウントの作成に失敗しました。(Sign Up Error)",
      }
    }
  }

  /**
   * Sign in with username and password (and OTP).
   * @param username login email address.
   * @param password login password.
   * @returns CognitoResult sign in result.
   */
  const signIn = async (username: string, password: string) => {
    setSignUpUserSession(false)
    const email = username // signIn username is email.
    const OTP_ERROR = "UserNotConfirmedException"

    checkDummyUser(username)

    // sign out before sign in. (clear cognito token)
    try {
      await Auth.signOut()
    } catch (error) {
      console.log("error:", error)
    }

    try {
      const user = await Auth.signIn(email, password)
      if (!user.challengeName)
        return forceSignInAndRedirectToRootPage(email, password)
      setCognitoUser(user)
      setUsername(user.username)

      if (user.challengeName !== "CUSTOM_CHALLENGE") {
        throw new Error("Error: user.challengeName is not CUSTOM_CHALLENGE.")
      }

      setGlobalMessage({
        message: `メールアドレス宛に送信されたワンタイムパスワードを入力してください。
            ワンタイムパスワードの到着まで少し時間がかかることがあります。`,
        severity: "info",
      })

      return {
        success: true,
        message:
          "Redirect to check OTP page. (user.challengeName = CUSTOM_CHALLENGE)",
      }
    } catch (error) {
      const signInError = error as unknown as CognitoError
      console.log("signInError.code:", signInError.code)

      if (signInError.code === "UserNotConfirmedException") {
        setSignUpUserSession(true)
        const user = await Auth.resendSignUp(email)

        setCognitoUser(user)
        setUsername(email)

        return {
          success: true,
          message: "UserNotConfirmedException",
        }
      }

      if (signInError.code !== OTP_ERROR) return unknownSignInError()
    }

    return {
      success: true,
      message: "サインインが完了しました。 (signIn Default Message.)",
    }
  }

  /**
   * Check OTP and sign in / sign up
   * @see https://docs.amplify.aws/lib/auth/switch-auth/q/platform/js/#migrate-users-with-amazon-cognito
   * @see https://aws-amplify.github.io/amplify-js/api/classes/authclass.html#sendcustomchallengeanswer
   * @param onetimePassword one time password (OTP) for sign in.
   * @returns CognitoResult sign in result.
   */
  const sendCustomChallengeAnswer = async (onetimePassword: string) => {
    // when cognitoUser is null, redirect to sign in page. (session must be CognitoUser.)
    if (!cognitoUser) {
      console.log("Error: cognitoUser is null.")
      setGlobalMessage({
        message:
          "サインインに失敗しました。もう一度はじめからやり直してください。",
        severity: "error",
      })
      return {
        success: false,
        message: "Error: cognitoUser is null. (sendCustomChallengeAnswer)",
      }
      // TODO: redirect to sign in page.
    }

    try {
      const user = await Auth.sendCustomChallengeAnswer(
        cognitoUser,
        onetimePassword,
      )
      console.log("user:", user)

      setIsAuthenticated(true)
      getRoles()
      getSubSystems()

      setGlobalMessage({ message: "サインインしました。", severity: "success" })

      // 認証情報の再ロード
      setIsLoading(true)
      Auth.currentAuthenticatedUser()
        .then((user) => {
          existAccountData(user.username).then((res) => {
            setUsername(user.username)
            setHasAccountData(res as boolean)
            setIsAuthenticated(true)
            setIsLoading(false)
          }).catch(() => {
            setIsAuthenticated(false)
            setHasAccountData(true)
            setIsLoading(false)
          })
        })
        .catch(() => {
          setUsername("")
          setIsAuthenticated(false)
          setHasAccountData(true)
          setIsLoading(false)
        })

      return {
        success: true,
        message:
          "sendCustomChallengeAnswer and CUSTOM_CHALLENGE sign in succeed.",
      }
    } catch (error) {
      if (isICanvasError(error)) showICanvasError(error)
      else
        setGlobalMessage({
          message: `サインインに失敗しました。
          ワンタイムパスワードの再発行が必要なため、サインインページに戻って最初からやり直してください。`,
          severity: "error",
        })

      return {
        success: false,
        message:
          "SignIn Error / onetimePassword is invalid or expired. (sendCustomChallengeAnswer Error)",
      }
    }
  }

  const confirmSignUp = async (onetimePassword: string) => {
    if (!cognitoUser)
      return {
        success: false,
        message: "メールアドレスまたは認証コードが正しくありません。ログイン画面に戻って最初からやり直してください。",
      }
    try {
      const result = await Auth.confirmSignUp(username, onetimePassword)

      return {
        success: true,
        message: "メールアドレスまたは認証コードが正しくありません。ログイン画面に戻って最初からやり直してください。",
      }
    } catch (error) {
      return {
        success: false,
        message: "メールアドレスまたは認証コードが正しくありません。ログイン画面に戻って最初からやり直してください。",
      }
    }
  }

  /**
   * Sign out. (clear cognito token on ALL DEVICES and redirect to root page.)
   * @returns Promise<CognitoResult> sign out result.
   */
  const signOut = async () => {
    try {
      await Auth.signOut({ global: true })
      setUsername("")
      setIsAuthenticated(false)
      checkDummyUser("")
      return { success: true, message: "" }
    } catch (error) {
      setGlobalMessage({
        message: "ログアウトできませんでした。",
        severity: "error",
      })

      return {
        success: false,
        message: "ログアウトできませんでした。(SignOut Error)",
      }
    }
  }

  /**
   * Get cognito user roles. (cognito:groups)
   * @returns Promise<CognitoResult> get cognito roles result.
   */
  const getRoles = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser()
      // ISSUE: signIn() から直接呼ぶと、ここで roles が取れていないことがある。原因不明。
      //        とりあえず / にアクセスした時に取得するようにしている。
      const cognitoRoles =
        user.signInUserSession?.accessToken.payload["cognito:groups"]
      setRoles(cognitoRoles)
      setIsRoleLoaded(true)

      if (!roles || roles.length === 0) {
        console.log("Error: roles is blank.")
        // throw new Error("Error: roles is blank.")
      }
      console.log("roles:", roles)
      return { success: true, message: "ロールの取得を行いました。" }
    } catch (error) {
      setGlobalMessage({
        message: `アカウントにユーザー権限が設定されていません。システム管理者へお問い合わせください。`,
        severity: "error",
      })

      // ユーザー権限の紐付いていないアカウントは存在してはいけないため、強制サインアウトを行う。
      setGlobalMessage({
        message:
          "アカウントの設定状態に問題が確認されました。強制サインアウトを行いました。",
        severity: "warning",
      })
      // signOut()

      return {
        success: false,
        message: "User has no roles. (getRoles Error)",
      }
    }
  }

  /**
   * Get sub systems (iCanvas, Kailog, etc.) login status.
   * @returns Promise<CognitoResult> get sub systems login status result.
   */
  const getSubSystems = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser()
      const cognitoSubSystems =
        user.signInUserSession?.accessToken.payload["custom:subSystems"]
      setSubSystems(cognitoSubSystems)
      if (!subSystems) throw new Error("Error: subSystems is undefined.")
      return {
        success: true,
        message: "連携システムのステータス取得を行いました。",
      }
    } catch (error) {
      setGlobalMessage({
        message: "連携システムのステータス取得に失敗しました。",
        severity: "error",
      })
      return {
        success: false,
        message: "連携システムのステータス取得に失敗しました。",
      }
    }
  }

  const checkDummyUser = (email: string) => {
    DummyLoginEmails.some((domain) => email.endsWith(domain))
      ? setDummyUser(true)
      : setDummyUser(false)
  }

  /**
   * Force sign in and redirect to root page.
   *
   * When some environment, OTP is not required. (ex. staging)
   * It means system login with ONLY username and password.
   * after logged in, Auth library set login token and redirect to root page.
   *
   * @param email login email address
   * @param password login password
   * @returns Promise<CognitoResult|undefined> sign in result.
   */
  const forceSignInAndRedirectToRootPage = async (
    email: string,
    password: string,
  ) => {
    const user = await Auth.signIn(email, password)
    console.log("signIn user:", user)

    setGlobalStates(user)

    setGlobalMessage({ message: "サインインしました。", severity: "success" })
    return {
      success: true,
      message: "Sign In successfully. (OTP checked & init global state.)",
      skipOTP: true,
    }
  }

  /**
   * Sign in error.
   * @returns CognitoResult unknown error.
   */
  const unknownSignInError = () => {
    setGlobalMessage({
      message: `ERROR: サインインできませんでした。
        メンテナンス中の可能性があります。しばらく経ってから再度お試しください。`,
      severity: "error",
    })
    return {
      success: false,
      message: "サインインできませんでした。(signInError)",
    }
  }

  /**
   * Set / Reset global states.
   * @param user CognitoUser
   */
  const setGlobalStates = (user: CognitoUser) => {
    // set global state
    setIsAuthenticated(true) // set Authenticated status.
    setUsername(user.getUsername()) // set UserPool's username. = User Id (Sub)
    getRoles() // get cognito roles and set global state. It must more than one role.
    getSubSystems() // get sub systems login status and set global state.
    setCognitoUser(null) // reset cognitoUser. (for sendCustomChallengeAnswer)
  }

  return {
    isLoading,
    setIsLoading,
    isAuthenticated,
    isDummyUser,
    isSignUpUserSession,
    hasAccountData,
    roles,
    isRoleLoaded,
    subSystems,
    username,
    signUp,
    signIn,
    sendCustomChallengeAnswer,
    signOut,
    getRoles, // for debug
    confirmSignUp,
    cognitoUser,
  }
}
