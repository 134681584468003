import { Contact } from "gql/graphql"

export interface Column {
  id: keyof Contact
  label: string
  align?: "right" | "left" | "center"
  minWidth?: number
}

export const columns: Column[] = [
  {
    id: "unread_cnt",
    label: "未読メッセージ",
    align: "center",
  },
  {
    id: "contact_type",
    label: "種別",
  },
  {
    id: "target_service",
    label: "サービス",
  },
  {
    id: "contact_detail",
    label: "内容",
  },
  {
    id: "created_at",
    label: "作成日"
  },
  {
    id: "status",
    label: "ステータス",
  },
]
