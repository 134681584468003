import React from "react"
import { useNavigate } from "react-router-dom"
import { useAuth } from "hooks/useAuth"
import { Login, Logout, Settings } from "@mui/icons-material"
import ArticleIcon from "@mui/icons-material/Article"
import EmailIcon from "@mui/icons-material/Email"
import HomeIcon from "@mui/icons-material/Home"
import FilePresentIcon from "@mui/icons-material/FilePresent"
import { gql, useQuery, useMutation } from "urql"
import { InfoData, DeliveryInfoDataInput } from "gql/graphql"
import dayjs from "dayjs"
import {
  Badge,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material"
import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import ManualDialog from "./ManualDialog"

const slotProps = {
  paper: {
    elevation: 0,
    sx: {
      overflow: "visible",
      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
      mt: 1.5,
      "&:before": {
        content: '""',
        display: "block",
        position: "absolute",
        top: 0,
        right: 14,
        width: 10,
        height: 10,
        bgcolor: "background.paper",
        transform: "translateY(-50%) rotate(45deg)",
        zIndex: 0,
      },
    },
  },
}

export default function AccountMenu() {
  const auth = useAuth()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const [contactRead, setContactRead] = React.useState(true)
  const [infoRead, setInfoRead] = React.useState(true)
  const [manualOpen, setManualOpen] = React.useState(false)

  const handleAccountMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const navigate = useNavigate()
  const executeSignIn = () => {
    navigate({ pathname: "/auth/signin" })
  }

  const executeSignOut = async () => {
    const result = await auth.signOut()
    if (result.success) {
      navigate({ pathname: "/" })
    } else {
      alert(result.message)
    }
  }

  const transitionContact = () => {
    navigate({ pathname: "/contact" })
  }
  const transitionInfo = () => {
    navigate({ pathname: "/information" })
  }

  const AccountMenuItems = () => {
    if (!auth.isAuthenticated) {
      return (
        <MenuItem onClick={executeSignIn}>
          <ListItemIcon>
            <Login fontSize="small" />
          </ListItemIcon>
          サインイン
        </MenuItem>
      )
    }
    return (
      <>
        <MenuItem>
          <AccountCircleIcon sx={{ mr: 1 }} /> マイアカウント
        </MenuItem>
        <Divider />
        <MenuItem>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          設定
        </MenuItem>
        <MenuItem onClick={executeSignOut}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          サインアウト
        </MenuItem>
      </>
    )
  }

  const [infoData] = useQuery({
    query: /* GraphQL */`
      query getInfoData {
        getInfoData {
          infoDataItems {
            id
            title
            content
            openDate
            readed
            delivered
          }
        }
      }
    `
  })

  const [resultUnread] = useQuery({
    query: /* Graphql */ `
      query getUnreadSummaryHeader {
        getUnreadSummary {
          unreadInfoCnt
          uncheckedContactCnt
        }
      }`
    })

  const [saveResult , executeMutation] = useMutation( 
    gql/* GraphQL */`
      mutation saveDeliveryInfoData($Data: DeliveryInfoDataInput!) {
        saveDeliveryInfoData(data: $Data) {
          infoIds
          userId
          deliveryDate
        }
      }
    `
  )

  const saveDeliveryInfoData = async (
    data: DeliveryInfoDataInput
  ) => {
    const execResult = await executeMutation({
      Data: data
    })
    return execResult.data
  }

  React.useEffect(() => {
    if (!infoData.fetching && !infoData.error && infoData.data ) {
      const data = infoData.data.getInfoData?.infoDataItems || []
      const unreadCount = data.filter((info: { readed: boolean }) => !info.readed).length
      setInfoRead(unreadCount <= 0)

      const currentTime = new Date()
      const pastOpenDateInfoIds = data
        .filter((info: InfoData) => new Date(info.openDate ?? 0) <= currentTime && !info.delivered)
        .map((info: InfoData) => info.id)

      if (pastOpenDateInfoIds.length > 0) {
        const now = dayjs().format('YYYY-MM-DD hh:mm:ss')
        const saveData: DeliveryInfoDataInput = {
          infoIds: pastOpenDateInfoIds,
          deliveryDate: now
        }
        saveDeliveryInfoData(saveData)
      }
    }
  }, [infoData.fetching, infoData.error, infoData.data ])

  const InfoAlert = () => {
    if (resultUnread.data?.getUnreadSummary.unreadInfoCnt > 0) {
      return (
        <MenuItem onClick={transitionInfo}>
          <Badge color="error" overlap="circular" variant="dot" badgeContent={resultUnread.data?.getUnreadSummary.unreadInfoCnt}>
            <HomeIcon sx={{ color: 'white' }}/>
          </Badge>
        </MenuItem>
      )
    }
    else
    {
      return (
        <MenuItem onClick={transitionInfo}>
          <HomeIcon sx={{ color: 'white' }}/>
        </MenuItem>
      )
    }
  }

  const [contactData] = useQuery({
    query: /* GraphQL */`
      query getContacts {
        getContacts {
          contacts {
            unread_cnt
          }
        }
      }
    `,
  })

  const ContactAlert = () => {
    if (resultUnread.data?.getUnreadSummary.uncheckedContactCnt > 0) {
      return (
        <MenuItem onClick={transitionContact}>
          <Badge color="error" overlap="circular" variant="dot" badgeContent={resultUnread.data?.getUnreadSummary.uncheckedContactCnt}>
            <EmailIcon sx={{ color: 'white'}}/>
          </Badge>
        </MenuItem>
      )
    } else {
      return (
        <MenuItem onClick={transitionContact}>
          <EmailIcon sx={{ color: 'white'}}/>
        </MenuItem>
      )
    }
  }

  React.useEffect(() => {
    if (!contactData.fetching && !contactData.error && contactData.data ) {
      const data = contactData.data.getContacts?.contacts || []
      const unreadCount = data.filter((contact: { unread_cnt: number }) => {
        return contact.unread_cnt > 0
      }).length

      setContactRead(unreadCount <= 0)
    }
  })
  
  return (
    <React.Fragment>
      <MenuItem
        onClick={() => {
          setManualOpen(true)
        }}
      >
        <Tooltip title="Manual">
          <FilePresentIcon sx={{ color: 'white' }} />
        </Tooltip>
      </MenuItem>
      <InfoAlert />
      <ContactAlert />
      <MenuItem
        onClick={() => {
          window.open("/terms_of_service", "_blank")
        }}
      >
        <Tooltip title="Terms">
          <ArticleIcon sx={{ color: 'white' }} />
        </Tooltip>
      </MenuItem>
      <Tooltip title="Account settings">
        <IconButton
          onClick={handleAccountMenu}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <AccountCircleIcon sx={{ width: 32, height: 32, color: 'white' }} />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={slotProps}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <AccountMenuItems />
      </Menu>
      <ManualDialog
        open={manualOpen}
        onClose={() => {
            setManualOpen(false)
        }}
      />
    </React.Fragment>
  )
}
