import { initReactI18next } from "react-i18next"
import { defaultFormNs, defaultNs } from "constants/i18n"
import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import Backend from "i18next-http-backend"
import en from "./en"
import ja from "./ja"

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // lng: "ja",
    fallbackLng: "ja",
    resources: {
      ja: ja,
      en: en,
    },
    defaultNS: [...defaultNs, ...defaultFormNs, "zod"],
    interpolation: {
      escapeValue: false,
    },
    returnNull: false,
  })

// when language is changed, set html attribute (<html lang="{lng}">...</html>).
// because google translate uses this attribute to translate the page.
i18n.on("languageChanged", (lng: string) => {
  document.documentElement.setAttribute("lang", lng)
})

export default i18n
