import "./i18n"
import "index.css"

import { Amplify } from "aws-amplify"
import { ProvideAuth } from "hooks/useAuth"
import React from "react"
import ReactDOM from "react-dom/client"
import { RouterProvider } from "react-router-dom"
import { Provider as UrqlProvider } from "urql"

import ThemeProvider from "@mui/material/styles/ThemeProvider"

import DefaulTheme from "./config/theme"
import { AmplifyConfig } from "./constants/amplify"
import urqlClient from "./hooks/useUrqlClient"
import routes from "./routes"
import { SnackbarProvider } from "notistack"

// import { startWorker } from "mocks"

Amplify.configure(AmplifyConfig)

// MSW
// startWorker()

// tslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = ReactDOM.createRoot(document.querySelector("#root")!)
root.render(
  <React.StrictMode>
    <SnackbarProvider anchorOrigin={{ vertical: "top", horizontal: "center" }}>
      <ProvideAuth>
        <UrqlProvider value={urqlClient}>
          <ThemeProvider theme={DefaulTheme}>
            <RouterProvider router={routes} />
          </ThemeProvider>
        </UrqlProvider>
      </ProvideAuth>
    </SnackbarProvider>
  </React.StrictMode>,
)
