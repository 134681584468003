import { Box } from "@mui/material"
import { styled } from "@mui/material/styles"

const MainBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  padding: theme.spacing(4),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  background: "#ffffff",
  borderRadius: "10px",
  maxWidth: "600px",
  width: "80%",
}))

export default MainBox
