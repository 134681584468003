/**
 * @fileoverview AuthReCaptchaView is a layout for auth pages that require GoogleReCaptchaProvider (reCAPTCHA v3).
 */
import { Helmet, HelmetProvider } from "react-helmet-async"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import { ThemeProvider, useTheme } from "@mui/material/styles"
import { GOOGLE_RECAPTCHA_SITE_KEY } from "constants/index"

export default function AuthReCaptchaView({
  children,
  pageName = "Main",
}: {
  pageName?: string
  children?: React.ReactNode
}) {
  pageName = pageName || "AuthReCaptchaView"

  const theme = useTheme()

  return (
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <Helmet>
          <title>{pageName} | 買いログ</title>
        </Helmet>

        <GoogleReCaptchaProvider reCaptchaKey={GOOGLE_RECAPTCHA_SITE_KEY}>
          {children}
        </GoogleReCaptchaProvider>
      </ThemeProvider>
    </HelmetProvider>
  )
}
