/*

	ページ内共有コンテキスト

*/
import React from "react"
import Box from "@mui/material/Box"
import CircularProgress from "@mui/material/CircularProgress"
import Presenter from "./Presenter"
import { Data, ExtractionForm } from "./type"
import { ItemFilterInput } from "gql/graphql"
import { useQuery } from "urql"

export type ReviewProp = {
  accountCategoryCnt: number
  defaultValue: ExtractionForm
  panelExtractionExpanded: boolean
  selectPanelExtraction: () => void
  analysis: boolean
  analysisWithFilter: boolean
  targetItems: Data[]
  filterTermFrom: string
  filterTermTo: string
  onClickAnalysisAll: (
    filter: ItemFilterInput,
    categoryDefinition: string
  ) => void
  onClickAnalysis: (
    rows: Data[],
    filterTermFrom: string,
    filterTermTo: string,
    categoryDefinition: string
  ) => void
  fetchingReviews: boolean
  setFetchingReviews: (value: boolean) => void
  filter: ItemFilterInput
  categoryDefinition: string
}
export const ReviewContext = React.createContext({} as ReviewProp)

//------------------------------------------------------------------------------
// 共有コンテキストのプロバイダ
//------------------------------------------------------------------------------
export default function Provider() {
  const [accountCategoryCnt, setAccountCategoryCnt] = React.useState(0)
  const [result] = useQuery({
    query: /* GraphQL */ `
      query getAccountCategory(
        $categoryDefinition1: String!,
        $categoryDefinition2: String!
      ) {
        intageCategory: getCategoryOptions(categoryDefinition: $categoryDefinition1)
        jicfsCategory: getCategoryOptions(categoryDefinition: $categoryDefinition2)
      }
    `,
    variables: {
      categoryDefinition1: "INTAGE",
      categoryDefinition2: "JICFS",
    }
  })

  // 共有状態
  const [defaultValue, setDefaultValue] = React.useState({
    term_from: "",
    term_to: "",
    category: [],
    subCategory: [],
    maker: [],
    brand: [],
    barcode: [],
    selectedItems: [],
  })
  const [filter, setFilter] = React.useState<ItemFilterInput>({})
  const [targetItems, setTargetItems] = React.useState<Data[]>([])
  const [analysis, setAnalysis] = React.useState(false)
  const [analysisWithFilter, setAnalysisWithFilter] = React.useState(false)
  const [filterTermFrom, setFilterTermFrom] = React.useState("")
  const [filterTermTo, setFilterTermTo] = React.useState("")
  const [categoryDefinition, setCategoryDefition] = React.useState("")
  const [fetchingReviews, setFetchingReviews] = React.useState(false)

  // 開閉状態の取得
  const [panelExtractionExpanded, setPanelExtractionExpanded] =
    React.useState(true)
  const selectPanelExtraction = () => {
    setPanelExtractionExpanded(!panelExtractionExpanded)
  }

  const onClickAnalysisAll = (
    filter: ItemFilterInput,
    categoryDefinition: string,
  ) => {
    setFilter(filter)
    setCategoryDefition(categoryDefinition)
    setAnalysis(false)
    setAnalysisWithFilter(true)
  }

  const onClickAnalysis = (
    rows: Data[],
    filterTermFrom: string,
    filterTermTo: string,
    categoryDefinition: string,
  ) => {
    setTargetItems(rows)
    setFilterTermFrom(filterTermFrom)
    setFilterTermTo(filterTermTo)
    setCategoryDefition(categoryDefinition)
    setAnalysisWithFilter(false)
    setAnalysis(true)
  }

  const item = {
    accountCategoryCnt: accountCategoryCnt,
    defaultValue: defaultValue,
    panelExtractionExpanded: panelExtractionExpanded,
    selectPanelExtraction: selectPanelExtraction,
    analysis: analysis,
    analysisWithFilter: analysisWithFilter,
    targetItems: targetItems,
    filterTermFrom: filterTermFrom,
    filterTermTo: filterTermTo,
    onClickAnalysisAll: onClickAnalysisAll,
    onClickAnalysis: onClickAnalysis,
    fetchingReviews: fetchingReviews,
    setFetchingReviews: setFetchingReviews,
    filter: filter,
    categoryDefinition: categoryDefinition,
  }

  React.useEffect(() => {
    if (result.data) {
      const intageCategoryCnt = result.data.intageCategory.length
      const jicfsCategoryCnt = result.data.jicfsCategory.length
      setAccountCategoryCnt(intageCategoryCnt + jicfsCategoryCnt)
    }
  }, [result.data])

  return (
    <ReviewContext.Provider value={item}>
      {result.fetching ?
        (
          <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
            <CircularProgress />
          </Box>
        ) : <Presenter />
      }
    </ReviewContext.Provider>
  )
}
