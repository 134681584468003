/*

	レビュー情報行

*/

import { Review } from "@review/pages/Review/type"
import { columns } from "./column"
import TableItemBar from "@review/components/TableItemBar"
import Stack from "@mui/material/Stack"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import Typography from "@mui/material/Typography"

export default function ItemRow(props: {
  no: number
  columns: readonly any[]
  row: any
}) {
  const columns = props.columns
  const row = props.row

  return (
    <TableRow>
      {columns.map((column: any) => {
        if (column.id === "point") {
          const value = row[column.id]
          return (
            <TableCell
              sx={{ pt: 1, pb: 1 }}
              key={column.id}
              align={column.align}
              style={{ padding: "4px" }}
            >
              <Stack direction="row" alignItems="center" spacing={1}>
                <TableItemBar
                  num={value}
                  scale={10}
                  fixed={Number.isInteger(value) ? 0 : 1}
                />
              </Stack>
            </TableCell>
          )
        } else {
          const value = row[column.id]

          if (column.id === "no") {
            // インデックス
            const value = props.no
          } else if (column.id === "strlen") {
            // 口コミの文字数
            const value = row["review"].length
          }

          return (
            <TableCell
              sx={{ pt: 1, pb: 1 }}
              key={column.id}
              align={column.align}
              style={{ padding: "4px" }}
            >
              {value}
            </TableCell>
          )
        }
      })}
    </TableRow>
  )
}
