/*

	お知らせページのベース

*/
import ApplicationTemplate from "common/layouts/application/index"
import Accordion from "./components/Accordion"
import Banner from "applications/information/features/Banner"
import "./style.css"

export default function Information() {
  return (
    <ApplicationTemplate>
      <Accordion />
      <Banner />
    </ApplicationTemplate>
  )
}
