/*

	対象商品リスト

*/
import React from "react"
import { Data } from "@review/pages/Review/type"
import { ItemFilterInput, PagerInput } from "gql/graphql"
import { columns } from "@review/pages/Review/column"
import Button from "@mui/material/Button"
import Checkbox from "@mui/material/Checkbox"
import CircularProgress from "@mui/material/CircularProgress"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TablePagination from '@mui/material/TablePagination';
import Pager from "@mui/material/Paper"
import ItemRow from "./ItemRow"
import { useTableItemList } from "./hooks"
import { gql, useQuery } from "urql"

const TableItems = (props: {
  rows: Data[] | null
  changeCheck: (row: Data) => void
  isSelected: (row: Data) => boolean
}) => {
  if (!props.rows) return <></>

  return props.rows
    .map((row: Data) => {
      return (
        <ItemRow
          key={row.barcode}
          row={row}
          isSelected={props.isSelected}
          changeCheck={props.changeCheck}
        />
      )
    })
}

export default function TableItemList(props: {
  filter: ItemFilterInput
  setFetching: (fetch: boolean) => void
  isFilterValid: boolean
  categoryDefinition: string
  onChange: (rows: Data[]) => void
  onLoad: (rows: Data[]) => void
}) {
  const {
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    selectedRows,
    setSelectedRows,
    isSelected,
  } = useTableItemList(props.onChange)

  // フィルタ変更でセレクトボックス解除
  React.useEffect(() => {
    setSelectedRows([])
  }, [props.filter])

  const filter = {
    ...props.filter,
    pager: {
      item_offset: page * rowsPerPage,
      per_page: rowsPerPage,
    },
  }
  const [result] = useQuery({
    query: /* GraphQL */ `
      query getItems($ItemFilterInput: ItemFilterInput!) {
        getFilteredItems(filterInput: $ItemFilterInput) {
          filteredItems {
            identifier
            name
            maker_name_orig
            intage_generic_category_lv3name
            jicfs_lv4name
            subcategory_name
            brand_name
            subbrand_name
            score_avg
            score_cnt
            comment_cnt
          }
          cnt
        }
      }
    `,
    variables: {
      ItemFilterInput: filter
    },
    pause: !props.isFilterValid,
  })

  React.useEffect(() => {
    props.setFetching(result.fetching)
  }, [result])

  const getItems = () => {
    if (!props.isFilterValid || result.fetching || result.error) return []
    return result.data.getFilteredItems.filteredItems.map((item: any) => {
      const category = props.categoryDefinition == "INTAGE"
        ? item.intage_generic_category_lv3name
        : item.jicfs_lv4name
      return {
        barcode: item.identifier,
        title: item.name,
        maker: item.maker_name_orig,
        category: category,
        subCategory: item.subcategory_name,
        brand: item.brand_name,
        subBrand: item.subbrand_name,
        avaragePoint: item.score_avg.toFixed(2),
        count: item.score_cnt,
        reviewCount: item.comment_cnt,
      }
    })
  }

  const getCnt = () => {
    if (!props.isFilterValid || result.fetching || result.error) return 0
    return result.data.getFilteredItems.cnt
  }

  // チェックボックスクリック時の処理
  const changeCheck = (data: Data) => {
    const newSelectedRows = isSelected(data)
      ? selectedRows.filter((row) => row.barcode !== data.barcode)
      : [...selectedRows, data]
    setSelectedRows(newSelectedRows)
  }

  React.useEffect(() => {
    props.onLoad(getItems())
  })

  return (
    <Pager>
      <TableContainer sx={{ maxHeight: "270px" }}>
        <Table stickyHeader area-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, padding: "4px" }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {
            getItems().length ? (
              <TableBody>
                <TableItems
                  rows={getItems()}
                  changeCheck={changeCheck}
                  isSelected={isSelected}
                />
              </TableBody>
            )
            : (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={columns.length + 1} align="center">
                    {
                      result.fetching ? (
                        <CircularProgress size={20} />
                      ) : (
                        "対象商品がありません"
                      )
                    }
                  </TableCell>
                </TableRow>
              </TableBody>
            )
          }
        </Table>
      </TableContainer>
      {
        getItems().length ? (
          <TablePagination
            count={getCnt()}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={(e, newPage) => {
              setPage(newPage)
            }}
            onRowsPerPageChange={(e) => {
              setRowsPerPage(parseInt(e.target.value))
              setPage(0)
            }}
          />
        ) : ""
      }
    </Pager>
  )
}
