/*

	口コミパネル

*/
import React from "react"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import CircularProgress from "@mui/material/CircularProgress"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import DialogActions from "@mui/material/DialogActions"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"
import InnerPanel from "@review/features/PanelAnalysis/components/InnerPanel"
import TableReview from "@review/features/PanelAnalysis/components/TableReview"
import { ReviewContext } from "@review/pages/Review/hooks"
import { gql, useClient, useQuery } from "urql"
import { Review, ReviewFilterInput } from "gql/graphql"
import dayjs from "dayjs"

const PanelReviewStack = styled(Stack)({
  flexDirection: "column",
  alignItems: "center",
  position: "relative",
})

const DownloadButtonArea = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "290px",
  fontWeight: "bold",
  position: "absolute",
  gap: "6px",
  top: 0,
  right: 0,
})

export default function PanelReview(props: {
  categoryDefinition: string,
  filter: ReviewFilterInput,
}) {
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(25)
  const [downloading, setDownloading] = React.useState(false)
  const [errorDialogOpen, setErrorDialogOpen] = React.useState(false)

  const ReviewContextValue = React.useContext(ReviewContext)
  const filter = {
    ...props.filter,
    pager: {
      item_offset: page * rowsPerPage,
      per_page: rowsPerPage,
    }
  }

  // レビューの取得
  const [result] = useQuery({
    query: /* GraphQL */ `
      query getReviews($ReviewFilterInput: ReviewFilterInput!) {
        getReviews(filter: $ReviewFilterInput) {
            reviews {
            score
            comment
            comment_length
            review_updated_at
            identifier
            name
            intage_generic_category_lv3name
            jicfs_lv4name
            maker_name_orig
            sex
            age_group
            pref
          }
          cnt
          cnt_review
          score_avg
          dist {
            score
            cnt
          }
        }
      }
    `,
    variables: {
      ReviewFilterInput: filter,
    },
  })

  const getItems = () => {
    if (result.fetching || result.error) return []
    return result.data.getReviews.reviews.map((item: Review) => {
      const category = props.categoryDefinition == "INTAGE"
        ? item.intage_generic_category_lv3name
        : item.jicfs_lv4name
      return {
        point: item.score,
        review: item.comment ? item.comment : "",
        strlen: item.comment_length,
        updateDate: dayjs(item.review_updated_at).format("YYYY/MM/DD"),
        barcode: item.identifier,
        title: item.name,
        category: category,
        maker: item.maker_name_orig,
        gender: item.sex,
        generation: item.age_group,
        address: item.pref,
      }
    })
  }

  const query = gql(/* GraphQL */ `
    query reviewsToExcel($DataInput: ReviewFilterInput!) {
      reviewsToExcel(data: $DataInput) {
        url
      }
    }
  `)
  const graphQLClient = useClient()

  const downloadExcel = () => {
    setDownloading(true)

    // Excelのダウンロード
    graphQLClient.query(
      query, {
        DataInput: filter,
      })
      .then((data) => {
          if (!data.data) {
            setDownloading(false)
            setErrorDialogOpen(true)
            return
          }
          const url = data.data.reviewsToExcel.url
          const anchor = document.createElement("a")
          anchor.href = url
          anchor.click()
          setDownloading(false)
      })
  }

  return (
    <InnerPanel>
      <PanelReviewStack>
        { result.fetching ? (
            <CircularProgress />
          ) : (
            <React.Fragment>
              <DownloadButtonArea>
                <Button
                  variant="contained"
                  color="info"
                  onClick={downloadExcel}
                  disabled={downloading || result.data.getReviews.cnt > 40000}
                  sx={{ fontWeight: "bold" }}
                >
                  ダウンロード
                </Button>
                <Typography>
                  ※ ダウンロード上限は評価件数40,000件まで
                </Typography>
              </DownloadButtonArea>
              <Typography
                variant="h4"
                component="h4"
                sx={{ fontSize: "18px", margin: "24px 0" }}
              >
                口コミ・評価
              </Typography>
              <TableReview
                page={page}
                setPage={setPage} 
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                reviewRows={getItems()}
                cnt={result.data.getReviews.cnt}
              />
            </React.Fragment>
          )
        }
      </PanelReviewStack>
      <Dialog open={errorDialogOpen}>
        <DialogTitle>
          エラー
        </DialogTitle>
        <DialogContent>
          データ量が超過しているためダウンロードできません。
          お手数ですが口コミ件数を40,000件以内にして再度お試しください。
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setErrorDialogOpen(false)}
            color="primary"
            autoFocus
          >
            閉じる
          </Button>
        </DialogActions>
      </Dialog>
    </InnerPanel>
  )
}
