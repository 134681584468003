import { UseFormReturn } from "react-hook-form"
import LockIcon from "@mui/icons-material/Lock"
import PersonIcon from "@mui/icons-material/Person"
import {
  FormControl,
  InputAdornment,
  TextField,
  Checkbox,
  FormControlLabel,
  FormHelperText,
} from "@mui/material"
import HtmlTooltip from "../../components/HtmlTooltip"
import { LoginFormInputs } from "../hooks"
import {
  termsAcceptLabel,
  emailNoticeText,
  passwordNoticeText,
} from "./constModules"
import "../../auth.css"

export default function MainFormAttrs({
  formMethods,
}: {
  formMethods: UseFormReturn<LoginFormInputs>
}) {
  const {
    register,
    formState: { errors },
  } = formMethods
  return (
    <>
      <FormControl
        margin="none"
        variant="standard"
        required
        error={!!errors.username}
        fullWidth
      >
        <HtmlTooltip title={emailNoticeText}>
          <TextField
            required
            autoFocus
            autoComplete="username"
            margin="normal"
            label="メールアドレス"
            {...register("username")}
            type="email"
            helperText={
              errors.username?.message || "メールアドレスを入力してください。"
            }
            error={!!errors.username}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonIcon />
                </InputAdornment>
              ),
            }}
          />
        </HtmlTooltip>
      </FormControl>

      <FormControl
        margin="none"
        variant="standard"
        required
        error={!!errors.password}
        fullWidth
      >
        <HtmlTooltip title={passwordNoticeText}>
          <TextField
            required
            autoFocus
            autoComplete="password"
            margin="normal"
            label="パスワード"
            {...register("password")}
            type="password"
            helperText={
              errors.password?.message ||
              "パスワードを8文字以上で入力してください。"
            }
            error={!!errors.password}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon />
                </InputAdornment>
              ),
            }}
          />
        </HtmlTooltip>
      </FormControl>

      <FormControl
        margin="none"
        variant="standard"
        fullWidth
        required
        error={!!errors.terms_accept}
      >
        <FormControlLabel
          {...register("terms_accept")}
          control={<Checkbox defaultChecked={false} />}
          label={termsAcceptLabel}
          required
        />
        {!!errors.terms_accept && (
          <FormHelperText error={!!errors.terms_accept}>
            {errors.terms_accept?.message ||
              "利用規約及びプライバシーポリシーに同意してください。"}
          </FormHelperText>
        )}
      </FormControl>
    </>
  )
}
