/**
 * @fileoverview Amplifyの設定ファイル
 * @see https://docs.amplify.aws/lib/client-configuration/configuring-amplify-categories/q/platform/js/
 */

import sls from "../env/sls.json"
import tf from "../env/tf.json"

const AWS_REGION = "ap-northeast-1"
const AUTHENTICATION_TYPE = "AMAZON_COGNITO_USER_POOLS"


export const CognitoConfig = {
  region: AWS_REGION,
  userPoolId: tf.cxmp_cognito_user_pool_id.value,
  userPoolWebClientId: tf.cxmp_cognito_user_pool_client_id.value,
  authenticationFlowType: "CUSTOM_AUTH",
}
export const AuthConfig = CognitoConfig // alias for CognitoConfig

export const AppSyncConfig = {
  region: AWS_REGION,
  graphql_endpoint: sls.AppsyncEndpoint,
  graphql_endpoint_iam_region: AWS_REGION,
  authentication_type: AUTHENTICATION_TYPE,
}
export const APIConfig = AppSyncConfig // alias for AppSyncConfig

export const AmplifyConfig = {
  aws_project_region: AWS_REGION,
  Auth: CognitoConfig,
  aws_appsync_graphqlEndpoint: sls.AppsyncEndpoint,
  aws_appsync_authenticationType: AUTHENTICATION_TYPE,
}
