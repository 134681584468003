/*

	ページ全体のビュー

*/
import Stack from "@mui/material/Stack"
import PanelThread from "./components/PanelThread"
import PageTitle from "@contact/components/PageTitle"

export default function Presenter() {
  return (
    <Stack direction="column" sx={{ width: "100%" }} spacing={5}>
      <PageTitle title="お問い合わせスレッド一覧" />
      <PanelThread />
    </Stack>
  )
}
