import { createContext, useEffect, useState } from 'react';
import { SubmitErrorHandler, useForm, UseFormReturn } from 'react-hook-form';
import { useNavigate, useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify'
import { z } from 'zod';
import i18n from 'i18n';
import { makeZodI18nMap } from 'zod-i18n-map';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAuth } from 'hooks/useAuth';
import { setGlobalMessage } from 'hooks/useGlobalMessage';
import Presenter from './Presenter';

export const FormContext = createContext<
  UseFormReturn<passwordChangeFormInputs> | undefined
>(undefined)

// execute Zod
z.setErrorMap(makeZodI18nMap({ ns: ["baseCustomedForm", "authForm", "zod"] }))
const formNS = "authForm"

const schema = z.object({
  onetime_password:
    z.string()
    .min(6, i18n.t("text.nonempty", { ns: formNS }))
    .max(6, i18n.t("text.justify", { ns: formNS })),
  new_password: z.string()
    .min(8, i18n.t("password.nonempty", { ns: "authForm" }))
    .max(100, i18n.t("password.max", { ns: "authForm", max: "100" }))
    .regex(/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\w\s])/, i18n.t("password.strong", { ns: "authForm" })),
})
export type passwordChangeFormInputs = z.infer<typeof schema>
export type ErrorWithFormProps = SubmitErrorHandler<passwordChangeFormInputs>

// 「パスワードを変更する」ボタンの disabled を制御するための関数
export const activateSendButton = ({formMethods}: {formMethods: UseFormReturn<passwordChangeFormInputs>}) => {
  const onetimePassword = formMethods.getValues("onetime_password") || ""
  const newPassword = formMethods.getValues("new_password") || ""

  return onetimePassword.length !== 6 || newPassword.length == 0 ? true : false
}

/**
 * Provider component for PasswordChangeForm.
 * @returns React component
 */
export default function Provider() {
  const formMethods = useForm<passwordChangeFormInputs>({
    resolver: zodResolver(schema),
  })
  if (formMethods === undefined) throw new Error("formMethods is undefined.")

  const auth = useAuth() // Cognito
  const navigate = useNavigate()
  const location = useLocation()
  const [email, setEmail] = useState(location.state)

  // If already authenticated, redirect to root page.
  useEffect(() => {
    if (auth.isAuthenticated) {
      navigate("/", { replace: true })
    }
  }, [auth])

  // IF email is undefined, redirect to passwordForget page.
  if (email === undefined || email === null) {
    navigate('/auth/passwordForget')
  }

  // Amplify Auth.forgotPasswordSubmit() の実行
  const execute = async (data: passwordChangeFormInputs) => {
    try {
      await Auth.forgotPasswordSubmit(
        email,
        data.onetime_password,
        data.new_password
      )

      navigate('/auth/signIn')

      setGlobalMessage({ message: "パスワードの変更をしました。", severity: "success" })
    } catch (error: any) {
      switch (error.code) {
        case 'CodeMismatchException':
          // 無効な認証コードが入力された場合に起こる。注) email が存在しない・無効化されている場合にも起こる。
          setGlobalMessage({ message: 'CodeMismatchException : メールアドレスまたは認証コードが正しくありません。ログイン画面に戻って最初からやり直してください。', severity: "error" })
          break
        case 'LimitExceededException':
          // 認証コードを間違え続けた場合に起こる。（1時間当たり5回まで）
          setGlobalMessage({ message: 'LimitExceededException : 試行制限を超えました。しばらくしてから試してください。', severity: "error" })
          break
        case 'ExpiredCodeException':
          // 認証コードが期限切れ（1時間をオーバー）した場合に起こる。注) Auth.forgotPassword() によって認証コードがリクエストされていない場合にも起こる。
          setGlobalMessage({ message: 'ExpiredCodeException : 認証コードが有効ではありません。最初からやり直してください。', severity: "error" })
          break
        case 'InvalidPasswordException':
          // ユーザープールのポリシーで設定したパスワードの強度を満たさない場合に起こる。
          setGlobalMessage({ message: 'InvalidPasswordException : パスワードの複雑さの強度を満たされていません。', severity: "error" })
          break
        case 'InvalidParameterException':
          // password が6文字未満など Cognito 側で正しくパースできない場合（バリデーションエラー）に起こる。
          setGlobalMessage({ message: 'InvalidParameterException : パスワードの桁数が不足しているまたは半角英数字記号でない文字が含まれています。', severity: "error" })
          break
        case 'ResourceNotFoundException':
          // Cognito のユーザープールに Username/Client id ない場合に起こる。つまり、ユーザー登録されていない場合に起こる。
          setGlobalMessage({ message: 'ResourceNotFoundException : 該当ユーザーの登録はありません。', severity: "error" })
          break
        case 'UserLambdaValidationException':
          // Lambda でバリデーションエラーなど何らかなエラーが起こった場合に起こる。
          setGlobalMessage({ message: 'UserLambdaValidationException : 予期せぬエラーが発生しました。管理者へお問い合わせください。', severity: "error" })
          break
        default:
          // その他のエラー
          setGlobalMessage({ message: error.toString(), severity: "error" });
      }
    }
  }

  const error: ErrorWithFormProps = (errors, event) => {
    console.log("errors", errors)
    console.log("event", event)
    console.debug("errorWithPasswordChange")
    return
  }

  return (
    <FormContext.Provider value={formMethods}>
      <Presenter execute={execute} error={error} />
    </FormContext.Provider>
  )
}
