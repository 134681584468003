import { AppBar } from "@mui/material"
import { AppBarProps } from "@mui/material/AppBar"
import { styled } from "@mui/material/styles"
import { type Theme } from "@mui/material/styles"

// `drawer` と `open` といったカスタムプロパティを受け取るための新しいコンポーネントを作成します
interface CustomAppBarProps extends AppBarProps {
  open: boolean
  drawer: number
  theme?: Theme
}
const CustomAppBar: React.FC<CustomAppBarProps> = (props) => {
    return <AppBar {...props} />
}

const HeaderAppBar = styled(CustomAppBar)(
  ({ theme, open = false, drawer = 0 }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawer,
      width: `calc(100% - ${drawer}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }),
)
export default HeaderAppBar
