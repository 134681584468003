/*

	対象商品リスト

*/
import { Data } from "@review/pages/Review/type"
import CloseIcon from "@mui/icons-material/Close"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import ItemRow from "./ItemRow"
import { columns } from "@review/pages/Review/column"
import { useTableItemList } from "./hooks"

const TableRowRemoveAll = (props: {
  rows: Data[],
  removeAllHandle: () => void,
}) => {
  if (props.rows.length <= 0) return <></>

  // 口コミ評価件数の多い順にソート
  props.rows.sort(function (a, b) {
    if (a.reviewCount > b.reviewCount) return -1
    return 0
  })

  return (
    <TableRow>
      <TableCell sx={{ pt: 1, pb: 1 }}>
       <CloseIcon
         onClick={() => props.removeAllHandle()}
         sx={{ cursor: "pointer" }}
       />
      </TableCell>
      <TableCell colSpan={10} sx={{ p: 0 }}>
       すべて解除
      </TableCell>
    </TableRow>
  )
}

const TableItems = (props: {
  rows: Data[]
  page: number
  rowsPerPage: number
  removeHandle: (row: Data) => void
}) => {
  return props.rows
    .slice(props.page * props.rowsPerPage, (props.page + 1) * props.rowsPerPage)
    .map((row: Data) => {
      return (
        <ItemRow
          row={row}
          onClickRemove={props.removeHandle}
        />
      )
    })
}

export default function ListTargetItem(props: {
  items: Data[]
  onChange: (rows: Data[]) => void
  defaultRow?: Data[]
}) {
  if (!props.items) return <></>

  const defaultRow = props.defaultRow ? props.defaultRow : []
  const {
    page,
    rowsPerPage,
    removeHandle,
    removeAllHandle,
  } = useTableItemList(defaultRow, props.items, props.onChange)

  return (
    <TableContainer sx={{ maxHeight: 270 }}>
      <Table stickyHeader area-label="sticky table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth, padding: "4px" }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          <TableRowRemoveAll
            rows={props.items}
            removeAllHandle={removeAllHandle}
          />
          <TableItems
            page={page}
            rowsPerPage={rowsPerPage}
            rows={props.items}
            removeHandle={removeHandle}
          />
        </TableBody>
      </Table>
    </TableContainer>
  )
}
