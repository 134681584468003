/*

	お知らせ画面下部バナー パネル

*/

import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { ServiceItem } from '../type';

const PanelBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexShrink: 0,
  width: "380px",
  height: "100px",
  border: "1px solid lightgray",
  cursor: 'pointer',
})

export default function Panel(props: {
  service: ServiceItem
  setSelectedItem: (item: ServiceItem) => void
  isSelected: boolean
}) {
  const service = props.service
  return (
    <PanelBox
      sx={{
        color: props.isSelected ? 'white' : 'gray',
        backgroundColor: props.isSelected ? 'gray' : 'white',
      }}
      onClick={() => {
        props.setSelectedItem(service)
      }}
    >
      { service.name }
    </PanelBox>
  )
}
