/*

	スレッドメッセージパネル

*/
import * as React from "react"
import { useQuery } from "urql"
import Alert from "@mui/material/Alert"
import CircularProgress from "@mui/material/CircularProgress"
import Stack from "@mui/material/Stack"
import { styled } from "@mui/material/styles"
import CachedIcon from "@mui/icons-material/Cached"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
import Message from "./Message"
import MessageForm from "@contact/pages/Detail/components/MessageForm"
import { MessageFormSchema } from "@contact/pages/Detail/components/MessageForm/schema"
import { ContactComment, MutationSendContactCommentArgs, SendContactCommentMutation } from "gql/graphql"
import { useMutation, gql } from "urql"

const MessageHistory = styled(Stack)({
  height: "300px",
  overflowY: "scroll",
  border: "1px solid #ccc",
  padding: "10px",
  resize: "vertical",
})

export default function PanelThreadMessage(props: {
  contactId: number
}) {
  const [messages, setMessages] = React.useState<ContactComment[]>([])
  const [alert, setAlert] = React.useState(false)

  const [resultQuery, executeQuery] = useQuery({
    query: /* Graphql */ `
      query getContactComments($filter: GetContactCommentsInput!) {
        getContactComments(filter: $filter) {
          contact_comment_id
          contact_id
          comment
          admin_id
          created_at
          is_read
        }
      }
    `,
    variables: {
      filter: {
        contact_id: props.contactId
      },
    }
  })

  const sendContactCommentQuery = gql /* Graphql */ `
    mutation sendContactComment(
      $data: SendContactCommentInput!
    ) {
      sendContactComment(data: $data) {
        contact_comment_id
        contact_id
        comment
        admin_id
        created_at
        is_read
      }
    }
  `

  const [sending, setSending] = React.useState(false)
  const [_, sendContactComment] = useMutation<
    SendContactCommentMutation,
    MutationSendContactCommentArgs
  >(sendContactCommentQuery)

  const [result, executeMutation] = useMutation(
    gql/* GraphQL */ `
      mutation readContactComments($Data: ReadContactCommentsInput!) {
        readContactComments(data: $Data) {
          contact_id
          contact_comment_id
        }
      }
    `
  )

  const onSubmit = async (form: MessageFormSchema, contactId: number) => {
    setSending(true)
    await sendContactComment({
        data: {
          contact_id: contactId,
          comment: form.message,
        }
    })
    .then((res) => {
      setSending(false)
      if(res.error) setAlert(true)
      executeQuery({ requestPolicy: "network-only" })
    })
  }

  // メッセージの最下部にスクロール
  const HistoryRef = React.useRef<HTMLDivElement>(null)
  React.useEffect(() => {
    const element = HistoryRef.current
    if (element) {
      element.scrollTop = element.scrollHeight
    }

    const last_message = messages[messages.length - 1]
    if (last_message) {
      executeMutation({
        Data: {
          contact_comment_id: last_message?.contact_comment_id || 0,
          contact_id: last_message?.contact_id || 0,
        },
      }).then((res) => {
        if (res.error) setAlert(true)
      })
    }
  }, [messages])

  React.useEffect(() => {
    if (result.error) setAlert(true)
    if (resultQuery.error) setAlert(true)
  }, [result.error, resultQuery.error])

  React.useEffect(() => {
    if (resultQuery.data) {
      setMessages(resultQuery.data.getContactComments)
    }
  }, [resultQuery.data])

  let firstUnread  = true
  return (
    <Stack gap={1}>
      {alert && (
        <Alert severity="error">エラーが発生しました</Alert>
      )}
      <Stack direction="row" alignItems="center">
        <IconButton
          onClick={() => executeQuery({ requestPolicy: "network-only" })}
          size="small"
        >
          <CachedIcon />
        </IconButton>
        <Typography sx={{ ml: 1 }}>最新のメッセージを取得</Typography>
      </Stack>
      <MessageHistory gap={2} ref={HistoryRef}>
        {resultQuery.fetching ? (
          <Stack
            sx={{
              width: "100%",
            }}
            alignItems="center"
          >
            <CircularProgress />
          </Stack>
        ) : messages.map((message) => {
            const isUnread = message.is_read === 0 && firstUnread
            if (isUnread) firstUnread = false
            return (
              <React.Fragment>
                { isUnread ?
                  <Stack
                    style={{
                      textAlign: "center",
                      padding: "5px",
                      backgroundColor: "#ccc",
                      borderRadius: "5px",
                    }}
                  >ここから未読</Stack> : <></>
                }
                <Message key={message.contact_comment_id} message={message} />
              </React.Fragment>
            )
          })
        }
      </MessageHistory>
      <MessageForm
        onSubmit={onSubmit}
        sending={sending}
        contactId={props.contactId}
      />
    </Stack>
  )
}
