import { useAuth } from "hooks/useAuth"
import i18n from "i18n"
import { createContext, useEffect } from "react"
import { SubmitErrorHandler, useForm, UseFormReturn } from "react-hook-form"
import { useNavigate, useLocation } from "react-router-dom"
import { z } from "zod"
import { makeZodI18nMap } from "zod-i18n-map"

import { zodResolver } from "@hookform/resolvers/zod"

import Presenter from "./Presenter"
import { afterSignup, afterSignupAndRedirect } from "../signUp/hooks"
import { setGlobalMessage } from 'hooks/useGlobalMessage';

export const FormContext = createContext<
  UseFormReturn<LoginFormInputs> | undefined
>(undefined)

// execute Zod
z.setErrorMap(makeZodI18nMap({ ns: ["baseCustomedForm", "authForm", "zod"] }))
const formNS = "authForm"
const schema = z.object({
  onetime_password: z
    .string()
    .min(6, i18n.t("text.nonempty", { ns: formNS }))
    .max(6, i18n.t("text.justify", { ns: formNS })),
})
export type LoginFormInputs = z.infer<typeof schema>
export type ErrorWithFormProps = SubmitErrorHandler<LoginFormInputs>

/**
 * Provider component for LoginForm.
 * @returns React component
 */
export default function Provider() {
  const formMethods = useForm<LoginFormInputs>({
    resolver: zodResolver(schema),
  })
  if (formMethods === undefined) throw new Error("formMethods is undefined.")

  const auth = useAuth() // Cognito
  const navigate = useNavigate()
  const location = useLocation()

  // If already authenticated, redirect to root page.
  useEffect(() => {
    if (auth.isAuthenticated) {
      navigate("/", { replace: true })
    }
  }, [auth])

  // Cognito sign in or sign up.
  const execute = async (data: LoginFormInputs) => {
    const result = auth.isSignUpUserSession
      ? await auth.confirmSignUp(data.onetime_password)
      : await auth.sendCustomChallengeAnswer(data.onetime_password)
    if (result.success) {
      // If sign up, redirect to thank you page. (because of session is not set yet.)
      if (auth.isSignUpUserSession) {
        const state = location.state as { username: string }

        afterSignupAndRedirect(navigate,location.pathname, state.username)
        return
      }

      return navigate("/", { replace: true })
    } else {
      setGlobalMessage({ message: result.message, severity: "error" })
    }
  }

  const error: ErrorWithFormProps = (errors, event) => {
    console.log("errors", errors)
    console.log("event", event)
    console.debug("errorWithCheckOTP")
    return
  }

  return (
    <FormContext.Provider value={formMethods}>
      <Presenter execute={execute} error={error} />
    </FormContext.Provider>
  )
}
