import React from "react"
import { Data } from "@review/pages/Review/type"

export type TableItemListProp = {
  page: number
  setPage: (page: number) => void
  rowsPerPage: number
  setRowsPerPage: (rowsPerPage: number) => void
  selectedRows: Data[]
  setSelectedRows: (selectedRows: Data[]) => void
  isSelected: (data: Data) => boolean
  isSelectAll: boolean
  setIsSelectAll: (isSelectAll: boolean) => void
}

export function useTableItemList(
  onChange: (selectedRows: Data[]) => void,
): TableItemListProp {
  const [selectedRows, setSelectedRows] = React.useState<Data[]>([])
  const [isSelectAll, setIsSelectAll] = React.useState(false)

  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(25)

  // 選択中のデータが変更されたら親コンポーネントに通知
  React.useEffect(() => {
    onChange(selectedRows)
  }, [selectedRows])

  // 同じ行であるかチェック
  function isSameRow(a: Data, b: Data): boolean {
    if (a.barcode == b.barcode && a.maker == b.maker && a.title == b.title) {
      return true
    }
    return false
  }

  // 既に選択されているデータであるか
  function isSelected(data: Data): boolean {
    for (let i = 0; i < selectedRows.length; i++) {
      if (isSameRow(selectedRows[i], data)) {
        return true
      }
    }
    return false
  }

  return {
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    selectedRows,
    setSelectedRows,
    isSelected,
    isSelectAll,
    setIsSelectAll,
  }
}
