import React, { useState, useContext, createContext, useEffect } from "react"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import { Env, RECAPTCHA_VERIFY_ENDPOINT, SKIP_RECAPTCHA } from "constants/index"
import { setGlobalMessage } from "hooks/useGlobalMessage"

const ReCaptchaContext = createContext<string | null>(null)

/**
 * reCAPTCHA のトークンを取得する Provider
 * @param0  formName string reCAPTCHA に送るフォーム名
 * @param0  children React.ReactNode | React.ReactNode[]
 * @returns
 */
export const ReCaptchaProvider = ({
  formName,
  children,
}: {
  formName: string
  children: React.ReactNode | React.ReactNode[]
}) => {
  // executeRecaptcha は非同期で取得しているため undefined になることがある
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [recaptchaValue, setRecaptchaValue] = useState<string | null>(null)

  // executeRecaptcha がセットされたときに再度実行する
  useEffect(() => {
    const getRecaptchaToken = async () => {
      try {
        if (!executeRecaptcha) {
          console.debug("executeRecaptcha is not initialized.")
          return // do not throw error! because executeRecaptcha sometimes is undefined.
        }

        const token = await executeRecaptcha(formName)
        if (token.length === 0) {
          console.error("reCAPTCHA token is not defined.")
          return // do not throw error! because executeRecaptcha sometimes is undefined.
        }
        console.debug(`reCAPTCHA token is defined. (length = ${token.length})`)

        setRecaptchaValue(token)
      } catch (error) {
        console.error("Error fetching reCAPTCHA token:", error)
      }
    }

    if (!recaptchaValue) getRecaptchaToken()
  }, [executeRecaptcha])

  useEffect(() => {
    if (!recaptchaValue) return
    console.debug(`recaptchaValue updated! (length = ${recaptchaValue.length})`)
  }, [recaptchaValue])

  return (
    <ReCaptchaContext.Provider value={recaptchaValue}>
      {children}
    </ReCaptchaContext.Provider>
  )
}

/**
 * ReCaptchaProvider で取得した reCAPTCHA のトークンを取得する
 * @returns reCAPTCHA のトークン
 */
export const useReCaptcha = () => {
  return useContext(ReCaptchaContext)
}

export class RecaptchaError extends Error {
  constructor(message: string | undefined) {
    super(message)
    this.name = "RecaptchaError"
  }
}

// check reCAPTCHA result from Google with AWS API Gateway.
export const checkReCaptcha = async (reCaptchaValue: string | null) => {
  if (SKIP_RECAPTCHA) return true
  const reCaptchaResult = await fetch(RECAPTCHA_VERIFY_ENDPOINT, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ token: reCaptchaValue }),
  })

  if (!reCaptchaResult.ok) {
    setGlobalMessage({ message: "reCAPTCHA error", severity: "error" })
    return false
  }

  return true
}
