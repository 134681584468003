/*

	統計情報パネル

*/
import InnerPanel from "@review/features/PanelAnalysis/components/InnerPanel"
import { Review } from "@review/pages/Review/type"
import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"
import Score from "./Score"

export default function PanelStatistics(props: {
  cnt: number
  cntReview: number
  scoreAvg: number
}) {
  return (
    <InnerPanel sx={{ width: "40%", height: "320px" }}>
      <Stack direction="row" sx={{ mt: 2, mb: 2 }}>
        <Box sx={{ width: "40%" }}>
          <Score
            label="平均評価点"
            point={props.scoreAvg}
            fontSize="44px"
            fixed={2}
          />
        </Box>
        <Stack direction="column" sx={{ width: "60%" }} spacing={4}>
          <Score label="評価件数" point={props.cnt} localeString={true} />
          <Score
            label="口コミ件数(2文字以上)"
            point={props.cntReview}
            localeString={true}
          />
        </Stack>
      </Stack>
    </InnerPanel>
  )
}
