/*

	日付入力のユーティリティ

*/
import { Controller } from "react-hook-form"
import dayjs, { Dayjs } from "dayjs"
import TextField from "@mui/material/TextField"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"

type DatePickerParams = {
  label: string
  name: string
}
export default function InputDate({ label, name }: DatePickerParams) {
  const minDate = dayjs().subtract(36, 'month')

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
      <Controller
        name={name}
        render={({ field, fieldState }) => (
          <DatePicker
            {...field}
            label={label}
            inputFormat="YYYY/MM/DD"
            mask="____/__/__"
            minDate={minDate}
            maxDate={dayjs()}
            onChange={(el) => field.onChange(el?.format("YYYY-MM-DD"))}
            renderInput={(parameters) => (
              <TextField
                variant="standard"
                {...parameters}
                sx={{ minWidth: 160 }}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        )}
      />
    </LocalizationProvider>
  )
}
