import { ReCaptchaProvider } from "utils/reCaptcha"
import AuthReCaptchaView from "common/layouts/application/AuthReCaptchaView"
import Provider from "./hooks"

const formName = "signUp"
export default function SignUp() {
  return (
    <AuthReCaptchaView pageName="サインアップ">
      <ReCaptchaProvider formName={formName}>
        <Provider />
      </ReCaptchaProvider>
    </AuthReCaptchaView>
  )
}
