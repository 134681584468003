import React from "react"
import { Data } from "@review/pages/Review/type"

export type TableItemListProp = {
  page: number
  rowsPerPage: number
  removeHandle: (data: Data) => void
  removeAllHandle: () => void
}

export function useTableItemList(
  defaultRow: Data[],
  items: Data[],
  onChange: (targetRows: Data[]) => void,
): TableItemListProp {
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(30)

  // 削除時処理
  const removeHandle = (data: Data) => {
    const newRows = items.filter((row) => row.barcode !== data.barcode)
    onChange(newRows)
  }
  const removeAllHandle = () => {
    onChange([])
  }

  return {
    page,
    rowsPerPage,
    removeHandle,
    removeAllHandle,
  }
}
