import { AlertTitle, Alert, Stack } from "@mui/material"
import { emailNoticeText } from "./constModules"

const EmailNoticeStack = () => {
  return (
    <Stack sx={{ width: "100%", mt: 2 }} spacing={2}>
      <Alert variant="outlined" severity="warning">
        <AlertTitle>ご注意ください</AlertTitle>
        {emailNoticeText}
      </Alert>
    </Stack>
  )
}
export default EmailNoticeStack
