/*

	スレッド詳細テーブル

*/
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableRow from "@mui/material/TableRow"
import { Contact, ContactComment } from "gql/graphql"

function HeadCell(props: {
  label: string
}) {
  return (
    <TableCell
      component="th"
      scope="row"
      align="center"
      sx={{ fontSize: "18hx", fontWeight: "bold", width: 120 }}
    >
      { props.label }
    </TableCell>
  )
}

export default function DetailTable(props: {
  thread: Contact
}) {
  const thread = props.thread

  return (
    <TableContainer>
      <Table>
        <TableBody>
          <TableRow>
            <HeadCell label="種別" />
            <TableCell>{ thread.contact_type }</TableCell>
          </TableRow>
          <TableRow>
            <HeadCell label="サービス" />
            <TableCell>{ thread.target_service }</TableCell>
          </TableRow>
          <TableRow>
            <HeadCell label="内容" />
            <TableCell>{ thread.contact_detail }</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}
