import { authExchange } from "@urql/exchange-auth"
import { Auth } from "aws-amplify"
import { AppSyncConfig } from "constants/amplify"
import { createClient, fetchExchange, mapExchange } from "urql"
import { isICanvasError, showICanvasError } from "./useGlobalMessage"

/**
 * Cognito から AppSync GraphQL 用の JWT ログイントークンを取得する。
 */
const getAuthToken = async () => {
  try {
    const session = await Auth.currentSession()
    return session.getIdToken().getJwtToken()
  } catch (error) {
    if (isICanvasError(error)) showICanvasError(error)
    return null
  }
}

const clientAuthExchange = authExchange(async (utils) => {
  let sessionToken: string | null = null
  sessionToken = await getAuthToken()

  return {
    addAuthToOperation(operation) {
      return utils.appendHeaders(operation, {
        Authorization: `Bearer ${sessionToken}`,
      })
    },
    didAuthError(error) {
      return error.response.status === 401
    },
    async refreshAuth() {
      sessionToken = await getAuthToken()
    },
  }
})

const client = createClient({
  url: AppSyncConfig.graphql_endpoint,
  exchanges: [
    // cacheExchange,
    clientAuthExchange,
    mapExchange({
      onError(error, operation) {
        if (isICanvasError(error)) showICanvasError(error)
      },
    }),
    fetchExchange,
  ],
})

export default client
