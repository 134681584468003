interface Props {
  width?: string;
  height?: string;
  fill?: string;
}

const ReviewIcon = (
  props: Props
) => {
  const height = props.height || '800px';
  const width = props.width || '800px';
  const fill = props.fill || '#4B4B4B';

  return (
    <svg height={height} width={width} viewBox="0 0 512 512">
      <g>
        <path fill={fill} d="M96.01,396.095l25.617-57.286l-11.716-5.998c-25.192-12.899-45.85-30.44-60.068-50.656
          c-14.227-20.252-22.077-43.024-22.094-67.295c0.009-19.051,4.85-37.144,13.775-53.864c13.386-25.057,36.158-46.97,65.379-62.579
          C136.107,82.8,171.651,73.542,210.05,73.55c41.055-0.008,78.749,10.604,108.927,28.237c30.215,17.632,52.761,42.202,64.269,69.914
          l25.617-10.66c-14.235-34.207-41.252-62.986-75.885-83.22C298.31,57.589,255.874,45.81,210.05,45.801
          C152.908,45.82,100.933,64.057,62.724,94.244c-19.086,15.103-34.74,33.242-45.67,53.665C6.134,168.313-0.009,191.05,0,214.861
          c-0.018,30.378,10.008,58.93,27.152,83.264c14.797,21.029,34.868,38.986,58.578,52.996l-35.119,78.523l24.099-2.674
          c49.474-5.51,97.6-17.271,137.544-29.248l-7.968-26.574C172.166,380.784,134.671,390.161,96.01,396.095z"></path>
        <path fill={fill} d="M499.481,260.521c-12.032-22.447-31.688-40.955-55.877-53.89c-24.208-12.935-53.059-20.342-83.969-20.351
          c-41.208,0.018-78.776,13.152-106.543,35.084c-13.874,10.975-25.302,24.199-33.314,39.158
          c-8.003,14.949-12.529,31.687-12.519,49.193c-0.018,26.791,10.587,51.559,27.921,71.332c2.159,2.457,4.417,4.85,6.765,7.163
          c1.563,1.526,3.226,2.972,4.869,4.435c0.831,0.741,1.608,1.526,2.457,2.249c2.24,1.897,4.571,3.703,6.937,5.474
          c0.316,0.244,0.605,0.497,0.921,0.732c13.46,9.9,29.041,17.768,46.041,23.251c0.108,0.036,0.207,0.072,0.307,0.108
          c28.489,11.038,88.395,32.04,151.254,39.068l24.1,2.673l-26.313-58.804c0.009-0.009,0.018-0.009,0.028-0.018
          c3.288-2.05,6.413-4.245,9.493-6.513c1.112-0.812,2.222-1.634,3.306-2.474c2.529-1.978,4.986-3.993,7.344-6.106
          c1.816-1.617,3.514-3.306,5.23-4.995c1.654-1.662,3.36-3.27,4.924-5.004c3.306-3.659,6.44-7.443,9.25-11.436
          c12.537-17.776,19.926-38.786,19.908-61.134C512.009,292.209,507.484,275.471,499.481,260.521z M469.392,354.879
          c-9.602,13.666-23.666,25.644-40.937,34.478l-11.716,5.998l16.602,37.108c-12.32-2.096-24.424-4.661-36.023-7.479
          c-0.235-0.054-0.479-0.117-0.714-0.172c-2.61-0.632-5.184-1.291-7.741-1.951c-0.478-0.117-0.957-0.244-1.427-0.362
          c-2.447-0.641-4.869-1.283-7.253-1.942c-0.514-0.136-1.011-0.28-1.517-0.416c-2.394-0.66-4.778-1.328-7.109-1.996
          c-0.252-0.072-0.497-0.144-0.749-0.226c-7.344-2.123-14.318-4.272-20.857-6.395c-0.533-0.172-1.093-0.352-1.626-0.524
          c-1.59-0.514-3.107-1.029-4.634-1.535c-0.984-0.334-1.987-0.66-2.944-0.984c-1.302-0.443-2.557-0.876-3.812-1.31
          c-1.156-0.407-2.312-0.804-3.432-1.202c-1.048-0.37-2.087-0.732-3.09-1.093c-1.264-0.452-2.484-0.894-3.676-1.328
          c-0.831-0.298-1.672-0.605-2.475-0.894c-1.346-0.496-2.637-0.984-3.893-1.454c-0.586-0.217-1.201-0.443-1.761-0.659
          c-1.789-0.678-3.505-1.337-5.086-1.951l-0.424-0.163l-0.434-0.136c-23.395-7.353-43.059-20.008-56.59-35.508
          c-13.559-15.555-21.047-33.639-21.065-53.068c0.009-12.746,3.234-24.841,9.24-36.105c9.006-16.873,24.471-31.822,44.487-42.508
          c19.999-10.694,44.442-17.08,70.9-17.072c35.272-0.018,66.96,11.372,89.316,29.095c11.192,8.853,20.044,19.24,26.051,30.486
          c6.016,11.264,9.24,23.359,9.25,36.105C484.234,325.955,479.003,341.185,469.392,354.879z"></path>
        <path fill={fill} d="M311.525,300.78c-7.705,0-13.965,6.251-13.965,13.974c0,7.705,6.26,13.956,13.965,13.956
          c7.714,0,13.974-6.251,13.974-13.956C325.499,307.031,319.239,300.78,311.525,300.78z"></path>
        <path fill={fill} d="M365.388,300.78c-7.705,0-13.956,6.251-13.956,13.974c0,7.705,6.251,13.956,13.956,13.956
          c7.724,0,13.974-6.251,13.974-13.956C379.362,307.031,373.111,300.78,365.388,300.78z"></path>
        <path fill={fill} d="M419.26,300.78c-7.714,0-13.964,6.251-13.964,13.974c0,7.705,6.251,13.956,13.964,13.956
          c7.714,0,13.965-6.251,13.965-13.956C433.225,307.031,426.974,300.78,419.26,300.78z"></path>
      </g>
    </svg>
  )
};

export default ReviewIcon;
