import { createContext } from "react"
import { useNavigate,NavigateFunction } from "react-router-dom"
import { SubmitErrorHandler, useForm, UseFormReturn } from "react-hook-form"
import i18n from "i18n"
import { z } from "zod"
import { makeZodI18nMap } from "zod-i18n-map"
import { zodResolver } from "@hookform/resolvers/zod"
import { useAuth, CognitoResult } from "hooks/useAuth"
import { useReCaptcha, checkReCaptcha } from "utils/reCaptcha"
import Presenter from "./Presenter"
import { setGlobalMessage } from "hooks/useGlobalMessage"
import {
  AFTER_SIGNUP_ENDPOINT,
  API_GATEWAY_API_KEY,
  SKIP_OTP,
} from "constants/base"
import { AccountDataResult } from "../signUpAccountData/hooks"

export const afterSignup = async (
  email: string,
): Promise<AccountDataResult> => {
  try {
    const response = await fetch(AFTER_SIGNUP_ENDPOINT, {
      mode: "cors",
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        "x-api-key": API_GATEWAY_API_KEY
      },
      body: JSON.stringify({ email: email }),
    })
    const res = await response.json()
    return res as AccountDataResult
  } catch (e) {
    throw Error("afterSignup error")
  }
}

export const afterSignupAndRedirect = async(navigate:NavigateFunction,pathname:string, username: string) =>{

  const redirectToFinish = () => {
    navigate("/auth/finish", {
      state: { from: pathname },
      replace: true,
    })
  }

  const result = await afterSignup(username)
  console.log("after signup", result.type)
  redirectToFinish()
}


export const FormContext = createContext<
  UseFormReturn<LoginFormInputs> | undefined
>(undefined)

// execute Zod
z.setErrorMap(makeZodI18nMap({ ns: ["authForm", "baseCustomedForm", "zod"] }))
const schema = z.object({
  username: z.string().email(),
  password: z.string()
    .min(8, i18n.t("password.nonempty", { ns: "authForm" }))
    .max(100, i18n.t("password.max", { ns: "authForm", max: "100" }))
    .regex(/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\w\s])/, i18n.t("password.strong", { ns: "authForm" })),
  terms_accept: z.literal(true, {
    errorMap: () => ({
      message: i18n.t("terms_accept.required", { ns: "authForm" }),
    }),
  }),
})
export type LoginFormInputs = z.infer<typeof schema>
export type ErrorWithFormProps = SubmitErrorHandler<LoginFormInputs>

/**
 * Provider component for LoginForm.
 * @returns React component
 */
export default function Provider() {
  const formMethods = useForm<LoginFormInputs>({
    resolver: zodResolver(schema),
  })
  if (formMethods === undefined) throw new Error("formMethods is undefined.")

  const reCaptchaValue = useReCaptcha() // reCAPTCHA
  const auth = useAuth() // Cognito
  const navigate = useNavigate()

  if( auth.isAuthenticated){
    navigate("/", { replace: true })
  }
  // singUp
  const execute = async (data: LoginFormInputs) => {
    const isHumanAccess = await checkReCaptcha(reCaptchaValue)
    if (!isHumanAccess) {
      setGlobalMessage({
        message:
          "セキュリティ上の理由によりサインアップを完了できませんでした。\nページを表示してから時間が経っている場合、ページを再読み込みしてください。",
        severity: "error",
      })
      throw new Error("Error: isHumanAccess is false.")
    }
    console.log("check reCAPTCHA v3 success.")

    // sign up
    const result = await auth.signUp(data.username, data.password)

    const redirectToCheckOTP = () => {
      navigate("/auth/checkOTP", {
        state: { from: location.pathname, username: data.username },
      })
    }

    if (result.success) {
      if (SKIP_OTP) {
        console.log("SKIP_OTP")
        afterSignupAndRedirect(navigate,location.pathname, data.username)
        return
      }

      redirectToCheckOTP()
      return
    }
    if (result.success == false) return
    // unknown error
    setGlobalMessage({
      message:
        "不明なエラーが発生しました。\nページを再読み込みしてもう一度試してください。",
      severity: "error",
    })
    console.log("signUp error:", result.message)
    throw new Error(`SignUp Error: unknown error. ${result.message}`)
  }

  // signUp with error (form input error)
  const error: ErrorWithFormProps = (errors, event) => {
    console.debug("errorWithSignIn", "errors", errors, "event", event)
    return
  }

  return (
    <FormContext.Provider value={formMethods}>
      <Presenter execute={execute} error={error} />
    </FormContext.Provider>
  )
}
