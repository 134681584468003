import { Box, Grid, Typography } from "@mui/material"

export default function HeroArea() {
  return (
    <Box>
      <Grid container>
        <Grid
          item
          xs
          sx={{
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            sx={{
              width: 260,
            }}
            src="/kailog_cfb_black.png"
          />
        </Grid>
      </Grid>
    </Box>
  )
}
