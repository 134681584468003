import { createTheme } from "@mui/material/styles"

const theme = createTheme({
  palette: {
    primary: {
      main: "#363a47",
      light: "#919094",
      dark: "#00b38f",
    },
    secondary: {
      main: "#e5e5e5",
      light: "#f3f3f3",
      dark: "#FF7500",
    },
    background: {
      default: "#f2f2f2",
    },
  },
  typography: {
    button: {
      textTransform: "none",
    },
    fontSize: 12,
  },
  mixins: {
    toolbar: {
      minHeight: 42,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 3600, // 4,320px is max width of 8k display.
    },
  },
})
export default theme
