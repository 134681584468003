/*

	条件抽出部分のビュー

*/
import * as React from "react"
import { FormProvider } from "react-hook-form"
import { Data, ExtractionForm } from "@review/pages/Review/type"
import { ItemFilterInput } from "gql/graphql"
import Alert from "@mui/material/Alert"
import Button from "@mui/material/Button"
import Stack from "@mui/material/Stack"
import { styled } from "@mui/material/styles"
import InputBarcode from "./components/InputBarcode"
import InputDateBetween from "./components/InputDateBetween"
import ListTargetItem from "./components/ListTargetItem"
import SelectBrand from "./components/SelectBrand"
import SelectCategory from "./components/SelectCategory"
import SelectCategoryDefinition from "./components/SelectCategoryDefinition"
import SelectMaker from "./components/SelectMaker"
import SelectSubCategory from "./components/SelectSubCategory"
import TableItemList from "./components/TableItemList"
import { useExtraction } from "./hooks"
import dayjs from "dayjs"

const ButtonShowList = styled(Button)({
  width: 160,
  marginTop: "32px",
  marginBottom: "8px",
  fontWeight: "bold",
})

const ButtonAddItems = styled(Button)({
  width: 300,
  marginTop: "16px",
  marginBottom: "8px",
  fontWeight: "bold",
})

export default function PanelExtraction(props: {
  defaultValue: ExtractionForm
  onClickAnalysisAll: (
    filter: ItemFilterInput,
    categoryDefinition: string,
  ) => void,
  onClickAnalysis: (
    rows: Data[],
    filterTermFrom: string,
    filterTermTo: string,
    categoryDefinition: string,
  ) => void,
  fetchingReviews: boolean,
}) {
  const {
    categoryDefinition,
    setCategoryDefinition,
    changeCategoryDefinition,
    formContextValue,
    filter,
    setFilter,
    reset,
    selectedItems,
    targetItems,
    onChangeSelectedItem,
    onChangeTargetItem,
    onClickAddItemTargetList,
    alert,
    setAlert,
    fetchingItems,
    setFetchingItems,
  } = useExtraction(props.defaultValue)

  // カテゴリ定義によってカテゴリの値を変更
  const values = formContextValue.watch()
  const intage_generic_category_lv3name = categoryDefinition === "INTAGE"
    ? values.category
    : []
  const jicfs_lv4name = categoryDefinition === "JICFS"
    ? values.category
    : []

  // カテゴリ定義選択用
  const SelectCategoryDefinitionHandle = {
    categoryDefinition: categoryDefinition,
    changeCategoryDefinition: changeCategoryDefinition,
  }

  React.useEffect(() => {
    const value = { ...props.defaultValue }
    reset(value)
  }, [props.defaultValue])

  const {
    handleSubmit,
    formState: { errors, isValid },
  } = formContextValue

  // フィルタの有効性チェック
  const filterValidation = (filter: ItemFilterInput) => {
    const termFrom = dayjs(filter.term_from)
    const termTo = dayjs(filter.term_to)
    if (termFrom.isValid() === false) return "開始日が不正です"
    if (termTo.isValid() === false) return "開始日が不正です"
    if (termFrom.isAfter(termTo)) return "開始日が終了日よりも後になっています"

    const intage_category = filter.intage_generic_category_lv3name
    const jicfs_category = filter.jicfs_lv4name
    if (
      intage_category && intage_category.length > 0
      || jicfs_category && jicfs_category.length > 0
    ) return ""
    if (filter.subcategory_name && filter.subcategory_name.length > 0) return ""
    if (filter.maker_name_orig && filter.maker_name_orig.length > 0) return ""
    if (filter.brand_name && filter.brand_name.length > 0) return ""
    if (filter.identifier && filter.identifier.length > 0) return ""
    return "「カテゴリ」「サブカテゴリ」「メーカー」「ブランド名」「バーコード」のいずれかを入力してください"
  }

  // フォームの送信時処理
  const onSubmit = (inputValues: any) => {
    const filter = {
      term_from: inputValues.term_from,
      term_to: inputValues.term_to,
      intage_generic_category_lv3name: [],
      jicfs_lv4name: [],
      subcategory_name: inputValues.sub_category,
      maker_name_orig: inputValues.maker,
      brand_name: inputValues.brand,
      identifier: inputValues.barcode,
      categoryDefinition,
    }
    if (categoryDefinition === "INTAGE")
      filter.intage_generic_category_lv3name = inputValues.category
    else
      filter.jicfs_lv4name = inputValues.category

    setAlert(filterValidation(filter))
    setFilter(filter)
  }

  // ボタンの表示制御
  const [isDisabledButtonAnalysisAll, setIsDisabledButtonAnalysisAll] = React.useState(true)
  const isDisabledButtonAddItems = () => selectedItems.length <= 0
  const isDisabledButtonAnalysis = () => {
    return targetItems.length <= 0 || fetchingItems
  }

  const onLoadItems = (rows: Data[]) => {
    if( rows.length <= 0 || fetchingItems ) {
      setIsDisabledButtonAnalysisAll(true)
    } else {
      setIsDisabledButtonAnalysisAll(false)
    }
  }


  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormProvider {...formContextValue}>
        <Stack direction="column">
          <Stack direction="row" justifyContent="end" alignItems="center">
            <SelectCategoryDefinition handle={SelectCategoryDefinitionHandle} />
          </Stack>

          <Stack direction="column" justifyContent="start" alignItems="left">
            <InputDateBetween
              title="データ期間"
              from_label="開始日"
              from_name="term_from"
              to_label="終了日"
              to_name="term_to"
            />
            <Stack
              direction="row"
              justifyContent="start"
              alignItems="start"
              spacing={2}
              marginTop={2}
            >
              <SelectCategory
                label="カテゴリ"
                name="category"
                categoryDefinition={categoryDefinition}
              />
              <SelectSubCategory
                label="サブカテゴリ"
                name="sub_category"
                intage_generic_category_lv3name={intage_generic_category_lv3name}
                jicfs_lv4name={jicfs_lv4name}
              />
              <SelectMaker
                label="メーカー"
                name="maker"
                intage_generic_category_lv3name={intage_generic_category_lv3name}
                jicfs_lv4name={jicfs_lv4name}
                sub_category={values.sub_category}
              />
              <SelectBrand
                label="ブランド"
                name="brand"
                intage_generic_category_lv3name={intage_generic_category_lv3name}
                jicfs_lv4name={jicfs_lv4name}
                sub_category={values.sub_category}
                maker={values.maker}
              />
              <InputBarcode label="バーコード" name="barcode" />
            </Stack>
          </Stack>

          <ButtonShowList
            variant="contained"
            color="info"
            type="submit"
            disabled={fetchingItems}
          >
            対象商品リストを表示
          </ButtonShowList>

          {alert.length > 0 && (
            <Alert
              severity="warning"
              sx={{ mt: 1, mb: 2 }}
            >
              {alert}
            </Alert>
          )}

          <TableItemList
            filter={filter}
            setFetching={setFetchingItems}
            isFilterValid={filterValidation(filter).length <= 0}
            categoryDefinition={categoryDefinition}
            onChange={onChangeSelectedItem}
            onLoad={onLoadItems}
          />

          <Stack direction="row" justifyContent="center">
            <Button
              variant="contained"
              color="info"
              disabled={isDisabledButtonAnalysisAll}
              sx={{ mt: 2, fontWeight: "bold", width: 220 }}
              onClick={() => {
                props.onClickAnalysisAll(filter, categoryDefinition)
              }}
            >
              対象商品リスト全てを集計する
            </Button>
          </Stack>

          <ButtonAddItems
            variant="contained"
            color="info"
            disabled={isDisabledButtonAddItems()}
            onClick={onClickAddItemTargetList}
          >
            チェックしたバーコードを商品指定に追加
          </ButtonAddItems>

          <ListTargetItem items={targetItems} onChange={onChangeTargetItem} />

          <Stack direction="column" alignItems="center">
            <Button
              sx={{ width: 220, mt: 2, fontWeight: "bold" }}
              variant="contained"
              disabled={isDisabledButtonAnalysis()}
              color="info"
              onClick={() => {
                const termFrom = formContextValue.getValues("term_from")
                const termTo = formContextValue.getValues("term_to")
                props.onClickAnalysis(targetItems, termFrom, termTo, categoryDefinition)
              }}
            >
              チェックした商品を集計する
            </Button>
          </Stack>
        </Stack>
      </FormProvider>
    </form>
  )
}
