interface Column {
  id: string
  label: string
  minWidth?: number
  align?: "right"
  format?: (value: number) => string
}

export const columns: Column[] = [
  {
    id: "no",
    label: "",
    minWidth: 30,
  },
  {
    id: "point",
    label: "評価点",
    minWidth: 80,
  },
  {
    id: "review",
    label: "口コミ",
    minWidth: 240,
  },
  {
    id: "strlen",
    label: "文字数",
    minWidth: 80,
  },
  {
    id: "updateDate",
    label: "評価日",
    minWidth: 80,
  },
  {
    id: "barcode",
    label: "バーコード",
    minWidth: 100,
  },
  {
    id: "title",
    label: "商品名",
    minWidth: 100,
  },
  {
    id: "category",
    label: "商品カテゴリ名",
    minWidth: 100,
  },
  {
    id: "maker",
    label: "メーカー名",
    minWidth: 100,
  },
  {
    id: "gender",
    label: "性別",
    minWidth: 80,
  },
  {
    id: "generation",
    label: "年代",
    minWidth: 80,
  },
  {
    id: "address",
    label: "都道府県",
    minWidth: 80,
  },
]
