import zod from "zod-i18n-map/locales/ja/zod.json"
import authForm from "./authForm.json"
import baseCustomedForm from "./baseCustomedForm.json"

const i18nConfig = {
  translation: {
    "Welcome to React": "翻訳機能が使えるようになりました。",
  },
  authForm: authForm,
  baseCustomedForm: baseCustomedForm,
  zod: zod,
} as const
export default i18nConfig
