import React from "react"

export type PanelThreadProp = {
  openContactDialog: boolean
  setOpenContactDialog: (open: boolean) => void
}

export function usePanelThread(): PanelThreadProp {
  const [openContactDialog, setOpenContactDialog] = React.useState(false)

  return {
    openContactDialog,
    setOpenContactDialog,
  }
}
