/*

	カテゴリ定義選択

	変更時は確認ダイアログを表示し、確定したら検索条件をリセットして変更を適用

*/
import React, { ReactNode } from "react"
import FormControl from "@mui/material/FormControl"
import FormLabel from "@mui/material/FormLabel"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import Stack from "@mui/material/Stack"
import { ExtractionForm } from "@review/pages/Review/type"
import ConfirmDialog from "@review/components/ConfirmDialog"

export default function SelectCategoryDefinition(props: {
  handle: {
    categoryDefinition: string
    changeCategoryDefinition: (value: string) => void
  }
}) {
  const [value, setValue] = React.useState("")
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false)
  const handle = props.handle

  return (
    <FormControl size="small">
      <Stack direction="row" alignItems="center">
        <FormLabel sx={{ marginRight: "20px" }}>カテゴリ定義</FormLabel>
        <Select
          value={handle.categoryDefinition}
          onChange={(event: SelectChangeEvent<string>) => {
            setOpenConfirmDialog(true)
            setValue((event.target as HTMLInputElement).value)
          }}
        >
          <MenuItem value="JICFS">JICFS細分類</MenuItem>
          <MenuItem value="INTAGE">インテージ口コミ用分類</MenuItem>
        </Select>
      </Stack>
      <ConfirmDialog
        open={openConfirmDialog}
        title="確認"
        message="カテゴリ定義を変更すると、検索条件がリセットされますが、よろしいですか？"
        onClose={() => {
          setOpenConfirmDialog(false)
        }}
        onDone={() => {
          handle.changeCategoryDefinition(value)
          setOpenConfirmDialog(false)
        }}
      />
    </FormControl>
  )
}
