import React from "react"
import LanguageIcon from "@mui/icons-material/Language"
import {
  Divider,
  InputAdornment,
  Link,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from "@mui/material"

export default function HeaderNav() {
  const [lang, setLang] = React.useState("ja")
  const handleChangeLang = (event: SelectChangeEvent<string>) => {
    event.preventDefault()
    console.log("handleChangeLang", event.target.value)
    setLang(event.target.value)
  }

  return (
    <Stack
      direction="row"
      className="header-nav"
      spacing={2}
      alignItems="baseline"
      justifyContent="flex-end"
      divider={
        <Divider
          orientation="vertical"
          flexItem
          sx={{ borderColor: "rgba(0, 0, 0, 0.87)" }}
        />
      }
      sx={{ m: 2 }}
    >
      <Link href="https://r-n-i.jp/contact/" target="contact">お問い合わせ</Link>
      <Link href="/terms_of_service/" target="terms_of_service">利用規約</Link>
      <Select
        value={lang}
        onChange={handleChangeLang}
        name="lang"
        startAdornment={
          <InputAdornment position="start">
            <LanguageIcon />
          </InputAdornment>
        }
        variant="standard"
      >
        <MenuItem value="ja">日本語</MenuItem>
      </Select>
    </Stack>
  )
}
