import * as React from "react"
import { Helmet, HelmetProvider } from "react-helmet-async"
import Box from "@mui/material/Box"
import CssBaseline from "@mui/material/CssBaseline"
import { ThemeProvider, useTheme } from "@mui/material/styles"
import Header from "./components/header/Header"
import LeftMenu from "./components/leftMenu/LeftMenu"
import MainArea from "./components/mainArea/MainArea"

export default function ApplicationTemplateView({
  pageName,
  drawer,
  children,
}: {
  pageName?: string
  drawer?: number
  children?: React.ReactNode
}) {
  drawer = drawer || 240
  pageName = pageName || "ApplicationTemplateView"

  const [open, setOpen] = React.useState(false)
  const toggleDrawer = () => setOpen(!open)

  const theme = useTheme()

  return (
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <Helmet>
          <title>{pageName} | 買いログ</title>
          <link rel="icon" href="/favicon.ico" />
          <link rel="icon" type="image/png" href="/favicon_64.png" sizes="64x64" />
          <link rel="icon" type="image/png" href="/favicon_512.png" sizes="512x512" />
        </Helmet>
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          {/* header */}
          <Header open={open} toggleDrawer={toggleDrawer} />

          {/* left menu */}
          <LeftMenu open={open} toggleDrawer={toggleDrawer} drawer={drawer} />

          {/* main */}
          <MainArea>{children}</MainArea>
        </Box>
      </ThemeProvider>
    </HelmetProvider>
  )
}
