import { ReCaptchaProvider } from "utils/reCaptcha"
import AuthReCaptchaView from "common/layouts/application/AuthReCaptchaView"
import Provider from "./hooks"

const formName = "signUp"
export default function SignUpAccountData() {
  return (
    <>
      <Provider />
    </>
  )
}
