import {
  Button,
  Card,
  CardActions,
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  Typography,
} from "@mui/material"
import WarningAmberIcon from "@mui/icons-material/WarningAmber"
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"
import ContactSupportIcon from "@mui/icons-material/ContactSupport"
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew"
import styled from "@emotion/styled"

const CustomedListItemIcon = styled(ListItemIcon)(() => ({
  minWidth: "3em",
}))

const BackgroundDesign = styled(Typography)(() => ({
  position: "absolute",
  right: "-30px",
  bottom: "-70px",
  color: "#bbb",
  fontWeight: "bold",
  fontSize: "18rem",
  opacity: 0.3,
  m: 0,
  p: 0,
  lineHeight: 1,
  letterSpacing: "-0.6rem",
}))

const CustomedListItem = styled(ListItem)(() => ({
  fontSize: "1.2em",
}))

type Params = {
  message?: string
  code?: number
}

type CodeErrorType = {
  [key: number]: string
}

const codeErrors: CodeErrorType = {
  400: "Bad Request",
  401: "Unauthorized",
  402: "Payment Required",
  403: "Forbidden",
  404: "Not Found",
  500: "Internal Server Error",
  503: "Service Unavailable",
  504: "Gateway Timeout",
}

const humanizedCodeErrors: CodeErrorType = {
  400: "不正なリクエストです。",
  401: "ログインしていません。",
  402: "この機能をご利用いただくには別途システム利用契約が必要です。",
  403: "現在の契約ではご利用いただけない機能です。",
  404: "ページが存在しません。入力されたURLをご確認ください。",
  500: "サーバーエラーが発生しました。条件を変えて再度お試しください。",
  503: "一時的にサーバーに問題が発生しているか、定期メンテナンス中（毎日2:00-5:00）です。",
  504: "アクセスが集中しているため、タイムアウトしました。しばらく時間をおいてから再度お試しください。",
}

export default function PermissionErrorBase({
  message,
  code,
}: Params): React.ReactElement {
  code = code || 403
  message = message || humanizedCodeErrors[code]
  console.log("test")
  return (
    <Card sx={{ position: "relative", minHeight: "40rem" }}>
      <BackgroundDesign>{codeErrors[code]}</BackgroundDesign>
      <CardContent sx={{ p: 2 }}>
        <Typography variant="h3" sx={{ m: 2, mb: 4 }}>
          {message}
          <Typography variant="inherit">({code})</Typography>
        </Typography>
        <List>
          <CustomedListItem>
            <CustomedListItemIcon>
              <WarningAmberIcon />
            </CustomedListItemIcon>
            {message}
          </CustomedListItem>
          <CustomedListItem>
            <CustomedListItemIcon>
              <WarningAmberIcon />
            </CustomedListItemIcon>
            新規にアカウントを発行した場合、各機能が利用可能になるまで数日かかる場合があります。
          </CustomedListItem>
          <CustomedListItem>
            <CustomedListItemIcon>
              <ErrorOutlineIcon />
            </CustomedListItemIcon>
            現在利用できない機能にご興味がある場合や、これまで使えていた機能が使えなくなった場合など、利用時にお困りの際には、お手数ですが本システムの契約をさせていただいている会社へお問い合わせください。
          </CustomedListItem>
        </List>
      </CardContent>
      <CardActions sx={{ justifyContent: "space-between", p: 4 }}>
        <Button size="large" sx={{ fontWeight: "bold" }} variant="outlined">
          <ArrowBackIosNewIcon />
          元のページに戻る
        </Button>
        <Button size="large" sx={{ fontWeight: "bold" }} variant="outlined">
          <ContactSupportIcon />
          新機能について問い合わせる
        </Button>
      </CardActions>
    </Card>
  )
}
