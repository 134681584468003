import { ReCaptchaProvider } from "utils/reCaptcha"
import AuthReCaptchaView from "common/layouts/application/AuthReCaptchaView"
import Provider from "./hooks"

const formName = "passwordForget"
export default function passwordForget() {
  return (
    <AuthReCaptchaView pageName="パスワード忘れ">
      <ReCaptchaProvider formName={formName}>
        <Provider />
      </ReCaptchaProvider>
    </AuthReCaptchaView>
  )
}
