import { UseFormReturn } from "react-hook-form"
import { Button } from "@mui/material"
import { ErrorWithFormProps, PasswordForgotFormInputs } from "../hooks"

const ButtonsAndLinks = ({
  formMethods,
  execute,
  error,
  disabledButtonFlag,
}: {
  formMethods: UseFormReturn<PasswordForgotFormInputs>
  execute: (data: PasswordForgotFormInputs) => Promise<void>
  error: ErrorWithFormProps
  disabledButtonFlag: boolean
}) => {

  return (
    <>
      <Button
        type="submit"
        onClick={formMethods.handleSubmit(execute, error)}
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        disabled={disabledButtonFlag}
      >
        パスワードを変更する
      </Button>
      <Button href="/auth/signIn" fullWidth variant="text" sx={{ mb: 4 }}>
        ログイン画面に戻る
      </Button>
    </>
  )
}
export default ButtonsAndLinks
