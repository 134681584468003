/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core"
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never }
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never
    }

/* tslint:disable */
/* eslint-disable */
// !!! DO NOT EDIT !!! Generated by graphql-codegen.

/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
}

export enum AccountAuthValueType {
  Domain = "DOMAIN",
  Email = "EMAIL",
}

export type Attribute = {
  __typename?: "Attribute"
  classCodes: Array<AttributeClassCode>
  code: Scalars["String"]["output"]
  indexKind: Scalars["String"]["output"]
  kind: Scalars["String"]["output"]
  name: Scalars["String"]["output"]
  numOfCtgs: Scalars["Int"]["output"]
}

export type AttributeCategory = {
  __typename?: "AttributeCategory"
  attrCode: Scalars["String"]["output"]
  code: Scalars["String"]["output"]
  name: Scalars["String"]["output"]
}

export type AttributeCategoryInput = {
  attrCode: Scalars["String"]["input"]
  code: Scalars["String"]["input"]
  name: Scalars["String"]["input"]
}

export enum AttributeClassCode {
  Area = "AREA",
  Brand = "BRAND",
  Channel = "CHANNEL",
  Genderage = "GENDERAGE",
  Houseworker = "HOUSEWORKER",
  Maker = "MAKER",
  Otherbd = "OTHERBD",
  Otherdt = "OTHERDT",
  Sku = "SKU",
  Subctg = "SUBCTG",
  User = "USER",
}

export type AttributeInput = {
  code: Scalars["String"]["input"]
  indexKind: Scalars["String"]["input"]
  kind: Scalars["String"]["input"]
  name: Scalars["String"]["input"]
}

export type Base = {
  __typename?: "Base"
  code: Scalars["String"]["output"]
  name: Scalars["String"]["output"]
}

export type Base64Excel = {
  __typename?: "Base64Excel"
  base64: Scalars["String"]["output"]
}

export type BaseInput = {
  code: Scalars["String"]["input"]
  name: Scalars["String"]["input"]
}

export type BrandFilterInput = {
  intage_generic_category_lv3name?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >
  jicfs_lv4name?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>
  maker_name_orig?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>
  subcategory_name?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>
}

export type Category = {
  __typename?: "Category"
  bases: Array<Base>
  code: Scalars["String"]["output"]
  index: Index
  name: Scalars["String"]["output"]
}

export type CategoryInput = {
  code: Scalars["String"]["input"]
  indexCode: Scalars["String"]["input"]
  name: Scalars["String"]["input"]
}

export type Chart = {
  __typename?: "Chart"
  data: ChartData
  error: Scalars["String"]["output"]
  fetching: Scalars["Boolean"]["output"]
  tranId: Scalars["String"]["output"]
  type: ChartType
}

export type ChartData = {
  __typename?: "ChartData"
  coordinates: Array<Coordinate>
  demographName?: Maybe<Scalars["String"]["output"]>
  excel: Array<ExcelData>
  prevYearComp?: Maybe<PrevYearComp>
  unit: Scalars["String"]["output"]
  xAxis: Array<Scalars["String"]["output"]>
}

export type ChartDataInput = {
  coordinates: Array<CoordinateInput>
  demographName?: InputMaybe<Scalars["String"]["input"]>
  excel: Array<ExcelDataInput>
  prevYearComp?: InputMaybe<PrevYearCompInput>
  unit: Scalars["String"]["input"]
  xAxis: Array<Scalars["String"]["input"]>
}

export type ChartInput = {
  data: ChartDataInput
  error: Scalars["String"]["input"]
  fetching: Scalars["Boolean"]["input"]
  tranId: Scalars["String"]["input"]
  type: ChartType
}

export enum ChartType {
  BrandScaleTrend = "BRAND_SCALE_TREND",
  BrandShare = "BRAND_SHARE",
  CategoryScaleTrend = "CATEGORY_SCALE_TREND",
  CompositionRatio = "COMPOSITION_RATIO",
  RepeatRatio = "REPEAT_RATIO",
  SegmentedTrialRepeat = "SEGMENTED_TRIAL_REPEAT",
  ShareCross = "SHARE_CROSS",
  TrialRatio = "TRIAL_RATIO",
  UserBasedCompositionRatio = "USER_BASED_COMPOSITION_RATIO",
}

export type Charts = {
  __typename?: "Charts"
  categoryScaleTrendChart?: Maybe<Array<Maybe<Coordinate>>>
}

export type Company = {
  __typename?: "Company"
  code: Scalars["String"]["output"]
  name: Scalars["String"]["output"]
}

export type Contact = {
  __typename?: "Contact"
  contact_detail?: Maybe<Scalars["String"]["output"]>
  contact_id: Scalars["Int"]["output"]
  contact_type?: Maybe<Scalars["String"]["output"]>
  created_at?: Maybe<Scalars["String"]["output"]>
  last_comment_date?: Maybe<Scalars["String"]["output"]>
  status?: Maybe<ContactStatus>
  target_service?: Maybe<Scalars["String"]["output"]>
  unread_cnt?: Maybe<Scalars["Int"]["output"]>
}

export type ContactComment = {
  __typename?: "ContactComment"
  admin_id: Scalars["Int"]["output"]
  comment: Scalars["String"]["output"]
  contact_comment_id: Scalars["Int"]["output"]
  contact_id: Scalars["Int"]["output"]
  created_at?: Maybe<Scalars["String"]["output"]>
  is_read?: Maybe<Scalars["Int"]["output"]>
}

export enum ContactStatus {
  Done = "DONE",
  New = "NEW",
  Received = "RECEIVED",
}

export type ContactType = {
  __typename?: "ContactType"
  contact_type: Scalars["String"]["output"]
  contact_type_id: Scalars["ID"]["output"]
}

export type Coordinate = {
  __typename?: "Coordinate"
  name: Scalars["String"]["output"]
  values: Array<Maybe<Scalars["Float"]["output"]>>
}

export type CoordinateInput = {
  name: Scalars["String"]["input"]
  values: Array<InputMaybe<Scalars["Float"]["input"]>>
}

export type DeleteSearchConditionInput = {
  SK: Scalars["String"]["input"]
  addAutoSelectTopItems: Scalars["Boolean"]["input"]
  base: BaseInput
  category: CategoryInput
  createdAt: Scalars["Float"]["input"]
  demographAttr: AttributeInput
  filterItemAttrCategories: Array<AttributeCategoryInput>
  filterItemAttrs: Array<AttributeInput>
  filterRouteAttrCategories: Array<AttributeCategoryInput>
  filterRouteAttrs: Array<AttributeInput>
  filterUserAttrCategories: Array<AttributeCategoryInput>
  filterUserAttrs: Array<AttributeInput>
  mainAttr: AttributeInput
  mainAttrCategory: AttributeCategoryInput
  period: PeriodInput
  subAttrCategories: Array<AttributeCategoryInput>
}

export type DeliveryInfoDataInput = {
  deliveryDate: Scalars["String"]["input"]
  infoIds: Array<InputMaybe<Scalars["String"]["input"]>>
}

export type DeliveryInfoDataOutput = {
  __typename?: "DeliveryInfoDataOutput"
  deliveryDate: Scalars["String"]["output"]
  infoIds: Array<Maybe<Scalars["String"]["output"]>>
  userId: Scalars["String"]["output"]
}

export type Dist = {
  __typename?: "Dist"
  cnt?: Maybe<Scalars["Int"]["output"]>
  score?: Maybe<Scalars["Float"]["output"]>
}

export type ExcelData = {
  __typename?: "ExcelData"
  range: Scalars["String"]["output"]
  value: Array<Array<Maybe<Scalars["String"]["output"]>>>
}

export type ExcelDataInput = {
  range: Scalars["String"]["input"]
  value: Array<Array<InputMaybe<Scalars["String"]["input"]>>>
}

export type FilteredItem = {
  __typename?: "FilteredItem"
  brand_name?: Maybe<Scalars["String"]["output"]>
  comment_cnt?: Maybe<Scalars["Int"]["output"]>
  identifier: Scalars["ID"]["output"]
  intage_generic_category_lv3name?: Maybe<Scalars["String"]["output"]>
  jicfs_lv4name?: Maybe<Scalars["String"]["output"]>
  maker_name_orig?: Maybe<Scalars["String"]["output"]>
  name: Scalars["String"]["output"]
  score_avg?: Maybe<Scalars["Float"]["output"]>
  score_cnt?: Maybe<Scalars["Int"]["output"]>
  subbrand_name?: Maybe<Scalars["String"]["output"]>
  subcategory_name?: Maybe<Scalars["String"]["output"]>
}

export type GetContactCommentsInput = {
  contact_id: Scalars["Int"]["input"]
}

export type GetContactsInput = {
  pager?: InputMaybe<PagerInput>
}

export type GetContactsOutput = {
  __typename?: "GetContactsOutput"
  contacts: Array<Contact>
  totalCount?: Maybe<Scalars["Int"]["output"]>
}

export type GetUnreadSummaryOutput = {
  __typename?: "GetUnreadSummaryOutput"
  uncheckedContactCnt: Scalars["Int"]["output"]
  unreadInfoCnt: Scalars["Int"]["output"]
}

export type Index = {
  __typename?: "Index"
  attributes?: Maybe<Array<Attribute>>
  indexCode: Scalars["String"]["output"]
  indexData?: Maybe<Array<IndexData>>
}

export type IndexSearchablePeriod = {
  __typename?: "IndexSearchablePeriod"
  begin: Scalars["String"]["output"]
  end: Scalars["String"]["output"]
}

export type InfoData = {
  __typename?: "InfoData"
  content?: Maybe<Scalars["String"]["output"]>
  delivered?: Maybe<Scalars["Boolean"]["output"]>
  id: Scalars["ID"]["output"]
  openDate?: Maybe<Scalars["String"]["output"]>
  readed?: Maybe<Scalars["Boolean"]["output"]>
  title?: Maybe<Scalars["String"]["output"]>
}

export type InfoDataInput = {
  pager?: InputMaybe<PagerInput>
}

export type InfoDataOutput = {
  __typename?: "InfoDataOutput"
  infoDataItems?: Maybe<Array<Maybe<InfoData>>>
  totalCount?: Maybe<Scalars["Int"]["output"]>
  unreadCnt?: Maybe<Scalars["Int"]["output"]>
}

export type ItemFilterInput = {
  brand_name?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>
  categoryDefinition?: InputMaybe<Scalars["String"]["input"]>
  identifier?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>
  intage_generic_category_lv3name?: InputMaybe<
    Array<Scalars["String"]["input"]>
  >
  jicfs_lv4name?: InputMaybe<Array<Scalars["String"]["input"]>>
  maker_name_orig?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>
  pager?: InputMaybe<PagerInput>
  subcategory_name?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>
  term_from?: InputMaybe<Scalars["String"]["input"]>
  term_to?: InputMaybe<Scalars["String"]["input"]>
}

export type ItemFilterOutput = {
  __typename?: "ItemFilterOutput"
  cnt?: Maybe<Scalars["Int"]["output"]>
  filteredItems?: Maybe<Array<Maybe<FilteredItem>>>
  pager?: Maybe<Pager>
}

export type LoginDummyUserResult = {
  __typename?: "LoginDummyUserResult"
  message?: Maybe<Scalars["String"]["output"]>
  success: Scalars["Boolean"]["output"]
}

export type MakerFilterInput = {
  intage_generic_category_lv3name?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >
  jicfs_lv4name?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>
  subcategory_name?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>
}

export type Mutation = {
  __typename?: "Mutation"
  deleteSearchCondition: Array<SearchCondition>
  forwardChart?: Maybe<Chart>
  loginDummyUser?: Maybe<LoginDummyUserResult>
  readContactComments: ReadContactCommentsOutput
  saveAccountData: SaveAccountDataOutput
  saveDeliveryInfoData?: Maybe<DeliveryInfoDataOutput>
  saveReadedInfoData?: Maybe<ReadedInfoDataOutput>
  saveSearchCondition: SearchCondition
  sendContact?: Maybe<Contact>
  sendContactComment?: Maybe<ContactComment>
}

export type MutationDeleteSearchConditionArgs = {
  searchConditions: Array<DeleteSearchConditionInput>
}

export type MutationForwardChartArgs = {
  chart: ChartInput
}

export type MutationLoginDummyUserArgs = {
  companyCode: Scalars["String"]["input"]
}

export type MutationReadContactCommentsArgs = {
  data: ReadContactCommentsInput
}

export type MutationSaveAccountDataArgs = {
  accountData: SaveAccountDataInput
}

export type MutationSaveDeliveryInfoDataArgs = {
  data: DeliveryInfoDataInput
}

export type MutationSaveReadedInfoDataArgs = {
  data: ReadedInfoDataInput
}

export type MutationSaveSearchConditionArgs = {
  searchCondition: SearchConditionInput
}

export type MutationSendContactArgs = {
  data: SendContactInput
}

export type MutationSendContactCommentArgs = {
  data: SendContactCommentInput
}

export type Pager = {
  __typename?: "Pager"
  item_offset?: Maybe<Scalars["Int"]["output"]>
  per_page?: Maybe<Scalars["Int"]["output"]>
}

export type PagerInput = {
  item_offset?: InputMaybe<Scalars["Int"]["input"]>
  per_page?: InputMaybe<Scalars["Int"]["input"]>
}

export type Period = {
  __typename?: "Period"
  begin: Scalars["String"]["output"]
  end: Scalars["String"]["output"]
  type: Scalars["String"]["output"]
}

export type PeriodInput = {
  begin: Scalars["String"]["input"]
  end: Scalars["String"]["input"]
  type: Scalars["String"]["input"]
}

export type PrevYearComp = {
  __typename?: "PrevYearComp"
  name: Scalars["String"]["output"]
  value: Scalars["Float"]["output"]
}

export type PrevYearCompInput = {
  name: Scalars["String"]["input"]
  value: Scalars["Float"]["input"]
}

export type Query = {
  __typename?: "Query"
  attributeCategories?: Maybe<Array<AttributeCategory>>
  categories: Array<Maybe<Category>>
  charts: Charts
  companies?: Maybe<Array<Maybe<Company>>>
  getBrandOptions?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>
  getCategoryOptions?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>
  getContactComments: Array<Maybe<ContactComment>>
  getContactDetail: Contact
  getContactTypes: Array<ContactType>
  getContacts: GetContactsOutput
  getFilteredItems?: Maybe<ItemFilterOutput>
  getInfoData?: Maybe<InfoDataOutput>
  getMakerOptions?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>
  getReviews?: Maybe<ReviewFilterOutput>
  getSubcategoryOptions?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>
  getTargetServices: Array<TargetService>
  getUnreadSummary?: Maybe<GetUnreadSummaryOutput>
  reviewsToExcel?: Maybe<SignedUrl>
  searchConditions: Array<SearchCondition>
  selfInterviewSeamlessLogin?: Maybe<Scalars["String"]["output"]>
  toExcel: Base64Excel
}

export type QueryAttributeCategoriesArgs = {
  attributes: Array<AttributeInput>
  category: CategoryInput
  filter: Scalars["String"]["input"]
}

export type QueryChartsArgs = {
  searchCondition: SearchConditionInput
  tranId: Scalars["String"]["input"]
}

export type QueryGetBrandOptionsArgs = {
  filter?: InputMaybe<BrandFilterInput>
}

export type QueryGetCategoryOptionsArgs = {
  categoryDefinition: Scalars["String"]["input"]
}

export type QueryGetContactCommentsArgs = {
  filter: GetContactCommentsInput
}

export type QueryGetContactDetailArgs = {
  contactId: Scalars["Int"]["input"]
}

export type QueryGetContactsArgs = {
  filter?: InputMaybe<GetContactsInput>
}

export type QueryGetFilteredItemsArgs = {
  filterInput: ItemFilterInput
}

export type QueryGetInfoDataArgs = {
  filter?: InputMaybe<InfoDataInput>
}

export type QueryGetMakerOptionsArgs = {
  filter?: InputMaybe<MakerFilterInput>
}

export type QueryGetReviewsArgs = {
  filter: ReviewFilterInput
}

export type QueryGetSubcategoryOptionsArgs = {
  filter?: InputMaybe<SubcategoryFilterInput>
}

export type QueryReviewsToExcelArgs = {
  data: ReviewFilterInput
}

export type QueryToExcelArgs = {
  charts: Array<ChartInput>
}

export type ReadContactCommentsInput = {
  contact_comment_id: Scalars["Int"]["input"]
  contact_id: Scalars["Int"]["input"]
}

export type ReadContactCommentsOutput = {
  __typename?: "ReadContactCommentsOutput"
  contact_comment_id: Scalars["Int"]["output"]
  contact_id: Scalars["Int"]["output"]
}

export type ReadedInfoDataInput = {
  infoId: Scalars["String"]["input"]
  readDate: Scalars["String"]["input"]
}

export type ReadedInfoDataOutput = {
  __typename?: "ReadedInfoDataOutput"
  id: Scalars["ID"]["output"]
  infoId: Scalars["String"]["output"]
  readDate: Scalars["String"]["output"]
  userId: Scalars["String"]["output"]
}

export type Review = {
  __typename?: "Review"
  age_group?: Maybe<Scalars["String"]["output"]>
  comment?: Maybe<Scalars["String"]["output"]>
  comment_length?: Maybe<Scalars["Int"]["output"]>
  identifier: Scalars["ID"]["output"]
  intage_generic_category_lv3name?: Maybe<Scalars["String"]["output"]>
  jicfs_lv4name?: Maybe<Scalars["String"]["output"]>
  maker_name_orig?: Maybe<Scalars["String"]["output"]>
  name?: Maybe<Scalars["String"]["output"]>
  pref?: Maybe<Scalars["String"]["output"]>
  review_updated_at?: Maybe<Scalars["String"]["output"]>
  score?: Maybe<Scalars["Float"]["output"]>
  sex?: Maybe<Scalars["String"]["output"]>
}

export type ReviewFilterInput = {
  brand_name?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>
  categoryDefinition?: InputMaybe<Scalars["String"]["input"]>
  identifier?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>
  intage_generic_category_lv3name?: InputMaybe<
    Array<Scalars["String"]["input"]>
  >
  jicfs_lv4name?: InputMaybe<Array<Scalars["String"]["input"]>>
  maker_name_orig?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>
  pager?: InputMaybe<PagerInput>
  subcategory_name?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>
  term_from?: InputMaybe<Scalars["String"]["input"]>
  term_to?: InputMaybe<Scalars["String"]["input"]>
}

export type ReviewFilterOutput = {
  __typename?: "ReviewFilterOutput"
  cnt?: Maybe<Scalars["Int"]["output"]>
  cnt_review?: Maybe<Scalars["Int"]["output"]>
  dist?: Maybe<Array<Maybe<Dist>>>
  pager?: Maybe<Pager>
  reviews?: Maybe<Array<Maybe<Review>>>
  score_avg?: Maybe<Scalars["Float"]["output"]>
}

export type ScAttribute = {
  __typename?: "SCAttribute"
  code: Scalars["String"]["output"]
  indexKind: Scalars["String"]["output"]
  kind: Scalars["String"]["output"]
  name: Scalars["String"]["output"]
}

export type ScCategory = {
  __typename?: "SCCategory"
  code: Scalars["String"]["output"]
  indexCode: Scalars["String"]["output"]
  name: Scalars["String"]["output"]
}

export type SaveAccountDataInput = {
  accountDataSession: Scalars["String"]["input"]
  companyName: Scalars["String"]["input"]
  departmentName: Scalars["String"]["input"]
  divisionName: Scalars["String"]["input"]
  userName: Scalars["String"]["input"]
}

export type SaveAccountDataOutput = {
  __typename?: "SaveAccountDataOutput"
  result: Scalars["Boolean"]["output"]
}

export type SearchCondition = {
  __typename?: "SearchCondition"
  SK: Scalars["String"]["output"]
  addAutoSelectTopItems: Scalars["Boolean"]["output"]
  base: Base
  category: ScCategory
  createdAt: Scalars["Float"]["output"]
  demographAttr: ScAttribute
  filterItemAttrCategories: Array<AttributeCategory>
  filterItemAttrs: Array<ScAttribute>
  filterRouteAttrCategories: Array<AttributeCategory>
  filterRouteAttrs: Array<ScAttribute>
  filterUserAttrCategories: Array<AttributeCategory>
  filterUserAttrs: Array<ScAttribute>
  mainAttr: ScAttribute
  mainAttrCategory: AttributeCategory
  period: Period
  subAttrCategories: Array<AttributeCategory>
}

export type SearchConditionInput = {
  addAutoSelectTopItems: Scalars["Boolean"]["input"]
  base: BaseInput
  category: CategoryInput
  demographAttr: AttributeInput
  filterItemAttrCategories: Array<AttributeCategoryInput>
  filterItemAttrs: Array<AttributeInput>
  filterRouteAttrCategories: Array<AttributeCategoryInput>
  filterRouteAttrs: Array<AttributeInput>
  filterUserAttrCategories: Array<AttributeCategoryInput>
  filterUserAttrs: Array<AttributeInput>
  mainAttr: AttributeInput
  mainAttrCategory: AttributeCategoryInput
  period: PeriodInput
  subAttrCategories: Array<AttributeCategoryInput>
}

export type SendContactCommentInput = {
  comment: Scalars["String"]["input"]
  contact_id: Scalars["Int"]["input"]
}

export type SendContactInput = {
  contact_detail: Scalars["String"]["input"]
  contact_type_id: Scalars["Int"]["input"]
  target_service_id: Scalars["Int"]["input"]
}

export type SignedUrl = {
  __typename?: "SignedUrl"
  url: Scalars["String"]["output"]
}

export type SubcategoryFilterInput = {
  intage_generic_category_lv3name?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >
  jicfs_lv4name?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>
}

export type Subscription = {
  __typename?: "Subscription"
  onForwardChart?: Maybe<Chart>
}

export type SubscriptionOnForwardChartArgs = {
  tranId: Scalars["String"]["input"]
}

export type TargetService = {
  __typename?: "TargetService"
  target_service: Scalars["String"]["output"]
  target_service_id: Scalars["ID"]["output"]
}

export type IndexData = {
  __typename?: "indexData"
  periodType: Scalars["String"]["output"]
  searchablePeriod: IndexSearchablePeriod
}

export type ChartsQueryVariables = Exact<{
  searchCondition: SearchConditionInput
  tranId: Scalars["String"]["input"]
}>

export type ChartsQuery = {
  __typename?: "Query"
  charts: {
    __typename?: "Charts"
    categoryScaleTrendChart?: Array<{
      __typename?: "Coordinate"
      name: string
      values: Array<number | null>
    } | null> | null
  }
}

export type SaveSearchConditionMutationVariables = Exact<{
  searchCondition: SearchConditionInput
}>

export type SaveSearchConditionMutation = {
  __typename?: "Mutation"
  saveSearchCondition: {
    __typename?: "SearchCondition"
    addAutoSelectTopItems: boolean
    createdAt: number
    SK: string
    category: {
      __typename?: "SCCategory"
      code: string
      indexCode: string
      name: string
    }
    mainAttr: {
      __typename?: "SCAttribute"
      name: string
      kind: string
      code: string
      indexKind: string
    }
    period: { __typename?: "Period"; type: string; begin: string; end: string }
    base: { __typename?: "Base"; code: string; name: string }
    mainAttrCategory: {
      __typename?: "AttributeCategory"
      code: string
      name: string
      attrCode: string
    }
    subAttrCategories: Array<{
      __typename?: "AttributeCategory"
      code: string
      name: string
      attrCode: string
    }>
    filterItemAttrs: Array<{
      __typename?: "SCAttribute"
      code: string
      name: string
      kind: string
      indexKind: string
    }>
    filterItemAttrCategories: Array<{
      __typename?: "AttributeCategory"
      code: string
      name: string
      attrCode: string
    }>
    filterRouteAttrs: Array<{
      __typename?: "SCAttribute"
      code: string
      name: string
      kind: string
      indexKind: string
    }>
    filterRouteAttrCategories: Array<{
      __typename?: "AttributeCategory"
      code: string
      name: string
      attrCode: string
    }>
    filterUserAttrs: Array<{
      __typename?: "SCAttribute"
      code: string
      name: string
      kind: string
      indexKind: string
    }>
    filterUserAttrCategories: Array<{
      __typename?: "AttributeCategory"
      code: string
      name: string
      attrCode: string
    }>
    demographAttr: {
      __typename?: "SCAttribute"
      name: string
      kind: string
      code: string
      indexKind: string
    }
  }
}

export type AttributeCategoriesQueryVariables = Exact<{
  selectedCategory: CategoryInput
  selectedAttributes: Array<AttributeInput> | AttributeInput
}>

export type AttributeCategoriesQuery = {
  __typename?: "Query"
  attributeCategories?: Array<{
    __typename?: "AttributeCategory"
    code: string
    name: string
    attrCode: string
  }> | null
}

export type SearchConditionsQueryVariables = Exact<{ [key: string]: never }>

export type SearchConditionsQuery = {
  __typename?: "Query"
  searchConditions: Array<{
    __typename?: "SearchCondition"
    addAutoSelectTopItems: boolean
    createdAt: number
    SK: string
    category: {
      __typename?: "SCCategory"
      code: string
      indexCode: string
      name: string
    }
    mainAttr: {
      __typename?: "SCAttribute"
      name: string
      kind: string
      code: string
      indexKind: string
    }
    period: { __typename?: "Period"; type: string; begin: string; end: string }
    base: { __typename?: "Base"; code: string; name: string }
    mainAttrCategory: {
      __typename?: "AttributeCategory"
      code: string
      name: string
      attrCode: string
    }
    subAttrCategories: Array<{
      __typename?: "AttributeCategory"
      code: string
      name: string
      attrCode: string
    }>
    filterItemAttrs: Array<{
      __typename?: "SCAttribute"
      code: string
      name: string
      kind: string
      indexKind: string
    }>
    filterItemAttrCategories: Array<{
      __typename?: "AttributeCategory"
      code: string
      name: string
      attrCode: string
    }>
    filterRouteAttrs: Array<{
      __typename?: "SCAttribute"
      code: string
      name: string
      kind: string
      indexKind: string
    }>
    filterRouteAttrCategories: Array<{
      __typename?: "AttributeCategory"
      code: string
      name: string
      attrCode: string
    }>
    filterUserAttrs: Array<{
      __typename?: "SCAttribute"
      code: string
      name: string
      kind: string
      indexKind: string
    }>
    filterUserAttrCategories: Array<{
      __typename?: "AttributeCategory"
      code: string
      name: string
      attrCode: string
    }>
    demographAttr: {
      __typename?: "SCAttribute"
      name: string
      kind: string
      code: string
      indexKind: string
    }
  }>
}

export type DeleteSearchConditionMutationVariables = Exact<{
  searchConditions:
    | Array<DeleteSearchConditionInput>
    | DeleteSearchConditionInput
}>

export type DeleteSearchConditionMutation = {
  __typename?: "Mutation"
  deleteSearchCondition: Array<{ __typename: "SearchCondition" }>
}

export type CategoriesQueryVariables = Exact<{ [key: string]: never }>

export type CategoriesQuery = {
  __typename?: "Query"
  categories: Array<{
    __typename?: "Category"
    name: string
    code: string
    bases: Array<{ __typename?: "Base"; code: string; name: string }>
    index: {
      __typename?: "Index"
      indexCode: string
      indexData?: Array<{
        __typename?: "indexData"
        periodType: string
        searchablePeriod: {
          __typename?: "IndexSearchablePeriod"
          begin: string
          end: string
        }
      }> | null
      attributes?: Array<{
        __typename?: "Attribute"
        name: string
        kind: string
        code: string
        indexKind: string
        numOfCtgs: number
        classCodes: Array<AttributeClassCode>
      }> | null
    }
  } | null>
}

export type ToExcelQueryVariables = Exact<{
  charts: Array<ChartInput> | ChartInput
}>

export type ToExcelQuery = {
  __typename?: "Query"
  toExcel: { __typename?: "Base64Excel"; base64: string }
}

export type GetContactTypesQueryVariables = Exact<{ [key: string]: never }>

export type GetContactTypesQuery = {
  __typename?: "Query"
  getContactTypes: Array<{
    __typename?: "ContactType"
    contact_type_id: string
    contact_type: string
  }>
}

export type GetTargetServicesQueryVariables = Exact<{ [key: string]: never }>

export type GetTargetServicesQuery = {
  __typename?: "Query"
  getTargetServices: Array<{
    __typename?: "TargetService"
    target_service_id: string
    target_service: string
  }>
}

export type GetContactDetailQueryVariables = Exact<{
  contactId: Scalars["Int"]["input"]
}>

export type GetContactDetailQuery = {
  __typename?: "Query"
  getContactDetail: {
    __typename?: "Contact"
    contact_id: number
    contact_detail?: string | null
    contact_type?: string | null
    target_service?: string | null
  }
}

export type GetContactCommentsQueryVariables = Exact<{
  filter: GetContactCommentsInput
}>

export type GetContactCommentsQuery = {
  __typename?: "Query"
  getContactComments: Array<{
    __typename?: "ContactComment"
    contact_comment_id: number
    contact_id: number
    comment: string
    admin_id: number
    created_at?: string | null
    is_read?: number | null
  } | null>
}

export type SendContactCommentMutationVariables = Exact<{
  data: SendContactCommentInput
}>

export type SendContactCommentMutation = {
  __typename?: "Mutation"
  sendContactComment?: {
    __typename?: "ContactComment"
    contact_comment_id: number
    contact_id: number
    comment: string
    admin_id: number
    created_at?: string | null
    is_read?: number | null
  } | null
}

export type ReadContactCommentsMutationVariables = Exact<{
  Data: ReadContactCommentsInput
}>

export type ReadContactCommentsMutation = {
  __typename?: "Mutation"
  readContactComments: {
    __typename?: "ReadContactCommentsOutput"
    contact_id: number
    contact_comment_id: number
  }
}

export type SendContactMutationVariables = Exact<{
  data: SendContactInput
}>

export type SendContactMutation = {
  __typename?: "Mutation"
  sendContact?: {
    __typename?: "Contact"
    contact_id: number
    contact_type?: string | null
    target_service?: string | null
    contact_detail?: string | null
    status?: ContactStatus | null
    created_at?: string | null
  } | null
}

export type GetInformationsQueryVariables = Exact<{
  filter: InfoDataInput
}>

export type GetInformationsQuery = {
  __typename?: "Query"
  getInfoData?: {
    __typename?: "InfoDataOutput"
    totalCount?: number | null
    unreadCnt?: number | null
    infoDataItems?: Array<{
      __typename?: "InfoData"
      id: string
      title?: string | null
      content?: string | null
      openDate?: string | null
      readed?: boolean | null
      delivered?: boolean | null
    } | null> | null
  } | null
}

export type SaveReadedInfoDataMutationVariables = Exact<{
  Data: ReadedInfoDataInput
}>

export type SaveReadedInfoDataMutation = {
  __typename?: "Mutation"
  saveReadedInfoData?: {
    __typename?: "ReadedInfoDataOutput"
    id: string
    infoId: string
    userId: string
    readDate: string
  } | null
}

export type GetReviewsQueryVariables = Exact<{
  ReviewFilterInput: ReviewFilterInput
}>

export type GetReviewsQuery = {
  __typename?: "Query"
  getReviews?: {
    __typename?: "ReviewFilterOutput"
    cnt?: number | null
    cnt_review?: number | null
    score_avg?: number | null
    reviews?: Array<{
      __typename?: "Review"
      score?: number | null
      comment?: string | null
      comment_length?: number | null
      review_updated_at?: string | null
      identifier: string
      name?: string | null
      intage_generic_category_lv3name?: string | null
      jicfs_lv4name?: string | null
      maker_name_orig?: string | null
      sex?: string | null
      age_group?: string | null
      pref?: string | null
    } | null> | null
    dist?: Array<{
      __typename?: "Dist"
      score?: number | null
      cnt?: number | null
    } | null> | null
  } | null
}

export type ReviewsToExcelQueryVariables = Exact<{
  DataInput: ReviewFilterInput
}>

export type ReviewsToExcelQuery = {
  __typename?: "Query"
  reviewsToExcel?: { __typename?: "SignedUrl"; url: string } | null
}

export type GetBrandOptionsQueryVariables = Exact<{
  BrandFilterInput?: InputMaybe<BrandFilterInput>
}>

export type GetBrandOptionsQuery = {
  __typename?: "Query"
  getBrandOptions?: Array<string | null> | null
}

export type GetCategoryOptionsQueryVariables = Exact<{
  categoryDefinition: Scalars["String"]["input"]
}>

export type GetCategoryOptionsQuery = {
  __typename?: "Query"
  getCategoryOptions?: Array<string | null> | null
}

export type GetMakerOptionsQueryVariables = Exact<{
  MakerFilterInput?: InputMaybe<MakerFilterInput>
}>

export type GetMakerOptionsQuery = {
  __typename?: "Query"
  getMakerOptions?: Array<string | null> | null
}

export type GetSubCategoryOptionsQueryVariables = Exact<{
  SubcategoryFilterInput?: InputMaybe<SubcategoryFilterInput>
}>

export type GetSubCategoryOptionsQuery = {
  __typename?: "Query"
  getSubcategoryOptions?: Array<string | null> | null
}

export type GetItemsQueryVariables = Exact<{
  ItemFilterInput: ItemFilterInput
}>

export type GetItemsQuery = {
  __typename?: "Query"
  getFilteredItems?: {
    __typename?: "ItemFilterOutput"
    cnt?: number | null
    filteredItems?: Array<{
      __typename?: "FilteredItem"
      identifier: string
      name: string
      maker_name_orig?: string | null
      intage_generic_category_lv3name?: string | null
      jicfs_lv4name?: string | null
      subcategory_name?: string | null
      brand_name?: string | null
      subbrand_name?: string | null
      score_avg?: number | null
      score_cnt?: number | null
      comment_cnt?: number | null
    } | null> | null
  } | null
}

export type GetAccountCategoryQueryVariables = Exact<{
  categoryDefinition1: Scalars["String"]["input"]
  categoryDefinition2: Scalars["String"]["input"]
}>

export type GetAccountCategoryQuery = {
  __typename?: "Query"
  intageCategory?: Array<string | null> | null
  jicfsCategory?: Array<string | null> | null
}

export type SaveAccountDataMutationVariables = Exact<{
  Data: SaveAccountDataInput
}>

export type SaveAccountDataMutation = {
  __typename?: "Mutation"
  saveAccountData: { __typename?: "SaveAccountDataOutput"; result: boolean }
}

export type GetInfoDataQueryVariables = Exact<{ [key: string]: never }>

export type GetInfoDataQuery = {
  __typename?: "Query"
  getInfoData?: {
    __typename?: "InfoDataOutput"
    infoDataItems?: Array<{
      __typename?: "InfoData"
      id: string
      title?: string | null
      content?: string | null
      openDate?: string | null
      readed?: boolean | null
      delivered?: boolean | null
    } | null> | null
  } | null
}

export type GetUnreadSummaryHeaderQueryVariables = Exact<{
  [key: string]: never
}>

export type GetUnreadSummaryHeaderQuery = {
  __typename?: "Query"
  getUnreadSummary?: {
    __typename?: "GetUnreadSummaryOutput"
    unreadInfoCnt: number
    uncheckedContactCnt: number
  } | null
}

export type SaveDeliveryInfoDataMutationVariables = Exact<{
  Data: DeliveryInfoDataInput
}>

export type SaveDeliveryInfoDataMutation = {
  __typename?: "Mutation"
  saveDeliveryInfoData?: {
    __typename?: "DeliveryInfoDataOutput"
    infoIds: Array<string | null>
    userId: string
    deliveryDate: string
  } | null
}

export type GetContactsQueryVariables = Exact<{ [key: string]: never }>

export type GetContactsQuery = {
  __typename?: "Query"
  getContacts: {
    __typename?: "GetContactsOutput"
    contacts: Array<{ __typename?: "Contact"; unread_cnt?: number | null }>
  }
}

export const ChartsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "charts" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "searchCondition" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "SearchConditionInput" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "tranId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "charts" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "searchCondition" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "searchCondition" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "tranId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "tranId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "categoryScaleTrendChart" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "values" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ChartsQuery, ChartsQueryVariables>
export const SaveSearchConditionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "saveSearchCondition" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "searchCondition" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "SearchConditionInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "saveSearchCondition" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "searchCondition" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "searchCondition" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "category" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "code" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "indexCode" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "mainAttr" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "kind" } },
                      { kind: "Field", name: { kind: "Name", value: "code" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "indexKind" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "period" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "begin" } },
                      { kind: "Field", name: { kind: "Name", value: "end" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "base" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "code" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "mainAttrCategory" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "code" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attrCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "subAttrCategories" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "code" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attrCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "filterItemAttrs" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "code" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "kind" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "indexKind" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "filterItemAttrCategories" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "code" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attrCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "filterRouteAttrs" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "code" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "kind" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "indexKind" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "filterRouteAttrCategories" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "code" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attrCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "filterUserAttrs" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "code" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "kind" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "indexKind" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "filterUserAttrCategories" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "code" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attrCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "demographAttr" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "kind" } },
                      { kind: "Field", name: { kind: "Name", value: "code" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "indexKind" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "addAutoSelectTopItems" },
                },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                { kind: "Field", name: { kind: "Name", value: "SK" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SaveSearchConditionMutation,
  SaveSearchConditionMutationVariables
>
export const AttributeCategoriesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "attributeCategories" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "selectedCategory" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CategoryInput" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "selectedAttributes" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "AttributeInput" },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "attributeCategories" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "category" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "selectedCategory" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "attributes" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "selectedAttributes" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: { kind: "StringValue", value: "", block: false },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "code" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "attrCode" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AttributeCategoriesQuery,
  AttributeCategoriesQueryVariables
>
export const SearchConditionsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "searchConditions" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "searchConditions" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "category" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "code" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "indexCode" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "mainAttr" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "kind" } },
                      { kind: "Field", name: { kind: "Name", value: "code" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "indexKind" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "period" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "begin" } },
                      { kind: "Field", name: { kind: "Name", value: "end" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "base" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "code" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "mainAttrCategory" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "code" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attrCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "subAttrCategories" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "code" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attrCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "filterItemAttrs" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "code" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "kind" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "indexKind" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "filterItemAttrCategories" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "code" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attrCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "filterRouteAttrs" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "code" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "kind" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "indexKind" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "filterRouteAttrCategories" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "code" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attrCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "filterUserAttrs" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "code" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "kind" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "indexKind" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "filterUserAttrCategories" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "code" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attrCode" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "demographAttr" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "kind" } },
                      { kind: "Field", name: { kind: "Name", value: "code" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "indexKind" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "addAutoSelectTopItems" },
                },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                { kind: "Field", name: { kind: "Name", value: "SK" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SearchConditionsQuery,
  SearchConditionsQueryVariables
>
export const DeleteSearchConditionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "deleteSearchCondition" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "searchConditions" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "DeleteSearchConditionInput" },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteSearchCondition" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "searchConditions" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "searchConditions" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteSearchConditionMutation,
  DeleteSearchConditionMutationVariables
>
export const CategoriesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "categories" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "bases" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "code" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "index" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "indexCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "indexData" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "periodType" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "searchablePeriod" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "begin" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "end" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attributes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "kind" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "indexKind" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "numOfCtgs" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "classCodes" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CategoriesQuery, CategoriesQueryVariables>
export const ToExcelDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "toExcel" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "charts" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "ChartInput" },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "toExcel" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "charts" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "charts" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "base64" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ToExcelQuery, ToExcelQueryVariables>
export const GetContactTypesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getContactTypes" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getContactTypes" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "contact_type_id" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "contact_type" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetContactTypesQuery,
  GetContactTypesQueryVariables
>
export const GetTargetServicesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getTargetServices" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getTargetServices" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "target_service_id" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "target_service" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTargetServicesQuery,
  GetTargetServicesQueryVariables
>
export const GetContactDetailDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getContactDetail" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "contactId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getContactDetail" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "contactId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "contactId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "contact_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "contact_detail" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "contact_type" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "target_service" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetContactDetailQuery,
  GetContactDetailQueryVariables
>
export const GetContactCommentsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getContactComments" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filter" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "GetContactCommentsInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getContactComments" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "filter" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "contact_comment_id" },
                },
                { kind: "Field", name: { kind: "Name", value: "contact_id" } },
                { kind: "Field", name: { kind: "Name", value: "comment" } },
                { kind: "Field", name: { kind: "Name", value: "admin_id" } },
                { kind: "Field", name: { kind: "Name", value: "created_at" } },
                { kind: "Field", name: { kind: "Name", value: "is_read" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetContactCommentsQuery,
  GetContactCommentsQueryVariables
>
export const SendContactCommentDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "sendContactComment" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "SendContactCommentInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "sendContactComment" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "data" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "contact_comment_id" },
                },
                { kind: "Field", name: { kind: "Name", value: "contact_id" } },
                { kind: "Field", name: { kind: "Name", value: "comment" } },
                { kind: "Field", name: { kind: "Name", value: "admin_id" } },
                { kind: "Field", name: { kind: "Name", value: "created_at" } },
                { kind: "Field", name: { kind: "Name", value: "is_read" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SendContactCommentMutation,
  SendContactCommentMutationVariables
>
export const ReadContactCommentsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "readContactComments" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "Data" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "ReadContactCommentsInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "readContactComments" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "Data" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "contact_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "contact_comment_id" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ReadContactCommentsMutation,
  ReadContactCommentsMutationVariables
>
export const SendContactDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "sendContact" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "SendContactInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "sendContact" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "data" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "contact_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "contact_type" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "target_service" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "contact_detail" },
                },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "created_at" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SendContactMutation, SendContactMutationVariables>
export const GetInformationsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getInformations" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filter" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "InfoDataInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getInfoData" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "filter" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "infoDataItems" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "content" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "openDate" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "readed" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "delivered" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
                { kind: "Field", name: { kind: "Name", value: "unreadCnt" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetInformationsQuery,
  GetInformationsQueryVariables
>
export const SaveReadedInfoDataDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "saveReadedInfoData" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "Data" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "ReadedInfoDataInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "saveReadedInfoData" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "Data" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "infoId" } },
                { kind: "Field", name: { kind: "Name", value: "userId" } },
                { kind: "Field", name: { kind: "Name", value: "readDate" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SaveReadedInfoDataMutation,
  SaveReadedInfoDataMutationVariables
>
export const GetReviewsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getReviews" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "ReviewFilterInput" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "ReviewFilterInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getReviews" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "ReviewFilterInput" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "reviews" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "score" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "comment" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "comment_length" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "review_updated_at" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "identifier" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "intage_generic_category_lv3name",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "jicfs_lv4name" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "maker_name_orig" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "sex" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "age_group" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "pref" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "cnt" } },
                { kind: "Field", name: { kind: "Name", value: "cnt_review" } },
                { kind: "Field", name: { kind: "Name", value: "score_avg" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "dist" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "score" } },
                      { kind: "Field", name: { kind: "Name", value: "cnt" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetReviewsQuery, GetReviewsQueryVariables>
export const ReviewsToExcelDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "reviewsToExcel" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "DataInput" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "ReviewFilterInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "reviewsToExcel" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "DataInput" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "url" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReviewsToExcelQuery, ReviewsToExcelQueryVariables>
export const GetBrandOptionsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getBrandOptions" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "BrandFilterInput" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "BrandFilterInput" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getBrandOptions" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "BrandFilterInput" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetBrandOptionsQuery,
  GetBrandOptionsQueryVariables
>
export const GetCategoryOptionsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getCategoryOptions" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "categoryDefinition" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getCategoryOptions" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "categoryDefinition" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "categoryDefinition" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCategoryOptionsQuery,
  GetCategoryOptionsQueryVariables
>
export const GetMakerOptionsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getMakerOptions" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "MakerFilterInput" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "MakerFilterInput" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getMakerOptions" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "MakerFilterInput" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMakerOptionsQuery,
  GetMakerOptionsQueryVariables
>
export const GetSubCategoryOptionsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getSubCategoryOptions" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "SubcategoryFilterInput" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "SubcategoryFilterInput" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getSubcategoryOptions" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "SubcategoryFilterInput" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetSubCategoryOptionsQuery,
  GetSubCategoryOptionsQueryVariables
>
export const GetItemsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getItems" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "ItemFilterInput" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "ItemFilterInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getFilteredItems" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filterInput" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "ItemFilterInput" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "filteredItems" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "identifier" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "maker_name_orig" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "intage_generic_category_lv3name",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "jicfs_lv4name" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "subcategory_name" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "brand_name" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "subbrand_name" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "score_avg" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "score_cnt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "comment_cnt" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "cnt" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetItemsQuery, GetItemsQueryVariables>
export const GetAccountCategoryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getAccountCategory" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "categoryDefinition1" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "categoryDefinition2" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            alias: { kind: "Name", value: "intageCategory" },
            name: { kind: "Name", value: "getCategoryOptions" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "categoryDefinition" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "categoryDefinition1" },
                },
              },
            ],
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "jicfsCategory" },
            name: { kind: "Name", value: "getCategoryOptions" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "categoryDefinition" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "categoryDefinition2" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAccountCategoryQuery,
  GetAccountCategoryQueryVariables
>
export const SaveAccountDataDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "saveAccountData" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "Data" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "SaveAccountDataInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "saveAccountData" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "accountData" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "Data" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "result" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SaveAccountDataMutation,
  SaveAccountDataMutationVariables
>
export const GetInfoDataDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getInfoData" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getInfoData" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "infoDataItems" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "content" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "openDate" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "readed" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "delivered" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetInfoDataQuery, GetInfoDataQueryVariables>
export const GetUnreadSummaryHeaderDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getUnreadSummaryHeader" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getUnreadSummary" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "unreadInfoCnt" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "uncheckedContactCnt" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUnreadSummaryHeaderQuery,
  GetUnreadSummaryHeaderQueryVariables
>
export const SaveDeliveryInfoDataDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "saveDeliveryInfoData" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "Data" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "DeliveryInfoDataInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "saveDeliveryInfoData" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "Data" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "infoIds" } },
                { kind: "Field", name: { kind: "Name", value: "userId" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "deliveryDate" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SaveDeliveryInfoDataMutation,
  SaveDeliveryInfoDataMutationVariables
>
export const GetContactsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getContacts" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getContacts" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "contacts" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "unread_cnt" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetContactsQuery, GetContactsQueryVariables>
