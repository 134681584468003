import { RouteObject, Outlet } from "react-router-dom"
import Review from "@review/pages/Review"
import { Protected } from "utils/auth/protect"

const ReviewRoutes: RouteObject[] = [
  {
    path: "review",
    element: (
      <Protected>
        <Outlet />
      </Protected>
    ),
    children: [
      { index: true, element: <Review /> },
    ],
  },
]

export default ReviewRoutes
