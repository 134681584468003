/*

	バーコード入力

*/
import React from "react"
import { useController, useFormContext } from "react-hook-form"
import SelectListBoxInput from "@review/components/SelectListBoxInput"
import Alert from "@mui/material/Alert"
import Autocomplete from "@mui/material/Autocomplete"
import ListItem from "@mui/material/ListItem"

export default function InputBarcode(props: { label: string; name: string }) {
  const [barcodes, setBarcodes] = React.useState([])

  const {
    watch,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext()

  const { field: fieldBarcode } = useController({
    name: props.name,
    control: control,
  })
  const barcode = getValues(props.name)

  const { field: fieldItem } = useController({
    name: props.name,
    control: control,
    defaultValue: [],
  })

  const [text, setText] = React.useState("")
  const [showAlert, setShowAlert] = React.useState(false);

  return (
    <div>
      <Autocomplete
        loading={false}
        size="small"
        options={barcodes}
        sx={{ width: "200px", maxHeight: "100px", overflow: "hidden auto" }}
        multiple
        freeSolo
        value={fieldItem.value}
        inputValue={text}
        onChange={(e, v) => {
          const values = v.map(str => str.trim()).filter(str => str !== '')
          setValue(props.name, Array.from(new Set(values)))
        }}
        onInputChange={(e, v) => {
          setText(v)
          const value = v
          setShowAlert(fieldItem.value.includes(value))

          // 改行、カンマで区切られた値をセット
          const regex = new RegExp(/\r|\n|\r\n|,| /)
          if (value.search(regex) !== -1) {
            const value_arr = value.split(regex).filter(str => str.trim() !== '')
            fieldItem.value.push(...value_arr)
            setValue(props.name, Array.from(new Set(fieldItem.value)))
            setText("")
          }
        }}
        renderInput={(params) => (
          <SelectListBoxInput
            params={params}
            name={props.name}
            label={props.label}
            fieldItem={fieldItem}
          />
        )}
      />
      {showAlert && (
        <Alert
          severity="warning"
          sx={{ mt: 1 }}
        >
          そのバーコードは既に登録されています。
        </Alert>
      )}
    </div>
  )
}
