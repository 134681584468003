import { useEffect } from "react"
import { UseFormReturn } from "react-hook-form"
import { Button, Typography } from "@mui/material"
import { useAuth } from "hooks/useAuth"
import { ErrorWithFormProps, passwordChangeFormInputs, activateSendButton } from "../hooks"
import { SERVICE_EMAIL } from "constants/base"

const OtpFormAndButtonAndLink = ({
  formMethods,
  execute,
  error,
  disabledButtonFlag,
  setDisabledButtonFlag,
}: {
  formMethods: UseFormReturn<passwordChangeFormInputs>
  execute: (data: passwordChangeFormInputs) => Promise<void>
  error: ErrorWithFormProps
  disabledButtonFlag: boolean
  setDisabledButtonFlag: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const auth = useAuth() // Cognito
  const username = auth.username
  useEffect(() => {
    console.debug("username is:", username)
  }, [auth, username])

  // パスワード変更ボタンの有効/無効
  setDisabledButtonFlag(activateSendButton({formMethods}))

  if (auth.isLoading) return <></>

  console.log(auth)
  return (
    <>
      <Button
        type="submit"
        onClick={formMethods.handleSubmit(execute, error)}
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        disabled={disabledButtonFlag}
      >
        認証してパスワードを変更する
      </Button>

      <Typography variant="body2" color="text.secondary">
        メールが届かない場合は、迷惑メールフォルダをご確認ください。また、
        {SERVICE_EMAIL} からのメールを受信できるように設定してください。
      </Typography>
  
      <Button href="/auth/passwordForget" fullWidth variant="text" sx={{ mt: 4, mb: 1 }}>
        パスワード変更ページに戻る（ワンタイムパスワードの発行もこちらから）
      </Button>
    </>
  )
}
export default OtpFormAndButtonAndLink
