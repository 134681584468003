import { Typography } from "@mui/material"

const MainBoxTitleText = ({ text }: { text?: string }) => {
  text = text || "買いログ(CODE)ポータルへようこそ！"
  return (
    <Typography
      component="p"
      variant="body1"
      sx={{ fontSize: "0.95rem", fontWeight: "500" }}
    >
      {text}
    </Typography>
  )
}
export default MainBoxTitleText
