import { useContext } from "react"
import { UseFormReturn } from "react-hook-form"
import { Box, CssBaseline } from "@mui/material"
import HeaderNav from "../components/HeaderNav"
import HeroArea from "../components/HeroArea"
import AreaBox from "../components/AreaBox"
import MainBox from "../components/MainBox"
import MainBoxTitleText from "../components/MainBoxTitleText"
import AuthCopyright from "../components/AuthCopyright"
import MainFormAttrs from "./components/MainFormAttrs"
import ButtonsAndLinks from "./components/ButtonsAndLinks"
import { ErrorWithFormProps, LoginFormInputs, FormContext } from "./hooks"
import "../auth.css"

export default function Presenter({
  execute,
  error,
}: {
  execute: (data: LoginFormInputs) => Promise<void>
  error: ErrorWithFormProps
}) {
  const formMethods = useContext(FormContext) as UseFormReturn<LoginFormInputs>

  return (
    <>
      <CssBaseline />
      <HeaderNav />
      <AreaBox>
        <HeroArea />

        <MainBox>
          <MainBoxTitleText />
          <Box component="form" noValidate sx={{ m: 1, mt: 2 }}>
            <MainFormAttrs formMethods={formMethods} />

            <ButtonsAndLinks
              formMethods={formMethods}
              execute={execute}
              error={error}
            />
          </Box>
        </MainBox>

        {/* footer */}
        <AuthCopyright />
      </AreaBox>
    </>
  )
}
