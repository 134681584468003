/*

	カテゴリ選択

*/
import SelectListBox from "@review/components/SelectListBox"
import { gql, useQuery } from "urql"

export default function SelectCategory(props: {
  name: string
  label: string
  categoryDefinition: string
}) {
  const [result] = useQuery({
    query: /* GraphQL */ `
      query getCategoryOptions($categoryDefinition: String!) {
        getCategoryOptions(categoryDefinition: $categoryDefinition)
      }
    `,
    variables: {
      categoryDefinition: props.categoryDefinition,
    }
  })

  const getOptionsFromResult = () => {
    if (result.fetching || result.error) return []
    if (result.data.getCategoryOptions == null) return []
    return result.data.getCategoryOptions
  }

  const getOptions = () => {
    return result.fetching
      ? []
      : getOptionsFromResult()
  }

  return(
    <SelectListBox
      name={props.name}
      label={props.label}
      items={getOptions()}
    />
  )
}
