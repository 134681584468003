import React from "react"
import Box from "@mui/material/Box"
import { Virtuoso } from "react-virtuoso"

export const ListBox = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement>
>((props, ref) => {
  const { children, ...other } = props
  const items = React.Children.toArray(children)
  return (
    <Box sx={{ height: "40vh" }} ref={ref} {...other}>
      <Virtuoso
        data={items}
        style={{ height: "100%" }}
        itemContent={(index: number, data) => {
          return <>{data}</>
        }}
      />
    </Box>
  )
})
