import zod from "zod-i18n-map/locales/en/zod.json"
import authForm from "./authForm.json"
import baseCustomedForm from "./baseCustomedForm.json"

const i18nConfig = {
  translation: {
    "Welcome to React": "Welcome to React and react-i18next",
  },
  authForm: authForm,
  baseCustomedForm: baseCustomedForm,
  zod: zod,
} as const
export default i18nConfig
