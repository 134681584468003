/*

	ページ全体のビュー

*/
import { useParams, Link } from "react-router-dom"
import Stack from "@mui/material/Stack"
import Alert from "@mui/material/Alert"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import CircularProgress from "@mui/material/CircularProgress"
import { styled } from "@mui/material/styles"
import PageTitle from "@contact/components/PageTitle"
import PanelDetail from "./components/PanelDetail"
import { Contact } from "gql/graphql"
import { useQuery } from "urql"

const ButtonBack = styled(Button)({
  width: 160,
  fontWeight: "bold",
})

export default function Presenter() {
  const { id } = useParams()

  const [result] = useQuery({
    query: /* Graphql */ `
      query getContactDetail($contactId: Int!) {
        getContactDetail(contactId: $contactId) {
          contact_id
          contact_detail
          contact_type
          target_service
        }
      }
    `,
    variables: {
      filter: {
        contact_id: Number(id)
      },
      contactId: Number(id)
    }
  })

  if (result.fetching) return(
    <Box component="div" sx={{textAlign: "center"}}>
      <CircularProgress />
    </Box>
  )

  const thread: Contact = result.data?.getContactDetail

  return (
    <Stack direction="column" sx={{ width: "100%" }} spacing={5}>
      <PageTitle title={`お問い合わせ詳細`} />
      <Link to="/contact">
        <ButtonBack
          variant="contained"
          color="secondary"
        >
          戻る
        </ButtonBack>
      </Link>
      {result.error
        ? <Alert severity="error">お問合せ詳細の取得に失敗しました</Alert>
        : <PanelDetail thread={thread} />
      }
    </Stack>
  )
}
