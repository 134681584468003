import { UseFormReturn } from "react-hook-form"
import PersonIcon from "@mui/icons-material/Person"
import { FormControl, InputAdornment, TextField } from "@mui/material"
import { PasswordForgotFormInputs, activateSendButton } from "../hooks"
import HtmlTooltip from "../../components/HtmlTooltip"
import { SERVICE_EMAIL } from "constants/base"
import "../../auth.css"

export default function MainFormAttrs({
  formMethods,
  setDisabledButtonFlag,
}: {
  formMethods: UseFormReturn<PasswordForgotFormInputs>
  setDisabledButtonFlag: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const {
    register,
    formState: { errors },
  } = formMethods

  return (
    <>
      <FormControl
        margin="none"
        variant="standard"
        required
        error={!!errors.email}
        fullWidth
      >
        <HtmlTooltip title={emailNoticeText}>
          <TextField
            required
            autoFocus
            autoComplete="email"
            margin="normal"
            label="メールアドレス"
            {...register("email")}
            type="email"
            helperText={
              errors.email?.message || "メールアドレスを入力してください。"
            }
            onKeyDown={() => {
              setDisabledButtonFlag(activateSendButton({formMethods}))
            }}
            onBlur={() => {
              setDisabledButtonFlag(activateSendButton({formMethods}))
            }}
            error={!!errors.email}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonIcon />
                </InputAdornment>
              ),
            }}
          />
        </HtmlTooltip>
      </FormControl>
    </>
  )
}

export const emailNoticeText = (
  <>
    <p>
      入力されたメールアドレス宛に
      <br />
      {SERVICE_EMAIL} から認証コードをお送りします。
    </p>
    <p>
      ※ 認証コードの有効期限は1時間です。
      <br />
      メールが届かない場合は、迷惑メールフォルダをご確認ください。
    </p>
  </>
)
