import { UseFormReturn } from "react-hook-form"
import LockIcon from "@mui/icons-material/Lock"
import PersonIcon from "@mui/icons-material/Person"
import BusinessIcon from '@mui/icons-material/Business';
import DeskIcon from '@mui/icons-material/Desk';
import {
  FormControl,
  InputAdornment,
  TextField,
} from "@mui/material"
import HtmlTooltip from "../../components/HtmlTooltip"
import { LoginFormInputs } from "../hooks"
import "../../auth.css"

export default function MainFormAttrs({
  formMethods,
}: {
  formMethods: UseFormReturn<LoginFormInputs>
}) {
  const {
    register,
    formState: { errors },
  } = formMethods
  return (
    <>
    <FormControl
      margin="none"
      variant="standard"
      required
      error={!!errors.username}
      fullWidth
    >
      <TextField
        required
        autoFocus
        margin="normal"
        label="氏名"
        {...register("username")}
        helperText={
          errors.username?.message || "氏名を入力してください。"
        }
        error={!!errors.username}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PersonIcon />
            </InputAdornment>
          ),
        }}
      />
    </FormControl>

    <FormControl
      margin="none"
      variant="standard"
      required
      error={!!errors.companyname}
      fullWidth
    >
        <TextField
          required
          autoFocus
          autoComplete="companyname"
          margin="normal"
          label="企業名"
          {...register("companyname")}
          type="email"
          helperText={
            errors.companyname?.message || "企業名を入力してください。"
          }
          error={!!errors.companyname}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <BusinessIcon />
              </InputAdornment>
            ),
          }}
        />
    </FormControl>

    <FormControl
      margin="none"
      variant="standard"
      required
      error={!!errors.departmentname}
      fullWidth
    >
        <TextField
          required
          autoFocus
          autoComplete="departmentname"
          margin="normal"
          label="部署名"
          {...register("departmentname")}
          type="email"
          helperText={
            errors.departmentname?.message || "部署名を入力してください。"
          }
          error={!!errors.departmentname}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <DeskIcon />
              </InputAdornment>
            ),
          }}
        />
    </FormControl>

    <FormControl
      margin="none"
      variant="standard"
      required
      error={!!errors.divisionname}
      fullWidth
    >
        <TextField
          autoFocus
          autoComplete="divisionname"
          margin="normal"
          label="部署名（課、グループなど）"
          {...register("divisionname")}
          type="email"
          helperText={
            errors.divisionname?.message || "部署名（課、グループなど）を入力してください。"
          }
          error={!!errors.divisionname}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <DeskIcon />
              </InputAdornment>
            ),
          }}
        />
    </FormControl>

  </>
  )
}
