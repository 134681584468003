import { UseFormReturn } from "react-hook-form"
import LockIcon from "@mui/icons-material/Lock"
import PersonIcon from "@mui/icons-material/Person"
import { FormControl, InputAdornment, TextField } from "@mui/material"
import { LoginFormInputs } from "../hooks"
import "../../auth.css"

export default function MainFormAttrs({
  formMethods,
}: {
  formMethods: UseFormReturn<LoginFormInputs>
}) {
  const {
    register,
    formState: { errors },
  } = formMethods
  return (
    <>
      <FormControl
        margin="none"
        variant="standard"
        required
        error={!!errors.username}
        fullWidth
      >
        <TextField
          required
          autoFocus
          autoComplete="username"
          margin="normal"
          label="メールアドレス"
          {...register("username")}
          type="email"
          helperText={
            errors.username?.message || "メールアドレスを入力してください。"
          }
          error={!!errors.username}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PersonIcon />
              </InputAdornment>
            ),
          }}
        />
      </FormControl>

      <FormControl
        margin="none"
        variant="standard"
        required
        error={!!errors.password}
        fullWidth
      >
        <TextField
          required
          autoFocus
          autoComplete="password"
          margin="normal"
          label="パスワード"
          {...register("password")}
          type="password"
          helperText={
            errors.password?.message || "パスワードを入力してください。"
          }
          error={!!errors.password}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LockIcon />
              </InputAdornment>
            ),
          }}
        />
      </FormControl>
    </>
  )
}
