export interface ServiceItem {
  index: number
  label: string
  name: string
}

export const serviceItems: ServiceItem[] = [
  {
    index: 0,
    label: "review",
    name: "口コミ・評価分析LITE",
  },
  {
    index: 1,
    label: "kailog_view",
    name: "買いログView",
  },
  {
    index: 2,
    label: "kailog",
    name: "買いログTalk self",
  },
]
