import {
  ICANVAS_DATA_IN_UPDATE_ERROR,
  ICANVAS_REQUEST_ERROR,
} from "constants/base"
import { AlertSnackbarProps } from "features/components/alertSnackbar"
import { enqueueSnackbar } from "notistack"

export const setGlobalMessage = ({ message, severity }: AlertSnackbarProps) => {
  enqueueSnackbar(message, {
    variant: severity,
  })
}

export const showICanvasError = (error: unknown) => {
  if (!(error instanceof Error)) return
  if (error.message.includes(ICANVAS_REQUEST_ERROR))
    setGlobalMessage({
      message: "ICANVASエラー: ICANVASへ接続できません。",
      severity: "error",
    })
  else
    setGlobalMessage({
      message: `ICANVASエラー: ${
        error.message.split(`${ICANVAS_DATA_IN_UPDATE_ERROR}: `).at(-1) || ""
      }`,
      severity: "error",
    })
}

export const isICanvasError = (error: unknown) => {
  return (
    error instanceof Error &&
    (error.message.includes(ICANVAS_DATA_IN_UPDATE_ERROR) ||
      error.message.includes(ICANVAS_REQUEST_ERROR))
  )
}
