/*

	メッセージ入力欄

*/
import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import { useForm, FormProvider, Controller } from "react-hook-form"
import { MessageFormSchema } from "./schema"

export default function Presenter( props: {
  onSubmit: (form: MessageFormSchema, contactId: number) => void
  sending: boolean
  contactId: number
}) {
  const formContext = useForm<MessageFormSchema>({
    mode: "onChange",
    defaultValues: {
      message: "",
    },
  })
  const { handleSubmit, reset } = formContext

  return (
    <FormProvider {...formContext}>
      <Box component="form" onSubmit={handleSubmit((form: MessageFormSchema) => {
          props.onSubmit(form, props.contactId)
          reset()
        })}>
        <Stack direction="row" alignItems="flex-start">
          <Controller
            name="message"
            defaultValue=""
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                multiline
                placeholder="メッセージを入力"
                rows={3}
              />
            )}
          />
          <Button
            variant="contained"
            color="info"
            type="submit"
            sx={{ ml: 2, flexShrink: 0 }}
            disabled={props.sending}
          >
            { props.sending ? "送信中..." : "送信" }
          </Button>
        </Stack>
      </Box>
    </FormProvider>
  )
}
