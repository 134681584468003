/*

    パネル

*/

import React from "react"
import Stack from "@mui/material/Stack"
import { styled } from "@mui/material/styles"

const StyledPanel = styled(Stack)(() => ({
  width: "100%",
  backgroundColor: "#fff",
  border: "1px solid #ccc",
  borderRadius: "10px",
  padding: "24px",
}))

export default function PanelWrapper( props: { children: React.ReactNode } ) {
  return (
    <StyledPanel gap={2}>
      { props.children }
    </StyledPanel>
  )
}
