/*

	詳細ビュー

*/
import Stack from "@mui/material/Stack"
import PanelWrapper from "@contact/components/PanelWrapper"
import { Contact, ContactComment } from "gql/graphql"
import PanelThreadMessage from "@contact/pages/Detail/components/PanelThreadMessage"
import DetailTable from "./DetailTable" 

export default function Presenter( props: {
  thread: Contact
}) {
  const thread = props.thread

  return (
    <Stack gap={4}>
      <PanelWrapper>
        <DetailTable thread={thread} />
      </PanelWrapper>
      <PanelWrapper>
        <PanelThreadMessage contactId={thread.contact_id} />
      </PanelWrapper>
    </Stack>
  )
}
