import * as React from "react"
import Link from "@mui/material/Link"
import Typography from "@mui/material/Typography"

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export default function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://www.intage.co.jp/" target="intage">
        INTAGE Inc.
      </Link>
      &nbsp;|&nbsp;
      <Link color="inherit" href="https://r-n-i.jp/" target="randi">
        Research and Innovation Co., Ltd.
      </Link>
    </Typography>
  )
}
