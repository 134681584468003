import { Env } from "./base"

// FIXME: 環境に応じてURLを適切に変更する
export const KailogViewUrl = () => {
  switch (Env) {
    case "development":
      return "https://researchandinnovation.jp.looker.com/"
    case "staging":
      return "https://researchandinnovation.jp.looker.com/"
    case "production":
      return "https://researchandinnovation.jp.looker.com/"
    default:
      return "https://researchandinnovation.jp.looker.com/"
  }
}

export const KailogTalkSelfUrl = () => {
  switch (Env) {
    case "development":
      return "https://open-php-81.asianbridge.org/self_interview/"
    case "staging":
      return "https://open-php-81.asianbridge.org/self_interview/"
    case "production":
      return "https://interview.code-biz.com/"
    default:
      return "https://open-php-81.asianbridge.org/self_interview/"
  }
}
