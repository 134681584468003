/*

	ブランド選択

*/
import SelectListBox from "@review/components/SelectListBox"
import { gql, useQuery } from "urql"

export default function SelectBrand(props: {
  name: string
  label: string
  intage_generic_category_lv3name: string[]
  jicfs_lv4name: string[]
  sub_category: string[]
  maker: string[]
}) {
  const filter = {
    intage_generic_category_lv3name: props.intage_generic_category_lv3name,
    jicfs_lv4name: props.jicfs_lv4name,
    subcategory_name: props.sub_category,
    maker_name_orig: props.maker,
  }

  const [result] = useQuery({
    query: /* GraphQL */ `
      query getBrandOptions($BrandFilterInput: BrandFilterInput) {
        getBrandOptions(filter: $BrandFilterInput)
      }
    `,
    variables: {
      BrandFilterInput: filter
    },
  })

  const getOptionsFromResult = () => {
    if (result.fetching || result.error) return []
    if (result.data.getBrandOptions == null) return []
    return result.data.getBrandOptions
  }

  const getOptions = () => {
    return result.fetching
      ? []
      : getOptionsFromResult()
  }

  return(
    <SelectListBox
      name={props.name}
      label={props.label}
      items={getOptions()}
    />
  )
}
