import { RouteObject, Outlet } from "react-router-dom"
import Information from "applications/information/pages/Information"
import { Protected } from "utils/auth/protect"

const InformationRoutes: RouteObject[] = [
  {
    path: "information",
    element: (
      <Protected>
        <Outlet />
      </Protected>
    ),
    children: [
      { index: true, element: <Information /> },
    ],
  },
]

export default InformationRoutes
