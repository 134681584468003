import Copyright from "features/components/copyright"
import { Box, Container, Toolbar } from "@mui/material"
import { styled } from "@mui/material/styles"
import { type Theme } from "@mui/material/styles"

const StyledMainArea = {
  Box: styled(Box)(({ theme }: { theme: Theme }) => ({
    flexGrow: theme.spacing(1),
    height: "100vh",
    width: "100%",
    overflow: "auto",
  })),
  Container: styled(Container)(({ theme }: { theme: Theme }) => ({
    mt: theme.spacing(4),
    mb: theme.spacing(4),
  })),
}

export default function MainArea({ children }: { children?: React.ReactNode }) {
  return (
    <StyledMainArea.Box component="main">
      <Toolbar />
      <StyledMainArea.Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
        {/* contents */}
        {children}

        {/* footer */}
        <Copyright sx={{ pt: 4 }} />
      </StyledMainArea.Container>
    </StyledMainArea.Box>
  )
}
