import { Env } from "./base"

/**
 * MSW
 */
// MSW URL
// @deprecated do not use this variable.
export const MSWUrl = ""

// @deprecated do not use this variable.
export const MSWDummyUrl = MSWUrl

// MSW接続タイムアウト
export const MSWConnTimeout = Env === "production" ? 30 * 1000 : 3 * 1000 // 30 seconds : 3 minutes
// ネットワークエラーを返すモックのURLプレフィックス
// @deprecated do not use this variable.
export const NetworkErrorMockPrefix = "/network-error"
