import { Drawer } from "@mui/material"
import { DrawerProps } from "@mui/material/Drawer"
import { styled } from "@mui/material/styles"
import { type Theme } from "@mui/material/styles"

// `drawer` と `open` といったカスタムプロパティを受け取るための新しいコンポーネントを作成します
interface CustomDrawerProps extends DrawerProps {
  open: boolean
  drawer: number
  theme?: Theme
}
const CustomDrawer: React.FC<CustomDrawerProps> = (props) => (
  <Drawer {...props} />
)

const MenuDrawer = styled(CustomDrawer)(({ theme, open, drawer }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawer,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}))
export default MenuDrawer
