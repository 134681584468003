import { Link } from "@mui/material"
import { SERVICE_DOMAIN } from "constants/base"

export const termsAcceptLabel = (
  <>
    <Link href="/terms_of_service/" target="terms_of_service">利用規約</Link>及び
    <Link href="https://r-n-i.jp/privacy/" target="privacy_policy">プライバシーポリシー</Link>に同意する。
  </>
)

export const emailNoticeText = (
  <>
    <p>
      ご登録いただいたメールアドレスに、確認コードをお送りします。
      <br />
      kailog.jp からのメールが確認可能なメールアドレスを入力ください。
      <br />
      確認コードの入力後、ログインが可能になります。
    </p>
    <p>
      ※確認コードの有効期限は24時間です。
      <br />
      メールが届かない場合は、迷惑メールフォルダをご確認ください。
    </p>
  </>
)

export const passwordNoticeText = (
  <>
    <p>
      パスワードは英数大文字小文字及び記号を含む8文字以上の文字列で入力してください。
      <br />
      利用可能な記号: <code>{"^ $ * . [ ] { } ( ) ? \" ! @ # % & / \ , > < ' : ; | _ ~ ` = + - "}</code>
      <br />
      例: <code>Abc123!@#</code>
    </p>
  </>
)
