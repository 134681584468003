import React from "react"
import { createGraphiQLFetcher } from "@graphiql/toolkit"
import { GraphiQL } from "graphiql"
import "./index.css"
import "graphiql/graphiql.css"

const fetcher = createGraphiQLFetcher({
  url: "https://localstack:4566/graphql",
})

export default function GraphiQLPage() {
  return <GraphiQL fetcher={fetcher} />
}
