import ApplicationTemplateView from "./ApplicationTemplateView"

export default function ApplicationTemplate({
  children,
  pageName = "Main",
  drawer = 260,
}: {
  pageName?: string
  children?: React.ReactNode
  drawer?: number
}) {
  return (
    <ApplicationTemplateView pageName={pageName} drawer={drawer}>
      {children}
    </ApplicationTemplateView>
  )
}
