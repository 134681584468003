/*

	口コミ・評価リスト

*/
import { Review } from "@review/pages/Review/type"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TablePagination from '@mui/material/TablePagination';
import Pager from "@mui/material/Paper"
import ItemRow from "./ItemRow"
import { columns } from "./column"
import { useTableReview } from "./hooks"

export default function TableReview(props: {
  page: number
  setPage: (page: number) => void
  rowsPerPage: number
  setRowsPerPage: (rowsPerPage: number) => void
  reviewRows: Review[]
  cnt: number
}) {
  const {
    rows,
  } = useTableReview(props.reviewRows)

  const {
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
  } = props

  return (
    <Pager sx={{ width: "100%" }}>
      <TableContainer sx={{ maxHeight: "380px" }}>
        <Table stickyHeader area-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, padding: "4px" }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {rows
              .map((row: Review, index: number) => {
                return (
                  <ItemRow
                    key={index}
                    no={index + 1}
                    columns={columns}
                    row={row}
                  />
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {
        rows.length ? (
          <TablePagination
            count={props.cnt}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={(e, newPage) => setPage(newPage)}
            onRowsPerPageChange={(e) => {
              setRowsPerPage(parseInt(e.target.value))
              setPage(0)
            }}
          />
        ) : ""
      }
    </Pager>
  )
}
