/*

	チャートパネル

*/
import ChartReview from "@review/features/PanelAnalysis/components/ChartReview"
import InnerPanel from "@review/features/PanelAnalysis/components/InnerPanel"
import { Review } from "@review/pages/Review/type"
import { Dist } from "gql/graphql"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"

export default function PanelChart(props: {
  dist: Dist[]
}) {
  return (
    <InnerPanel sx={{ width: "60%" }}>
      <Stack
        direction="column"
        alignItems="center"
        sx={{ height: "100%", minHeight: "200px" }}
      >
        <Typography variant="h4" component="h4" sx={{ fontSize: "18px" }}>
          評価点分布
        </Typography>
        <ChartReview dist={props.dist} />
      </Stack>
    </InnerPanel>
  )
}
