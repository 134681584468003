import { styled } from "@mui/material/styles"
import Copyright from "features/components/copyright"

const AuthCopyright = styled(Copyright)(({ theme }) => ({
  marginTop: theme.spacing(2),
  paddingTop: theme.spacing(1),
  borderTop: "1px solid #d4d4d4",
  maxWidth: "600px",
  width: "80%",
}))

export default AuthCopyright
