import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import { Divider, IconButton, List, Toolbar } from "@mui/material"
import GlobalMenu from "./GlobalMenu"
import MenuDrawer from "./MenuDrawer"
import { HelpMenu, SuggestMenu } from "./SuggestMenu"

export default function LeftMenu({
  open,
  drawer,
  toggleDrawer,
}: {
  open: boolean
  drawer: number
  toggleDrawer: () => void
}) {
  open = open || false
  drawer = drawer || 260

  return (
    <MenuDrawer variant="permanent" open={open} drawer={drawer}>
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          px: [1],
        }}
      >
        <IconButton onClick={toggleDrawer}>
          <ChevronLeftIcon />
        </IconButton>
      </Toolbar>
      <Divider />
      <List component="nav">
        <GlobalMenu />
      </List>
    </MenuDrawer>
  )
}
