/*

	リストボックスによる複数選択の入力フォーム

*/
import { useFormContext } from "react-hook-form"
import React from "react"
import TextField from "@mui/material/TextField"
import CloseIcon from "@mui/icons-material/Close"

export default function SelectListBoxInput(props: {
  params: any
  name: string
  label: string
  fieldItem: any
}){
  const params = props.params
  const formName = props.name
  const formLabel = props.label
  const fieldItem = props.fieldItem

  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext()

  const fieldValue = getValues(formName)

  return(
    <TextField
      {...params}
      label={formLabel}
      InputProps={{
        ...params.InputProps,
        type: "search",
        endAdornment: (
          <React.Fragment>
           { fieldItem.value.length > 0 &&
              <CloseIcon
                sx={{ position: "absolute", top: 0, right: 0, cursor: "pointer" }}
                onClick={() => {
                  setValue(formName, [])
                }}
              />
           }
          </React.Fragment>
        ),
      }}
      value={fieldValue}
      variant="standard"
      error={!!errors[formName]}
      helperText={<>{errors[formName]?.message}</>}
      sx={{ paddingRight: "0", minWidth: "0px" }}
    />
  )
}
