import { createContext } from "react"
import { useNavigate } from "react-router-dom"
import { SubmitErrorHandler, useForm, UseFormReturn } from "react-hook-form"
import { Auth } from 'aws-amplify'
import { z } from "zod"
import { makeZodI18nMap } from "zod-i18n-map"
import { zodResolver } from "@hookform/resolvers/zod"
import Presenter from "./Presenter"
import { setGlobalMessage } from "hooks/useGlobalMessage"

export const FormContext = createContext<
  UseFormReturn<PasswordForgotFormInputs> | undefined
>(undefined)

// execute Zod
z.setErrorMap(makeZodI18nMap({ ns: ["baseCustomedForm", "authForm", "zod"] }))
const formNS = "baseCustomedForm"
const schema = z.object({
  email: z.string().email(),
})
export type PasswordForgotFormInputs = z.infer<typeof schema>
export type ErrorWithFormProps = SubmitErrorHandler<PasswordForgotFormInputs>

// 「パスワードを変更する」ボタンの disabled を制御するための関数
export const activateSendButton = ({formMethods}: {formMethods: UseFormReturn<PasswordForgotFormInputs>}) => {
  const email = formMethods.getValues("email") || ""

  return email.length == 0 ? true : false
}

/**
 * Provider component for PasswordForgotForm.
 * @returns React component
 */
export default function Provider() {
  const formMethods = useForm<PasswordForgotFormInputs>({
    resolver: zodResolver(schema),
  })

  if (formMethods === undefined) throw new Error("formMethods is undefined.")

  const navigate = useNavigate()

  // Amplify Auth.forgotPassword() の実行
  const execute = async (data: PasswordForgotFormInputs) => {
    try {
      await Auth.forgotPassword(data.email)
      // 存在しない・無効化されている email を使用してもエラーにはならない。
      // パスワードの回復手段を「なし」に設定している場合もエラーにはならない。
      navigate('/auth/passwordChange', { state: data.email })
    } catch (error: any) {
      switch (error.code) {
        case 'CodeMismatchException':
          // 無効な認証コードが入力された場合に起こる。注) email が存在しない・無効化されている場合にも起こる。
          setGlobalMessage({ message: 'CodeMismatchException : メールアドレスまたは認証コードが正しくありません。ログイン画面に戻って最初からやり直してください。', severity: "error" })
          break

        case 'LimitExceededException':
          // 同じメールアドレスに認証コードを何度も送信した際に起こる。（1時間当たり5回まで）
          setGlobalMessage({ message: 'LimitExceededException : 試行制限を超えました。しばらくしてから試してください。', severity: "error" })
          break
        case 'ResourceNotFoundException':
          // Cognito のユーザープールに Username/Client id ない場合に起こる。つまり、ユーザー登録されていない場合に起こる。
          // 接続先の Cognito のユーザープールが存在しない場合にもこのエラーが起こる : ResourceNotFoundException: Username/client id combination not found.
          setGlobalMessage({ message: `ResourceNotFoundException : 予期せぬエラーが発生しました。管理者にお問い合わせください。`, severity: "error" })
          break
        default:
          // その他のエラー
          setGlobalMessage({ message: error.toString(), severity: "error" });
      }
    }
  }

  const error: ErrorWithFormProps = (errors, event) => {
    console.debug("errorWithPasswordChange")
    return
  }

  return (
    <FormContext.Provider value={formMethods}>
      <Presenter execute={execute} error={error} />
    </FormContext.Provider>
  )
}
