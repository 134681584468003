/*

	新規お問い合わせダイアログ

*/
import { useQuery } from "urql"
import Stack from '@mui/material/Stack'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from "@mui/icons-material/Close"
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import TextField from '@mui/material/TextField'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { useState } from 'react'
import { ContactType, TargetService } from 'gql/graphql'

export default function ContactDialog(props: {
  open: boolean
  onClose: () => void
  onDone: (
    typeValue: number,
    serviceValue: number,
    detailValue: string
  ) => Promise<void>
}) {
  const [typeValue, setTypeValue] = useState(0)
  const [serviceValue, setServiceValue] = useState(0)
  const [detailValue, setDetailValue] = useState("")
  const [alert, setAlert] = useState("")

  const [typeResult] = useQuery({
    query: /* Graphql */ `
      query getContactTypes {
        getContactTypes {
          contact_type_id
          contact_type
        }
      }
    `
  })
  const [serviceResult] = useQuery({
    query: /* Graphql */ `
      query getTargetServices {
        getTargetServices {
          target_service_id
          target_service
        }
      }
    `
  })

  const typeOptions: ContactType[] = typeResult.data?.getContactTypes || []
  const serviceOptions: TargetService[] = serviceResult.data?.getTargetServices || []

  const handlerClick = async () => {
    if (typeValue === 0 ) {
      setAlert("問い合わせしたい内容を選択してください")
      return
    }
    if (serviceValue === 0 ) {
      setAlert("問い合わせしたいサービスを選択してください")
      return
    }
    if (detailValue === "" ) {
      setAlert("問い合わせ内容を入力してください")
      return
    }

    props.onDone(
      typeValue,
      serviceValue,
      detailValue,
    )
  }

  return (
    <Dialog open={ props.open } fullWidth maxWidth="md">
      <DialogTitle align="center">管理者へのお問い合わせ</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => props.onClose()}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent>
        <Stack gap={2}>
          {alert && (
            <Alert severity="error">{alert}</Alert>
          )}
          <FormControl fullWidth>
            <InputLabel id="select_type">問い合わせしたい内容を選択してください</InputLabel>
            <Select
              label="問い合わせしたい内容を選択してください"
              labelId="select_type"
              name="contact_type"
              fullWidth
              onChange={(e) => setTypeValue(Number(e.target.value))}
            >
              {typeOptions.map((option) => (
                <MenuItem key={option.contact_type_id} value={option.contact_type_id}>
                  {option.contact_type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="select_service">問い合わせしたいサービスを選択してください</InputLabel>
            <Select
              label="問い合わせしたいサービスを選択してください"
              labelId="select_service"
              name="target_service"
              fullWidth
              onChange={(e) => setServiceValue(Number(e.target.value))}
            >
              {serviceOptions.map((option) => (
                <MenuItem key={option.target_service_id} value={option.target_service_id}>
                  {option.target_service}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            name="contact_detail"
            placeholder="問い合わせ内容を入力してください"
            fullWidth
            multiline
            rows={5}
            onChange={(e) => setDetailValue(e.target.value)}
          />
        </Stack>
      </DialogContent>

      <DialogActions sx={{ pb: 3, pr: 3 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handlerClick}
        >
          この内容で問い合わせる
        </Button>
      </DialogActions>
    </Dialog>
  )
}
