/*

	ページ内共有コンテキスト

*/

import React from "react"
import Presenter from "./Presenter"

export type ContactDetailProp = {}
export const ContactDetailContext = React.createContext({} as ContactDetailProp)

//------------------------------------------------------------------------------
// 共有コンテキストのプロバイダ
//------------------------------------------------------------------------------
export default function Provider() {
  const item = {}
  return (
    <ContactDetailContext.Provider value={item}>
      <Presenter />
    </ContactDetailContext.Provider>
  )
}
