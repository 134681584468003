import React, { useEffect, useRef, useState, useContext } from "react"
import { UseFormReturn } from "react-hook-form"
import {
  Box,
  CssBaseline,
  TextField,
  Stack,
  FormHelperText,
} from "@mui/material"
import HeaderNav from "../components/HeaderNav"
import HeroArea from "../components/HeroArea"
import AreaBox from "../components/AreaBox"
import MainBox from "../components/MainBox"
import MainBoxTitleText from "../components/MainBoxTitleText"
import AuthCopyright from "../components/AuthCopyright"
import OtpFormAndButtonAndLink from "./components/OtpFormAndButtonAndLink"
import NewPasswordForm from "./components/NewPasswordForm"
import { passwordChangeFormInputs, FormContext, ErrorWithFormProps } from "./hooks"
import "../auth.css"

export default function Presenter({
  execute: executeHandler,
  error: errorHandler,
}: {
  execute: (data: passwordChangeFormInputs) => Promise<void>
  error: ErrorWithFormProps
}) {
  const formMethods = useContext(FormContext) as UseFormReturn<passwordChangeFormInputs>

  // OTP入力欄のコントロール
  const [values, setValues] = useState<string[]>(["", "", "", "", "", ""])
  const [error, setError] = useState<string | null>(null)
  // パスワード変更ボタンの有効/無効
  const [disabledButtonFlag, setDisabledButtonFlag] = useState<boolean>(true)
  const inputs = useRef<(HTMLInputElement | null)[]>([])

  useEffect(() => {
    // Autofocus the first OTP input
    inputs.current[0]?.focus()

  }, [])

  const handleInputChange = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const newValues = [...values]
    const inputValue = e.target.value

    // Check for non-digit characters
    if (/\D/.test(inputValue)) {
      setError("半角数字のみ入力可能です。")
      return
    }

    setError(null) // Clear error

    let i = index
    for (const char of inputValue) {
      if (i < 6) {
        newValues[i] = char
        i++
      }
    }

    setValues(newValues)

    if (i < 6 && inputs.current[i]) {
      inputs.current[i]?.focus()
    }
  }

  const handleKeyDown = (index: number, e: React.KeyboardEvent) => {
    if (e.key === "Backspace" || e.key === "Delete") {
      setValues((prev) => {
        const newValues = [...prev]

        if (newValues[index]) {
          // 現在のTextFieldに値がある場合
          // 右の値を左にシフト
          for (let i = index; i < 5; i++) {
            newValues[i] = newValues[i + 1] || ""
          }
          newValues[5] = "" // 最後のTextFieldはクリア
        } else if (index > 0) {
          // 現在のTextFieldが空で前のTextFieldに値がある場合
          // 右の値を左にシフト
          for (let i = index - 1; i < 5; i++) {
            newValues[i] = newValues[i + 1] || ""
          }
          newValues[5] = "" // 最後のTextFieldはクリア
        }

        return newValues
      })

      // フォーカスを左のTextFieldに移動
      if (index > 0) {
        inputs.current[index - 1]?.focus()
      }
    } else if (e.key === "ArrowLeft" && index > 0) {
      inputs.current[index - 1]?.focus()
    } else if (e.key === "ArrowRight" && index < 5) {
      inputs.current[index + 1]?.focus()
    }
  }

  const handlePaste = (e: React.ClipboardEvent) => {
    e.preventDefault()
    const pastedData = e.clipboardData.getData("text").slice(0, 6)
    const startIndex = inputs.current.findIndex((input) => input === e.target)
    const newValues = [...values]

    for (let i = 0; i < 6 - startIndex && i < pastedData.length; i++) {
      newValues[startIndex + i] = pastedData[i]
    }

    if (pastedData.length > 6) {
      setError(
        "Pasted OTP is too long. Only the first 6 digits will be considered.",
      )
    }

    if (/\D/.test(pastedData)) {
      setError("半角数字のみ入力可能です。")
      return
    }

    setValues(newValues)
  }

  // Watch onetime_password and set formMethods value.
  formMethods.watch("onetime_password")
  useEffect(() => {
    formMethods.setValue("onetime_password", values.join(""))
  }, [values])

  return (
    <>
      <CssBaseline />
      <HeaderNav />
      <AreaBox>
        <HeroArea />

        <MainBox>
          <MainBoxTitleText text="メールアドレス宛に送信されたワンタイムパスワードと新しいパスワードを入力してください。" />

          <Box component="form" noValidate sx={{ m: 1, mt: 3 }}>
            <Stack direction="row" spacing={1}>
              {values.map((value, index) => (
                <TextField
                  key={index}
                  variant="outlined"
                  value={value}
                  inputProps={{
                    maxLength: 1,
                    inputMode: "numeric",
                    style: {
                      textAlign: "center",
                      fontSize: "1.5rem",
                      fontWeight: "bold",
                      fontFamily: "monospace",
                    },
                  }}
                  onChange={(e) => handleInputChange(index, e)}
                  onKeyDown={(e) => handleKeyDown(index, e)}
                  onPaste={handlePaste}
                  error={Boolean(error)}
                  inputRef={(ref) => (inputs.current[index] = ref)}
                />
              ))}
            </Stack>
  
            <FormHelperText error={Boolean(error)}>
              {error || (
                <>
                  買いログからのワンタイムパスワードが記載されたメールが見つからない場合は、ログイン画面に戻り再度「パスワードを忘れた場合」から試行してください。
                </>
              )}
            </FormHelperText>
            <TextField
              type="hidden"
              {...formMethods.register("onetime_password")}
              sx={{ display: "none" }}
            />
          </Box>

          <NewPasswordForm formMethods={formMethods} setDisabledButtonFlag={setDisabledButtonFlag} />

          <OtpFormAndButtonAndLink
            formMethods={formMethods}
            execute={executeHandler}
            error={errorHandler}
            disabledButtonFlag={disabledButtonFlag}
            setDisabledButtonFlag={setDisabledButtonFlag}
          />
        </MainBox>

        {/* footer */}
        <AuthCopyright />
      </AreaBox>
    </>
  )
}
