import { ReCaptchaProvider } from "utils/reCaptcha"
import AuthReCaptchaView from "common/layouts/application/AuthReCaptchaView"
import Presenter from "./Presenter"

const formName = "signUp"
export default function signUp() {
  return (
    <AuthReCaptchaView pageName="サインアップ完了">
      <ReCaptchaProvider formName={formName}>
        <Presenter />
      </ReCaptchaProvider>
    </AuthReCaptchaView>
  )
}
