/*

	スコア表示用

*/
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"

export default function Score(props: {
  label: string
  point: number
  fontSize?: string
  fixed?: number
  localeString?: boolean
}) {
  let point = props.point.toFixed(props.fixed ? props.fixed : 0)
  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ width: "100%", height: "100%", color: "#555" }}
    >
      <Typography sx={{ fontSize: "18px" }}>{props.label}</Typography>
      <Typography
        sx={{
          fontWeight: "bold",
          fontSize: props.fontSize ? props.fontSize : "32px",
          mt: 1,
        }}
      >
        {props.localeString ? parseInt(point).toLocaleString() : point}
      </Typography>
    </Stack>
  )
}
