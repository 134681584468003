import { Button } from "@mui/material"
import { UseFormReturn } from "react-hook-form"
import { ErrorWithFormProps, LoginFormInputs } from "../hooks"

const ButtonsAndLinks = ({
  formMethods,
  execute,
  error,
}: {
  formMethods: UseFormReturn<LoginFormInputs>
  execute: (data: LoginFormInputs) => Promise<void>
  error: ErrorWithFormProps
}) => {
  return (
    <>
      <Button
        type="submit"
        onClick={formMethods.handleSubmit(execute, error)}
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        登録する
      </Button>
    </>
  )
}
export default ButtonsAndLinks
