/*

	ページ内共有コンテキスト

*/

import React from "react"
import Presenter from "./Presenter"

export type ContactListProp = {}
export const ContactListContext = React.createContext({} as ContactListProp)

//------------------------------------------------------------------------------
// 共有コンテキストのプロバイダ
//------------------------------------------------------------------------------
export default function Provider() {
  const item = {}
  return (
    <ContactListContext.Provider value={item}>
      <Presenter />
    </ContactListContext.Provider>
  )
}
