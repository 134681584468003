/*

	お問い合わせ詳細画面

*/
import ApplicationTemplate from "common/layouts/application"
import Provider from "./hooks"

export default function Detail() {
  return (
    <ApplicationTemplate>
      <Provider />
    </ApplicationTemplate>
  )
}
